import { rem } from "polished";
import styled from "styled-components/macro";
import { colors } from "../styles/variables";

export default styled.label`
  color: ${colors.gray};
  font-size: 1em;
  line-height: 1.2;
  width: 15.3rem;
  margin-right: 1.8rem;
  min-height: ${rem(38)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
