import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Amplify, { Auth, Hub, API } from "aws-amplify";

import { AuthData } from "./types";

import GlobalStyle from "./styles/GlobalStyle";

import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Application from "./pages/Application";
import { GlobalContext, GlobalStateType } from "./context/global";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_STAFF_AWS_REGION,
    region: process.env.REACT_APP_AWS_REGION,
    oauth: {
      domain: `${process.env.REACT_APP_STAFF_USER_POOL_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${process.env.REACT_APP_REGISTRAR_USER_POOL_CLIENT_CALLBACK_URL}`,
      redirectSignOut: `${process.env.REACT_APP_REGISTRAR_USER_POOL_CLIENT_CALLBACK_URL}`,
      responseType: "code",
    },
  },
});
API.configure({
  endpoints: [
    {
      name: "csvApi",
      endpoint: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_STAGE}`,
      region: process.env.REACT_APP_AWS_REGION,
    },
  ],
});

type Props = {};

const App: React.FunctionComponent<Props> = (props) => {
  const { setGlobalState } = React.useContext(GlobalContext);

  // Hosted UI Cognito login
  React.useEffect(() => {
    const fetchAndStoreCourts = async (): Promise<void> => {
      const apiName = "csvApi";
      const path = "/private/registrar/getRegistrarCourts";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      };

      const result: string[] = await API.post(apiName, path, myInit);
      setGlobalState((state: GlobalStateType) => ({
        ...state,
        courtIDs: result,
      }));
    };

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI":
          console.log("Hosted UI");
          break;
        case "signIn":
          setGlobalState((state: GlobalStateType) => ({
            ...state,
            user: data,
          }));
          fetchAndStoreCourts();
          break;
        case "signOut":
          setGlobalState({
            courtIDs: [],
            user: null,
          });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          setGlobalState({
            courtIDs: [],
            user: null,
          });
          console.log("Sign in failure", data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user: AuthData) => {
        setGlobalState((state: GlobalStateType) => ({
          ...state,
          user,
        }));
        fetchAndStoreCourts();
      })
      .catch(() => {
        setGlobalState({
          courtIDs: [],
          user: null,
        });
      });
  }, [props, setGlobalState]);

  return (
    <>
      <GlobalStyle />
      <Router>
        <Header />
        <Switch>
          <ProtectedRoute path="/" exact component={Dashboard} />
          <ProtectedRoute
            path="/application/:applicationID"
            exact
            component={Application}
          />
          <Route path="/login" exact component={Login} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
