import { FilterStateType, FilterTypeType } from "../pages/Dashboard";
import {
  ApplicationStatusType,
  ApplicationType,
  ApplicationTypeType,
} from "csv-package";

import getCourt from "./getCourt";

export const calculateApplicationType = (Type: ApplicationTypeType): string => {
  const map: { [K in ApplicationTypeType]: string } = {
    E: "Extend",
    V: "Vary",
    R: "Revoke",
    EV: "Extend, Vary",
    N: "New",
    EVR: "EVR",
  };
  return map[Type];
};

export interface BaseColumnType {
  label: string;
}

export interface KeyColumnType extends BaseColumnType {
  key: keyof ApplicationType;
}

export interface FunctionColumnType extends BaseColumnType {
  sort: {
    ASC: Function;
    DESC: Function;
  };
}

const flagScores: { [key: string]: number } = {
  high_risk: 3,
  interim_sought: 2,
  callback: 2,
  review: 1,
};

const applicationStatusScores: { [K in ApplicationStatusType]: number } = {
  archived: 0,
  submitted: 15,
  verified: 3,
  "verified-awaiting-leave": 5,
  dnp: 1,
  initiated: 0,
  "re-declare-requested": 4,
  "re-declare-accepted": 20,
};

const applicationTypeScores: { [K in ApplicationTypeType]: number } = {
  N: 20,
  E: 30,
  EV: 30,
  V: 15,
  R: 5,
  EVR: 0,
};

const calculateFlagScore = (application: ApplicationType): number => {
  const flagsScore: number = Object.keys(application.Flags).reduce(
    (total: number, currentKey: string): number => {
      if (application.Flags[currentKey]) {
        const scoreToAdd = flagScores[currentKey] || 0;
        return total + scoreToAdd;
      }
      return total;
    },
    0
  );
  const statusScore: number =
    applicationStatusScores[application.Status] +
    applicationTypeScores[application.Type];

  return flagsScore + statusScore;
};

export type TableColumnType = KeyColumnType | FunctionColumnType;

const orderBySubmitted = (a: ApplicationType, b: ApplicationType): number => {
  return a.Submitted > b.Submitted ? -1 : 1;
};

export const tableColumns: TableColumnType[] = [
  {
    label: "Ref. number",
    key: "ApplicationID",
  },
  {
    label: "Status",
    key: "Status",
  },
  {
    label: "Type",
    sort: {
      ASC (a: ApplicationType, b: ApplicationType): number {
        return calculateApplicationType(a.Type) <
          calculateApplicationType(b.Type)
          ? -1
          : 1;
      },
      DESC (a: ApplicationType, b: ApplicationType): number {
        return calculateApplicationType(a.Type) >
          calculateApplicationType(b.Type)
          ? -1
          : 1;
      },
    },
  },
  {
    label: "Submitted",
    key: "Submitted",
  },
  {
    label: "Applicant",
    sort: {
      ASC (a: ApplicationType, b: ApplicationType): number {
        const aFamNam = a.Data.appl_det_fam_nam || a.Data.ap_fam_nam;
        const bFamNam = b.Data.appl_det_fam_nam || b.Data.ap_fam_nam;
        if (aFamNam && bFamNam) {
          return aFamNam < bFamNam ? -1 : 1;
        }
        return 0;
      },
      DESC (a: ApplicationType, b: ApplicationType): number {
        const aFamNam = a.Data.appl_det_fam_nam || a.Data.ap_fam_nam;
        const bFamNam = b.Data.appl_det_fam_nam || b.Data.ap_fam_nam;
        if (aFamNam && bFamNam) {
          return aFamNam > bFamNam ? -1 : 1;
        }
        return 0;
      },
    },
  },
  {
    label: "Respondent",
    sort: {
      ASC (a: ApplicationType, b: ApplicationType): number {
        if (a.Data.resp_fam_nam && b.Data.resp_fam_nam) {
          return a.Data.resp_fam_nam < b.Data.resp_fam_nam ? -1 : 1;
        }
        return 0;
      },
      DESC (a: ApplicationType, b: ApplicationType): number {
        if (a.Data.resp_fam_nam && b.Data.resp_fam_nam) {
          return a.Data.resp_fam_nam > b.Data.resp_fam_nam ? -1 : 1;
        }
        return 0;
      },
    },
  },
  {
    label: "Assigned to",
    key: "Registrar",
  },
  {
    label: "Court",
    sort: {
      ASC (a: ApplicationType, b: ApplicationType): number {
        const courtA = getCourt(a.CourtID);
        const courtB = getCourt(b.CourtID);
        if (courtA && courtB) {
          return courtA.name < courtB.name ? -1 : 1;
        }
        return 0;
      },
      DESC (a: ApplicationType, b: ApplicationType): number {
        const courtA = getCourt(a.CourtID);
        const courtB = getCourt(b.CourtID);
        if (courtA && courtB) {
          return courtA.name > courtB.name ? -1 : 1;
        }
        return 0;
      },
    },
  },
  {
    label: "Flagged",
    sort: {
      ASC (a: ApplicationType, b: ApplicationType): number {
        const aScore = calculateFlagScore(a);
        const bScore = calculateFlagScore(b);
        if (aScore === bScore) {
          return orderBySubmitted(a, b);
        }
        return aScore < bScore ? -1 : 1;
      },
      DESC (a: ApplicationType, b: ApplicationType): number {
        const aScore = calculateFlagScore(a);
        const bScore = calculateFlagScore(b);
        if (aScore === bScore) {
          return orderBySubmitted(a, b);
        }
        return aScore > bScore ? -1 : 1;
      },
    },
  },
];

type FilterLogicType = {
  [key in keyof FilterStateType]: (
    application: ApplicationType,
    filterState: FilterStateType
  ) => boolean;
};

export const filterLogic: FilterLogicType = {
  searchTerm: (application, filterState) => {
    const filterValue = filterState.searchTerm;
    if (typeof filterValue !== "string") {
      throw new Error("Search Term must be a string");
    }
    if (filterValue === "") {
      return true;
    }

    const results = [
      "ApplicationID",
      "appl_det_fam_nam",
      "resp_fam_nam",
      "appl_det_giv_nam",
      "resp_giv_nam",
    ].map((key: string): boolean => {
      const { Data, ...rest } = application;
      const flattenedApplication: { [key: string]: any } = {
        ...rest,
        ...Data,
      };
      if (typeof flattenedApplication[key] === "string") {
        return (
          flattenedApplication[key]
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) >= 0
        );
      }
      return false;
    });

    return results.some((r) => r);
  },
  type: (application, filterState) => {
    const filterValue = filterState.type;
    const matrix: { [key in FilterTypeType]: boolean } = {
      all: true,
      unallocated: !Boolean(application.Registrar),
      myApplications: filterState.currentRegistrar === application.Registrar,
    };
    return matrix[filterValue];
  },
  jurisdiction: (application, filterState) => {
    const filterValue = filterState.jurisdiction;
    if (filterValue === "all") return true;
    const uppercaseFilterValue = filterValue.toUpperCase();
    const jurisdiction = application.ApplicationID.substring(0, uppercaseFilterValue.length);

    if (uppercaseFilterValue === "FVIOEVR" && ["E", "V", "R"].some((key: string) => application.Type.includes(key))) return true;
    if (uppercaseFilterValue === jurisdiction && uppercaseFilterValue === "FVIO") return application.Type.includes("N");

    return uppercaseFilterValue === jurisdiction;
  },
  currentRegistrar: () => true,
};
