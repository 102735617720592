// import _ from "lodash";
import { ApplicationStateType } from "../context/application";

const useCanEvrBeSubmitted = (state: ApplicationStateType): boolean => {
  // disable temp till the narrative changes are done
  if (state.applicationState === null) {
    return false;
  }

  if (
    !state.applicationState.Narrative ||
    state.applicationState.Narrative.length <= 0
  ) {
    return false;
  }

  // if the application has been edited and there are validation errors
  if (!_.isEmpty(state.errors)) {
    return false;
  }

  // If no registrar set assigned
  if (
    !state.applicationState.Registrar ||
    state.applicationState.Registrar === ""
  ) {
    return false;
  }

  // If re-declare in progress and call back requested
  if (
    state.applicationState.Status === "re-declare-requested" &&
    state.applicationState.Flags.call_back
  ) {
    return false;
  }

  // Status where submit isn't allowed
  if (
    !["submitted", "re-declare-accepted", "verified-awaiting-leave"].includes(
      state.applicationState.Status
    )
  ) {
    return false;
  }

  return true;
};

export default useCanEvrBeSubmitted;
