import React from "react";
import styled from "styled-components/macro";

type Props = {
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  handleOnChange: React.ChangeEventHandler<HTMLInputElement>;
};

const Radio: React.FunctionComponent<Props> = ({
  name,
  value,
  checked,
  disabled,
  children,
  handleOnChange,
}) => (
  <RadioWrapper>
    <input
      type="radio"
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={handleOnChange}
      id={`${name}-${value}`}
    />
    <Check htmlFor={`${name}-${value}`} />
    <label className="label" htmlFor={`${name}-${value}`}>
      {children}
    </label>
  </RadioWrapper>
);

export default Radio;

export const Check = styled.label`
  height: 18px;
  width: 18px;
  border: 1px solid #3c3c3c;
  border-radius: 2px;
  background: white;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  position: relative;

  input ~ &:after {
    display: none;
  }

  input:checked ~ &:after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #3c3c3c;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  input:focus ~ & {
    box-shadow: 0px 0px 5px 0px rgba(60, 60, 60, 0.75);
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
  }

  label {
    font-size: 0.85em;
  }
`;
