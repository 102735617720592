import React from "react";
import styled, { css } from "styled-components/macro";
import { ApplicationStatusType } from "csv-package";

import { colors } from "../styles/variables";
import { applicationStatusObjects } from "../helpers/applicationStatus";

type Props = {
  status: ApplicationStatusType;
};

const statusOptions: {
  [K in ApplicationStatusType]: {
    color: string;
    type: "ring" | "solid" | "half";
  };
} = {
  submitted: {
    color: "#69C07A",
    type: "ring",
  },
  verified: {
    color: "#69C07A",
    type: "solid",
  },
  "verified-awaiting-leave": {
    color: "#69C07A",
    type: "half",
  },
  dnp: {
    color: colors.alertRed,
    type: "solid",
  },
  initiated: {
    color: "#000000",
    type: "solid",
  },
  archived: {
    color: "#F4823C",
    type: "solid",
  },
  "re-declare-accepted": {
    color: colors.blue,
    type: "ring",
  },
  "re-declare-requested": {
    color: colors.blue,
    type: "solid",
  },
};

const TableStatus: React.FunctionComponent<Props> = (props) => {
  const applicationStatusObject = applicationStatusObjects[props.status];

  if (applicationStatusObject === undefined) {
    return null;
  }

  const statusText = applicationStatusObject.name;

  return (
    <StatusContainer>
      <Circle status={props.status} />
      <span
        dangerouslySetInnerHTML={{
          __html: statusText.replace(
            /([A-Za-z]{1,}-[A-Za-z]{1,})/gm,
            "<nobr>$1<nobr>"
          ),
        }}
      />
    </StatusContainer>
  );
};

export default TableStatus;

const StatusContainer = styled.div`
  position: relative;
  padding-left: 1.1em;
`;

interface CircleProps {
  readonly status: ApplicationStatusType;
}

const Circle = styled.div<CircleProps>`
  position: absolute;
  display: block;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ status }) => status && statusOptions[status].color};
  border: 2px solid black;
  border-color: ${({ status }) => status && statusOptions[status].color};
  background: ${({ status }) =>
    status && statusOptions[status].type !== "solid"
      ? `transparent`
      : status && statusOptions[status].color};
  left: 0;
  top: 0.3em;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  ${({ status }) =>
    status &&
    statusOptions[status].type === "half" &&
    css`
      &:before {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        background-color: ${statusOptions[status].color};
        position: absolute;
        top: 0;
        left: 0;
      }
    `}
`;
