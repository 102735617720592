import React from "react";
import _ from "lodash";
import { ApplicationContext } from "../context/application";
import { useParams } from "react-router";
import { TParams } from "../pages/Application";
import { updateApplicationWorkingData } from "./useUpdateDataField";

type ChildrenControls = {
  removeChild: (childIndex: number) => void;
  addChild: () => void;
  removeAllChildren: () => void;
};

const useChildren = (): ChildrenControls => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const { applicationID } = useParams<TParams>();

  const setChildren = (children: any[]) => {
    dispatch({
      type: "updateWorkingApplicationState",
      field: `Data.children`,
      value: children
    });
    dispatch({
      type: "updateLocalApplicationState",
      field: `Data.children`,
      value: children
    });
    updateApplicationWorkingData(applicationID, { children });
  }

  const getChildren = () => _.get(
    state,
    `workingApplicationState.Data.children`
  );


  const removeChild = (childIndex: number) => {
    const children = getChildren();
    if (children.length <= 1) {
      setChildren([]);
    } else {
      setChildren([
        ...children.slice(0, childIndex),
        ...children.slice(childIndex + 1)
      ]);
    }
  };

  const addChild = () => {
    const newChild = {
      index: "",
    };
    const children = getChildren();
    if (_.isEmpty(children)) {
      setChildren([newChild]);
    } else {
      setChildren([...children, newChild]);
    }
  };

  const removeAllChildren = () => {
    setChildren([]);
  };

  return {
    addChild,
    removeChild,
    removeAllChildren,
  };
};

export default useChildren;
