import React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { rem } from "polished";

import { colors } from "../styles/variables";

import { ReactComponent as EditIcon } from "../assets/icons/icon-edit.svg";
import { ReactComponent as RevertIcon } from "../assets/icons/icon-revert.svg";
import { ReactComponent as SaveIcon } from "../assets/icons/icon-save.svg";
import { ReactComponent as ForbiddenIcon } from "../assets/icons/icon-forbidden.svg";

import ButtonInline from "./ButtonInline";

type Props = {
  hide: boolean;
  showSavingIndicator: boolean;
  showCommitButton: boolean;
  showRevertButton: boolean;
  showEditButton: boolean;
  showCancelButton: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: React.MouseEventHandler<HTMLButtonElement>;
  handleRevert: React.MouseEventHandler<HTMLButtonElement>;
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  immutable: boolean;
};

const PortalQuestionButtons: React.FC<Props> = ({
  hide,
  showSavingIndicator,
  showCommitButton,
  showRevertButton,
  showEditButton,
  showCancelButton,
  setEdit,
  handleSave,
  handleRevert,
  handleCancel,
  immutable,
}) => {
  if (hide) {
    return null;
  }

  if (showSavingIndicator) {
    return <Saving type="bubbles" />;
  }

  return (
    <Buttons>
      {showCommitButton && (
        <ButtonInline onClick={handleSave} icon={<SaveIcon />}>
          Commit edit
        </ButtonInline>
      )}
      {showCancelButton && (
        <ButtonInline onClick={handleCancel} alert>
          Cancel
        </ButtonInline>
      )}
      {showEditButton && (
        <ButtonInline
          onClick={() => setEdit(true)}
          icon={immutable ? <ForbiddenIcon /> : <EditIcon />}
          disabled={immutable}
        >
          Edit
        </ButtonInline>
      )}
      {showRevertButton && (
        <ButtonInline onClick={handleRevert} icon={<RevertIcon />} alert>
          Revert
        </ButtonInline>
      )}
    </Buttons>
  );
};

export default PortalQuestionButtons;

const Saving = styled(ReactLoading)`
  margin-left: auto;
  align-items: flex-end;
  height: 38px !important;
  width: 38px !important;
  fill: ${colors.blue};
  color: ${colors.blue};
  svg {
    height: 38px;
    width: 38px;
  }
`;

export const Buttons = styled.div`
  margin-left: auto;
  align-self: flex-start;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.2s linear;
  min-height: ${rem(38)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;
