import { rem } from "polished";
import styled, { css } from "styled-components/macro";
import { colors } from "../styles/variables";

const secondaryCss = css`
  background-color: #f4f7fb;
  color: ${colors.blue};
`;

const outlineCss = css`
  background-color: transparent;
  border: 1px solid currentColor;
  color: ${colors.blue};
`;

const claseCss = css`
  background-color: transparent;
  color: ${colors.blue};
  text-decoration: underline;
`;

const inlineCss = css`
  background-color: transparent;
  color: ${colors.blue};
  text-decoration: underline;
  padding: 0;
  min-width: auto;
  font-weight: 400;
`;

const slimCss = css`
  min-width: auto;
  width: auto;
  height: auto;
  line-height: 1.2;
  text-align: center;
`;

const alertCss = css`
  background-color: ${colors.alertRed};
`;

const smallCss = css`
  font-size: ${rem(14)};
  font-weight: 400;
  padding: 0.5em;
  vertical-align: middle;
  line-height: ${rem(14)};
`;

const Button = styled.button<{
  secondary?: boolean;
  outline?: Boolean;
  clear?: boolean;
  inline?: boolean;
  slim?: boolean;
  alert?: boolean;
  small?: boolean;
}>`
  font-size: 0.9375em;
  min-width: 10.4em;
  height: 2.53em;
  line-height: 2.53;
  border: none;
  background-color: ${colors.blue};
  color: #fff;
  border-radius: 3px;
  white-space: nowrap;
  padding: 0 1.1em;
  cursor: pointer;
  font-weight: 400;

  &:disabled {
    background-color: #f4f7fb;
    color: ${colors.gray};
  }

  & + & {
    margin-left: 6px;
  }

  ${({ secondary }) => secondary && secondaryCss}
  ${({ outline }) => outline && outlineCss}
  ${({ clear }) => clear && claseCss}
  ${({ inline }) => inline && inlineCss}
  ${({ slim }) => slim && slimCss}
  ${({ alert }) => alert && alertCss}
  ${({ alert, outline }) =>
    alert &&
    outline &&
    css`
      background: transparent;
      color: ${colors.alertRed};
    `}
  ${({ small }) => small && smallCss}
`;

export default Button;
