import React from "react";
import _ from "lodash";
import { DeclarationOfTruth } from "csv-package";

import { ApplicationContext } from "../context/application";
import styled from "styled-components";

const PortalDeclarationOfTruth: React.FC<{}> = () => {
  const [{ config, applicationState }] = React.useContext(ApplicationContext);

  if (!config) {
    return null;
  }

  const firstName = config.declarationOfTruth.applicantFirstNameFields.reduceRight(
    (a, b) => {
      const value = _.get(applicationState, `Data.${b}`);
      return value ? value : a;
    },
    ""
  );

  const familyName = config.declarationOfTruth.applicantFamilyNameFields.reduceRight(
    (a, b) => {
      const value = _.get(applicationState, `Data.${b}`);
      return value ? value : a;
    },
    ""
  );

  const dob = config.declarationOfTruth.applicantDOBFields.reduceRight(
    (a, b) => {
      const value = _.get(applicationState, `Data.${b}`);
      return value ? value : a;
    },
    ""
  );

  const checked = _.get(
    applicationState,
    `Data.${config.declarationOfTruth.checkedField}`
  );

  const applicantName = `${firstName} ${familyName}`;

  return (
    <Wrapper>
      <Line />
      <DeclarationOfTruth
        applicantName={applicantName}
        applicantDOB={dob}
        checked={checked}
        onCheckboxChange={() => null}
        disabled
      />
    </Wrapper>
  );
};

export default PortalDeclarationOfTruth;

const Wrapper = styled.div`
  padding: 10px 15px 0;
`;

const Line = styled.div`
  border-top: 1px solid #d4e1ed;
`;
