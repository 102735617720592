import React from "react";
import Loading from "react-loading";
import { useParams } from "react-router";
import styled from "styled-components";
import _ from "lodash";
import { API, Auth } from "aws-amplify";
import { ApplicationDataType, ApplicationStatusType } from "csv-package";

import { ApplicationContext } from "../context/application";
import updateApplicationStatus from "../helpers/updateApplicationStatus";
import { TParams } from "../pages/Application";
import Button from "./Button";
import Modal from "./Modal";
import TableStatus from "./TableStatus";

type Props = {};

const ModalCourtlink: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const { applicationID } = useParams<TParams>();
  const [processing, setProcessing] = React.useState<boolean>(false);

  const getNextStatus = (): ApplicationStatusType => {
    if (state.applicationState!.Data.seeking_appl_role === "resp") {
      return "verified-awaiting-leave";
    }
    return "verified";
  };

  const saveAnyDirtyData = async (): Promise<void> => {
    const body: {
      ApplicationID: string;
      Narrative?: string;
      Data?: ApplicationDataType;
    } = {
      ApplicationID: applicationID,
    };

    // check if Narrative need to be included
    if (
      !_.isEqual(
        state.workingApplicationState!.Narrative,
        state.applicationState!.Narrative
      )
    ) {
      body["Narrative"] = state.workingApplicationState!.Narrative;
    }

    // check if Data needs to be updated
    // Get a list of Data keys that have been changed
    const differenceKeys = _(state.workingApplicationState!.Data)
      .keys()
      .filter(
        (k) =>
          !_.isEqual(
            state.workingApplicationState!.Data[k],
            state.applicationState!.Data[k]
          )
      )
      .value();
    if (differenceKeys.length > 0) {
      body["Data"] = _.pick(
        state.workingApplicationState!.Data,
        differenceKeys
      ) as ApplicationDataType;
    }

    // no updates required exit
    if (!body.Data && !body.Narrative) {
      return;
    }

    // Do API

    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body,
    };
    try {
      const result = await API.post(
        "csvApi",
        "/private/registrar/updateApplicationState",
        myInit
      );
      // if sucessful update core application state
      if (result) {
        // update data
        _.forIn(body.Data, (value, field) =>
          dispatch({
            type: "updateApplicationState",
            field: `Data.${field}`,
            value,
          })
        );
        // update narrative
        dispatch({
          type: "updateApplicationState",
          field: `Narrative`,
          value: state.workingApplicationState!.Narrative,
        });
      }
    } catch (error) {
      // If there was an error alert the user and reset UI
      alert("Error while updating application Data. Please try again.");
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setProcessing(true);
    await saveAnyDirtyData();
    await updateApplicationStatus(
      applicationID,
      getNextStatus(),
      state,
      dispatch
    );
    setProcessing(false);
    dispatch({ type: "setActiveModal", modal: null });
  };

  return (
    <Modal
      type="confirm"
      size="small"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      <p>You're about to submit application {applicationID} to Courtlink.</p>
      <p>
        You will not be able to update information once you've submitted. Please
        confirm
      </p>
      <Progress>
        <TableStatus status={state.applicationState!.Status} /> &nbsp; ⇨ &nbsp;
        <TableStatus status={getNextStatus()} />
      </Progress>

      <footer>
        {processing ? (
          <Loading type="spin" />
        ) : (
          <>
            <Button
              outline
              onClick={() => dispatch({ type: "setActiveModal", modal: null })}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Confirm submit</Button>
          </>
        )}
      </footer>
    </Modal>
  );
};

export default ModalCourtlink;

export const Progress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
