import React from "react";
import { useParams } from "react-router";
import { ApplicationContext } from "../context/application";
import updateApplicationStatus from "../helpers/updateApplicationStatus";
import { TParams } from "../pages/Application";
import Button from "./Button";
import { Progress } from "./ModalCourtlink";

import Modal from "./Modal";
import TableStatus from "./TableStatus";

type Props = {};

const ModalUpdateStatus: React.FunctionComponent<Props> = () => {
  const { applicationID } = useParams<TParams>();
  const [state, dispatch] = React.useContext(ApplicationContext);
  const isEvr = state?.config?.applicationType === "EVR";

  const { targetStatus } = state;

  if (targetStatus === null) {
    return null;
  }

  return (
    <Modal
      type={isEvr ? "confirm" : "alert"}
      size="small"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      {!isEvr ? <p>You're about to change the status of application {applicationID}.</p> : <p>You are confirming that application {applicationID} has been manually transferred to Courtlink.</p>}
      {isEvr && <p>You will not be able to update the online application information once you’ve confirmed .</p>}
      <p>Please confirm:</p>
      <Progress>
        <TableStatus status={state.applicationState!.Status} /> &nbsp; ⇨ &nbsp;
        <TableStatus status={targetStatus} />
      </Progress>
      <footer>
        <Button
          alert={!isEvr}
          outline
          onClick={() => dispatch({ type: "setActiveModal", modal: null })}
        >
          Cancel
        </Button>
        <Button
          alert={!isEvr}
          onClick={() => {
            updateApplicationStatus(
              applicationID,
              targetStatus,
              state,
              dispatch
            );
            dispatch({ type: "setActiveModal", modal: null });
          }}
        >
          {isEvr ? 'Confirm' : 'Continue'}
        </Button>
      </footer>
    </Modal>
  );
};

export default ModalUpdateStatus;
