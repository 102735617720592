import React from "react";
import styled from "styled-components/macro";
import { colors } from "../styles/variables";
// import { ReactComponent as IconEdit } from "../assets/icons/edit-icon.svg";
// import Button from "./Button";

type Props = {};

const SectionHeading: React.FunctionComponent<Props> = ({ children }) => (
  <H2>
    {children}
    {/* <div>
      {!editMode && (
        <Button className="icon" clear slim onClick={() => setEditMode(true)}>
          <IconEdit /> Edit
        </Button>
      )}
      {editMode && (
        <Button clear slim inline small onClick={() => setEditMode(false)}>
          Cancel
        </Button>
      )}
      {editMode && status === "dirty" && (
        <Button small style={{ minWidth: "auto" }}>
          ReDeclare
        </Button>
      )}
    </div> */}
  </H2>
);

export default SectionHeading;

const H2 = styled.h2`
  display: flex;
  justify-content: space-between;

  button.icon {
    padding: 0 0 0 1.1875em;
    margin: 0;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.blue};
    height: 1em;
    line-height: 1;
    position: relative;
    text-decoration: underline;
    min-width: auto;
    cursor: pointer;
    &:focus {
      outline: none;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 1em;
      width: 1em;
    }
  }
`;
