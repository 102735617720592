import React from "react";
import styled from "styled-components/macro";
import { ApplicationDataType, conditionsChecker } from "csv-package";

import { RiskSummaryConfigType } from "../config";
import { colors } from "../styles/variables";
import Spacer from "./Spacer";

type Props = {
  riskSummaries: RiskSummaryConfigType[];
  applicationData: ApplicationDataType;
};

const RiskSummary: React.FC<Props> = ({ riskSummaries, applicationData }) => {
  const filteredRiskSummaries = riskSummaries.filter((r) =>
    conditionsChecker(r.conditions, applicationData)
  );

  if (filteredRiskSummaries.length <= 0) {
    return <Spacer />;
  }

  return (
    <Container>
      <h4>Risk summary</h4>
      <RiskList>
        {filteredRiskSummaries.map((r) => (
          <li key={r.text}>{r.text}</li>
        ))}
      </RiskList>
    </Container>
  );
};

export default RiskSummary;

const Container = styled.div`
  margin: 38px 0 10px;
  background-color: #f8d9dd;
  padding: 1.125rem;
  border-radius: 0.375rem;

  h4 {
    color: ${colors.alertRed};
    margin: 0;
    margin-bottom: 0.625rem;
    font-weight: 300;
  }
`;

const RiskList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding-left: 0.7em;
    text-indent: -0.7em;
    margin-bottom: 0.5rem;
  }
  li::before {
    content: "• ";
    color: ${colors.alertRed};
    padding-right: 0.15em;
  }
`;
