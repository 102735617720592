import { API, Auth } from "aws-amplify"
import _ from "lodash"
import { rem } from "polished"
import React from "react"
import Loading from "react-loading"
import { RouteComponentProps } from "react-router-dom"
import styled from "styled-components/macro"
import TimeAgo from "timeago-react"
import { ApplicationStatusType, ApplicationType, RevisionType } from "csv-package"

import ApplicationActions from "../components/ApplicationActions"
import ApplicationModals from "../components/ApplicationModals"
import ApplicationNotes from "../components/ApplicationNotes"
import ApplicationRevisionLog from "../components/ApplicationRevisionLog"
import Button from "../components/Button"
import ContentContainer from "../components/ContentContainer"
import FlagEditBlock from "../components/FlagEditBlock"
import Narrative from "../components/Narrative"
import ReDeclareStatusBar from "../components/ReDeclareStatusBar"
import RiskSummary from "../components/RiskSummary"
import Select from "../components/Select"
import SideMenu from "../components/SideMenu"

import { ReactComponent as PrintIcon } from "../assets/icons/Print.svg"

import { ApplicationContext, initialState, reducer } from "../context/application"
import { GlobalContext } from "../context/global"

import { applicationStatues, applicationStatusObjects } from "../helpers/applicationStatus"
import getCourt from "../helpers/getCourt"
import optomisitcUpdate from "../helpers/optomisitcUpdate"
import optomisticUpdateFlag from "../helpers/optomisticUpdateFlag"

import { UserType } from "../types"
import configs from "../config"
import PortalQuestions from "../components/PortalQuestions"
import applicationStatusTransitionMatrix from "../helpers/applicationStatusTransitionMatrix"
import PortalDeclarationOfTruth from "../components/PortalDeclarationOfTruth"
import Spacer from "../components/Spacer"
import ButtonInline from "../components/ButtonInline"

export type TParams = { applicationID: string }

const Application: React.FunctionComponent<RouteComponentProps<TParams>> = ({ match }) => {
  const { globalState } = React.useContext(GlobalContext)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  // Fetch Application on mount
  React.useEffect(() => {
    // fetch application
    const fetchApplication = async () => {
      const apiName = "csvApi"
      const path = "/private/registrar/getApplication"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
          ApplicationID: match.params.applicationID
        }
      }

      const result: ApplicationType = await API.post(apiName, path, myInit)

      dispatch({
        type: "setConfig",
        config: configs[result.Jurisdiction][result.Type]
      })

      dispatch({
        type: "initialiseApplicationState",
        application: result
      })
    }

    fetchApplication()
  }, [dispatch, match.params.applicationID, globalState.user])

  // Fetch registrar list for court on court update
  React.useEffect(() => {
    const fetchCourtRegistrars = async () => {
      const apiName = "csvApi"
      const path = "/private/registrar/getCourtRegistrars"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
          courtID: state.applicationState!.CourtID
        }
      }

      const result: UserType[] = await API.post(apiName, path, myInit)
      dispatch({
        type: "setCourtRegistrars",
        registrars: result
      })
    }
    if (state.applicationState !== null) {
      fetchCourtRegistrars()
    }
    // eslint-disable-next-line
  }, [state.applicationState?.CourtID])

  const getLastStatus = React.useCallback((): ApplicationStatusType => {
    // this func will reverse the revision log and look for a status that isn't dnp
    if (!state.applicationState || state.applicationState.RevisionLog.length < 2) {
      throw new Error("Missing revision log")
    }

    const reversedLogs = state.applicationState.RevisionLog.sort((a, b) => (a.Datetime < b.Datetime ? 1 : -1))

    const foundLog =
      Array.isArray(reversedLogs) &&
      reversedLogs.find((l: RevisionType): boolean => Boolean(l.Status && l.Status !== "dnp"))
    if (foundLog === undefined || foundLog === false || foundLog.Status === undefined) {
      throw new Error("Missing revision log status")
    }
    return foundLog.Status
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.applicationState?.Status])

  const handleUpdateRegistrar = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const currentValue = state.applicationState?.Registrar || "unassigned"
    const nextValue = e.target.value === "unassigned" ? "" : e.target.value

    await optomisitcUpdate({
      apiBody: {
        email: nextValue
      },
      apiPath: "/private/registrar/updateApplicationRegistrar",
      ApplicationID: match.params.applicationID,
      currentValue: currentValue,
      nextValue: nextValue,
      field: "Registrar",
      dispatch
    })
  }

  const handleUpdateAppointmentStatus = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const currentValue = _.get(state, `applicationState.Flags.appointment_booked`, false)
    const nextValue = e.target.value === "booked"

    await optomisticUpdateFlag({
      flagName: "appointment_booked",
      ApplicationID: match.params.applicationID,
      currentValue,
      nextValue,
      dispatch
    })
  }

  const handleFlagUpdate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = _.get(state.applicationState, e.target.name, false)
    const nextValue = !_.get(state.applicationState, e.target.name, false)

    await optomisticUpdateFlag({
      flagName: e.target.name.split(".")[1],
      ApplicationID: match.params.applicationID,
      currentValue,
      nextValue,
      dispatch
    })
  }

  if (state.applicationState === null) {
    return (
      <LoadingContainer>
        <Loading type="spin" />
      </LoadingContainer>
    )
  }

  const court = getCourt(state.applicationState.CourtID)

  return (
    <ApplicationContext.Provider value={[state, dispatch]}>
      <ReDeclareStatusBar />
      <ContentContainer>
        <ApplicationPageContainer>
          <SideBarHolder>
            <SideMenu />
          </SideBarHolder>
          <MainHolder>
            <header id="summary">
              <ApplicationTitleBar>
                <p>{match.params.applicationID}</p>
                <ButtonInline onClick={() => window.print()} icon={<PrintIcon />}>
                  Print
                </ButtonInline>
              </ApplicationTitleBar>
              <h2>
                {state.applicationState.Jurisdiction.toLowerCase() === "support" ? (
                  <>{state.applicationState.Data.call_name}</>
                ) : (
                  <>
                    {state.applicationState.Data.appl_det_giv_nam || state.applicationState.Data.ap_giv_nam}{" "}
                    {state.applicationState.Data.appl_det_fam_nam || state.applicationState.Data.ap_fam_nam}
                  </>
                )}
              </h2>
              <p>
                {new Date(state.applicationState.Submitted).toLocaleDateString("en-AU")}{" "}
                <span className="light">
                  <TimeAgo datetime={state.applicationState.Submitted} />
                </span>
              </p>
              <p>{court.name}</p>
              <MetaControls>
                <Button
                  inline
                  slim
                  onClick={() =>
                    dispatch({
                      type: "setActiveModal",
                      modal: "confirm-change-court"
                    })
                  }
                >
                  Change
                </Button>
                {state.applicationState.Status === "dnp" && (
                  <Button
                    inline
                    slim
                    onClick={() =>
                      dispatch({
                        type: "setActiveModal",
                        modal: "confirm-status-update",
                        targetStatus: "archived"
                      })
                    }
                  >
                    Archive application
                  </Button>
                )}
              </MetaControls>
              <hr />
              <br />
              <MetaControls>
                <SelectMetaControl>
                  <label htmlFor="Registrar">Assigned to</label>
                  <Select
                    name="Registrar"
                    value={state.applicationState.Registrar || "unassigned"}
                    onChange={handleUpdateRegistrar}
                  >
                    <option value="unassigned">Unassigned</option>
                    {state.courtRegistrars
                      .sort((a, b) => (a.given_name < b.given_name ? -1 : 1))
                      .map(({ email, given_name, family_name }) => (
                        <option key={email} value={email}>
                          {given_name} {family_name}
                        </option>
                      ))}
                    {
                      // check if a registrar is set but not in the list
                      state.applicationState.Registrar &&
                        state.courtRegistrars.find((r) => r.email === state.applicationState!.Registrar) ===
                          undefined && (
                          <option value={state.applicationState.Registrar}>
                            {state.applicationState.Registrar}
                          </option>
                        )
                    }
                  </Select>
                </SelectMetaControl>
                <SelectMetaControl>
                  <label htmlFor="Flags.appointment_booked">Appointment status</label>
                  <Select
                    name="Flags.appointment_booked"
                    value={state.applicationState.Flags!.appointment_booked ? "booked" : "not_booked"}
                    onChange={handleUpdateAppointmentStatus}
                  >
                    <option value="not_booked">Not Booked</option>
                    <option value="booked">Booked</option>
                  </Select>
                </SelectMetaControl>
                <SelectMetaControl>
                  <label htmlFor="Status">Application status</label>
                  <Select
                    name="Status"
                    id="Status"
                    onChange={(e) => {
                      dispatch({
                        type: "setActiveModal",
                        modal: "confirm-status-update",
                        targetStatus: e.target.value as ApplicationStatusType
                      })
                    }}
                    value={state.applicationState.Status}
                  >
                    {applicationStatues.map((status) => (
                      <option
                        key={status}
                        value={status}
                        disabled={
                          state.applicationState!.Status === "dnp"
                            ? status !== getLastStatus()
                            : !applicationStatusTransitionMatrix[state.applicationState!.Status].includes(
                                status
                              )
                        }
                      >
                        {applicationStatusObjects[status].name}
                      </option>
                    ))}
                  </Select>
                </SelectMetaControl>
              </MetaControls>
              {state.config?.riskSummary && (
                <RiskSummary
                  riskSummaries={state.config.riskSummary}
                  applicationData={state.applicationState.Data}
                />
              )}
              <Flags>
                {state.config!.flags.map((flag) => (
                  <FlagEditBlock
                    key={flag}
                    type={flag}
                    checked={_.get(state, `applicationState.Flags.${flag}`, false)}
                    handleOnChange={handleFlagUpdate}
                  />
                ))}
              </Flags>
            </header>
            <PrintNarrative>
              <h3>Narrative</h3>
              <pre>{state.workingApplicationState?.Narrative || ""}</pre>
            </PrintNarrative>
            <SummaryQuestions>
              <PortalQuestions questionSet="summaryQuestions" />
            </SummaryQuestions>
            <ApplicationNotes />
            <ApplicationRevisionLog logs={state.applicationState.RevisionLog} />
            <PortalQuestions questionSet="mainQuestions" />
            <PortalDeclarationOfTruth />
            <Spacer height={50} />
          </MainHolder>
          <NarrativeHolder>
            <div>
              <Narrative />
              <ApplicationActions />
            </div>
          </NarrativeHolder>
        </ApplicationPageContainer>
      </ContentContainer>
      <ApplicationModals />
    </ApplicationContext.Provider>
  )
}

export default Application

const gutter = 15
const sideBarWidth = 160
const mainWidth = 620

const ApplicationPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 32px;
`

const SideBarHolder = styled.div`
  width: ${sideBarWidth}px;
  margin-right: ${gutter}px;
  position: relative;

  > * {
    position: sticky;
    top: 32px;
    left: 0;
  }

  @media print {
    display: none;
  }
`

const MainHolder = styled.main`
  width: ${rem(mainWidth)};

  .light {
    font-weight: 300;
  }

  > header > p:first-child {
    margin-top: 0;
  }

  hr {
    color: rgb(212, 225, 237);
    border: none;
    border-bottom: 1px solid currentColor;
  }

  header {
    padding: 0 15px 0;

    @media print {
      padding: 0;
    }
  }

  @media print {
    width: 90%;
  }
`

const PrintNarrative = styled.div`
  display: none;
  pre {
    font-family: inherit;
    white-space: pre-line;
  }
  @media print {
    display: block;
    padding-bottom: 1.2em;
    font-family: inherit;
  }
`

const NarrativeHolder = styled.div`
  margin-left: auto;
  position: relative;
  width: calc(100% - ${sideBarWidth + gutter + 39 + mainWidth}px);

  > * {
    position: sticky;
    top: 32px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  @media print {
    display: none;
  }
`

const MetaControls = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectMetaControl = styled.div`
  width: calc(33.333% - 9px);
  label {
    color: rgb(109, 109, 109);
    font-size: 0.875rem;
    margin-bottom: 0.7em;
    display: block;
  }

  select {
    width: 100%;
  }
`

const Flags = styled.div`
  display: flex;
`

const LoadingContainer = styled.div`
  display: flex;
  height: calc(100vh - 100px);
  justify-content: center;
  align-items: center;
`

const SummaryQuestions = styled.div`
  width: calc(100% + ${rem(36)});
  margin-left: -${rem(18)};
`

const ApplicationTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`
