import React from "react";
import styled from "styled-components/macro";
import { Link, Events } from "react-scroll";
import { colors } from "../styles/variables";
import { ApplicationContext } from "../context/application";
import { SideMenuType } from "../config";
import { conditionsChecker } from "csv-package";

type Props = {};

const SideMenu: React.FunctionComponent<Props> = () => {
  const [state] = React.useContext(ApplicationContext);
  const reDeclareData = state.workingApplicationState!.Data;

  React.useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
  }, []);

  if (state.config === null) {
    return null;
  }

  const renderSideMenuItem = (menuItem: SideMenuType) => {
    if(menuItem.conditions && !conditionsChecker(menuItem.conditions, reDeclareData)){
      return null;
    }
    return (
      <ListItem key={menuItem.name}>
          <Link smooth={true} to={menuItem.name} spy={true}>
            {menuItem.label}
          </Link>
        </ListItem>
    );
  }

  return (
    <SideMenuOuter>
      {state.config.sideMenu.map((menuItem: SideMenuType) => (
        renderSideMenuItem(menuItem)
      ))}
    </SideMenuOuter>
  );
};

export default SideMenu;

const ListItem = styled.li`
  cursor: pointer;
  margin-bottom: 0.625rem;
  color: ${colors.gray};

  > a.active {
    color: ${colors.blue};
  }
`;

const SideMenuOuter = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
