import React from "react";
import { ConditionsType, conditionsChecker } from "csv-package";
import styled from "styled-components/macro";

import PortalQuestion from "../components/PortalQuestion";
import PortalQuestionAddress from "../components/PortalQuestionAddress";
import QuestionsFrame from "../components/QuestionFrame";
import SectionHeading from "../components/SectionHeading";
import { ApplicationContext } from "../context/application";
import { QuestionSectionType, QuestionConfigType } from "../config";
import ConditionalHeading from "./ConditionalHeading";
import IconButton from "./IconButton";
import useChildren from "../hooks/useChildren";

type Props = {
  questionSet: "mainQuestions" | "summaryQuestions";
};

const QuestionLogic: React.FunctionComponent<{
  childIndex?: number;
  question: QuestionConfigType;
}> = ({ question, childIndex }) => {

  const transformName = React.useCallback(
    (name: string): string => {
      if (typeof childIndex === "number") {
        return `children[${childIndex}].${name}`;
      }
      return name;
    },
    [childIndex]
  );

  const transformConditions = React.useCallback(
    (conditionsObject?: ConditionsType): ConditionsType | undefined => {
      if (conditionsObject === undefined) {
        return conditionsObject;
      }
      if (typeof childIndex === "number") {
        return Object.keys(conditionsObject).reduce((a, c) => {
          const key = c.replace(new RegExp("\\[x\\]", "g"), `[${childIndex}]`);
          return {
            ...a,
            [key]: conditionsObject[c],
          };
        }, {});
      }
      return conditionsObject;
    },
    [childIndex]
  );

  // regular question
  if (typeof question === "string") {
    return <PortalQuestion name={transformName(question)} immutable={false} />;
  }

  // question with conditions
  if (typeof question === "object" && "name" in question) {
    return (
      <PortalQuestion
        label={question.label}
        name={transformName(question.name)}
        conditions={transformConditions(question.conditions)}
        immutable={Boolean(question.immutable)}
        options={question.options}
      />
    );
  }

  // address question
  if (typeof question === "object" && "namePrefix" in question) {
    return (
      <PortalQuestionAddress
        namePrefix={transformName(question.namePrefix)}
        label={question.label}
        conditions={transformConditions(question.conditions)}
        immutable={Boolean(question.immutable)}
      />
    );
  }

  // heading
  if (typeof question === "object" && "text" in question) {
    return (
      <ConditionalHeading conditions={transformConditions(question.conditions)}>
        {question.text}
      </ConditionalHeading>
    );
  }
  return null;
};

const PortalQuestions: React.FunctionComponent<Props> = ({ questionSet }) => {
  const [state] = React.useContext(ApplicationContext);
  const { applicationState, workingApplicationState, config } = state;
  const { addChild, removeChild } = useChildren();

  if (
    applicationState === null ||
    workingApplicationState === null ||
    config === null
  ) {
    return null;
  }
  const Data = workingApplicationState.Data;

  const questions = config[questionSet];

  return (
    <fieldset
      disabled={[
        "verified",
        "re-declare-requested",
        "dnp",
        "archived",
      ].includes(applicationState.Status)}
      style={{ padding: "0", border: "none" }}
    >
      {questions.map((section: QuestionSectionType, index: number) => (
        <section id={section.id} key={section.id || index}>
          {section.frames.map((frame, index) => {
            if (
              frame.conditions &&
              !conditionsChecker(frame.conditions, workingApplicationState.Data)
            ) {
              return null;
            }

            if (
              frame.children &&
              (!Array.isArray(Data.children) || Data.children.length === 0)
            ) {
              return null;
            }

            return (
              <QuestionsFrame
                privateFrame={frame.private || false}
                key={index}
                id={frame.id}
                privateHeading={frame.privateHeading || undefined}
              >
                {frame.heading && (
                  <SectionHeading>{frame.heading}</SectionHeading>
                )}
                {frame.subHeading && <h3>{frame.subHeading}</h3>}
                {frame.contentHeading && <h4>{frame.contentHeading}</h4>}

                {frame.children &&
                  Array.isArray(Data.children) &&
                  <>
                    {Data.children
                      .sort((a, b) => a.index - b.index)
                      .map((data, childIndex) => (
                        <div key={childIndex}>
                          <h3>Child {childIndex + 1}</h3>
                          {frame.questions.map((question: any, i) => (
                            <QuestionLogic
                              question={question}
                              childIndex={childIndex}
                              key={i}
                            />
                          )
                          )}
                        </div>
                      ))}
                    <br />
                    <BottomActions>
                      <IconButton
                        label="Remove Child"
                        icon="plus"
                        rotateIcon
                        alert
                        disabled={Data.children.length <= 1}
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            window.confirm("Are you sure you want to remove this child")
                          ) {
                            removeChild(Data.children.length - 1);
                          }
                        }}
                      />
                      <IconButton
                        icon="plus"
                        disabled={Data.children.length >= 8}
                        onClick={(e) => {
                          e.preventDefault();
                          addChild();
                        }}
                        label="Add another child"
                      />
                    </BottomActions>
                  </>}
                {!frame.children &&
                  frame.questions.map((question, i) => (
                    <QuestionLogic question={question} key={i} />
                  ))}
              </QuestionsFrame>
            );
          })}
        </section>
      ))}
    </fieldset>
  );
};

export default PortalQuestions;

const BottomActions = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
