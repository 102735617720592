import _ from "lodash";

import { ApplicationStateType } from "../context/application";

const useReDeclareRequired = (state: ApplicationStateType): boolean => {
  if (
    ["archived", "dnp", "re-declare-requested", "verified"].includes(
      state.applicationState!.Status
    )
  ) {
    return false;
  }

  const compareFields = ["Data", "Narrative"];

  const pickedApplicationState = _(state.applicationState)
    .pick(compareFields)
    .pickBy(_.identity)
    .value();
  const pickedWorkingApplicationState = _(state.workingApplicationState)
    .pick(compareFields)
    .pickBy(_.identity)
    .value();

  return !_.isEqual(pickedApplicationState, pickedWorkingApplicationState);
};

export default useReDeclareRequired;
