import React from "react";
import styled from "styled-components/macro";
import { Auth } from "aws-amplify";

import { GlobalContext } from "../context/global";

import ContentContainer from "./ContentContainer";
import { Link } from "react-router-dom";

import { ReactComponent as MCVLogo } from "../assets/images/mcv-logo-long.svg";
import { colors } from "../styles/variables";

type Props = {};

const Header: React.FunctionComponent<Props> = (props) => {
  const { globalState } = React.useContext(GlobalContext);
  return (
    <header>
      <MCVBar>
        <Content>
          <MCVLogo />
        </Content>
      </MCVBar>
      <MenuBar>
        <Content>
          <Link to="/">Dashboard</Link>
          {globalState.user && (
            <button onClick={() => Auth.signOut()}>Logout</button>
          )}
        </Content>
      </MenuBar>
    </header>
  );
};

export default Header;

const MCVBar = styled.div`
  height: 3.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  svg {
    width: 174px;
    height: 23px;
  }
`;

const MenuBar = styled.div`
  width: 100%;
  height: 3.06em;
  color: white;
  background: ${colors.blue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 1280px;

  @media print {
    display: none;
  }
`;

const Content = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  button {
    color: white;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
  }
`;
