import _ from "lodash";
import { ApplicationDataType } from "csv-package";

import { AddressType } from "../components/PortalQuestionAddress";

const generateAddressString = (
  data: ApplicationDataType | AddressType,
  namePrefix?: string
): string => {
  let addressString = "";

  if (namePrefix) {
    addressString += _.get(data, `${namePrefix}_flat`, "") || "";
    if (addressString !== "") {
      addressString += "/";
    }
    addressString += _.get(data, `${namePrefix}_strnr`, "");
    addressString += " " + _.get(data, `${namePrefix}_strname`, "");
    addressString += " " + _.get(data, `${namePrefix}_strtype`, "");
    addressString += ", " + _.get(data, `${namePrefix}_sub`, "");
    addressString += " " + _.get(data, `${namePrefix}_state`, "");
    addressString += " " + _.get(data, `${namePrefix}_postcode`, "");
  } else {
    addressString += _.get(data, `_flat`, "") || "";
    if (addressString !== "") {
      addressString += "/";
    }
    addressString += _.get(data, `_strnr`, "");
    addressString += " " + _.get(data, `_strname`, "");
    addressString += " " + _.get(data, `_strtype`, "");
    addressString += ", " + _.get(data, `_sub`, "");
    addressString += " " + _.get(data, `_state`, "");
    addressString += " " + _.get(data, `_postcode`, "");
  }

  if (typeof addressString !== "string") {
    throw new Error("Invalid address type generated");
  }

  return addressString;
};

export default generateAddressString;
