import { em, rem } from "polished";
import styled, { css } from "styled-components/macro";
import { colors } from "../styles/variables";

const Input = styled.input<{ error?: boolean }>`
  font-size: ${rem(16)};
  height: ${em(38, 16)};
  width: 100%;
  border-radius: 3px;
  padding-left: ${em(10)};
  padding-right: 0.5em;
  color: ${colors.black};
  appearance: none;
  border: 1px solid white;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${colors.alertRed};
    `}
`;
export default Input;
