import React from "react";
import { AuthData } from "../types";

export type GlobalStateType = {
  user: AuthData | null | undefined;
  courtIDs: string[];
};

const initialState = {
  user: undefined, // undefined = auth check hasn't taken place
  courtIDs: [],
};

type GlobalContextType = {
  globalState: GlobalStateType;
  setGlobalState: React.Dispatch<any>;
};

export const GlobalContext = React.createContext<GlobalContextType>({
  globalState: initialState,
  setGlobalState: (v) => {},
});

const GlobalContextProvider: React.FunctionComponent = ({ children }) => {
  const [globalState, setGlobalState] = React.useState<GlobalStateType>(
    initialState
  );
  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
