import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import { SchemaFieldType, conditionsChecker } from "csv-package";
import _ from "lodash";

import { ApplicationContext } from "../context/application";

import { colors } from "../styles/variables";
import { OptionType } from "../config";

type QuestionInputProps = {
  name: string;
  config: SchemaFieldType;
  value: string | string[];
  editMode: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  selectOptions?: OptionType[];
};

const QuestionInput: React.FunctionComponent<QuestionInputProps> = ({
  name,
  config,
  value,
  editMode,
  handleChange,
  selectOptions,
}) => {
  const [state] = React.useContext(ApplicationContext);

  let options = selectOptions || config.options;

  // check if there are conditions on the options
  // if yes run them through conditions checker
  if (Array.isArray(options) && options.find((o) => "conditions" in o) !== undefined) {
    options = options.filter(
      (o) => o.conditions && conditionsChecker(o.conditions, state.workingApplicationState!.Data)
    );
  }
  

  if (!config.multiple && ((options && Array.isArray(options)) || (selectOptions && Array.isArray(selectOptions)))) {
    let questionOptions = selectOptions ? selectOptions : options;
    if (!editMode) {
      const option = Array.isArray(questionOptions) && questionOptions.find((o) => o.value === value);
      return <PlainValue data-testid={name}>{option ? option.text : ""}</PlainValue>;
    }

    return (
      <StyledSelect
        name={name}
        id={name}
        value={typeof value === "string" ? value : ""}
        disabled={!editMode}
        onChange={handleChange}
      >
        <option value=""></option>
        {Array.isArray(questionOptions) && questionOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </StyledSelect>
    );
  }

  if (config.multiple === true && Array.isArray(options)) {
    // show values if value is an array and edit mode if off
    if (!editMode && !Array.isArray(value)) {
      return (
        <PlainValue>
          <ul>
            {options
              .filter((o) => value.includes(o.value))
              .map((o) => (
                <li key={o.value}>{o.text}</li>
              ))}
          </ul>
        </PlainValue>
      );
    }

    return (
      <>
        {options.map((option) => {
          let disabled = !editMode;

          const disableCheck = config.multiple && config.exclusiveOption && Array.isArray(value);

          if (
            disableCheck &&
            Array.isArray(config.exclusiveOption) &&
            _.intersection(value, config.exclusiveOption).length > 0 && // if one of exclusive options has been checked
            !_.intersection(value, config.exclusiveOption).includes(option.value) //if this option is not the checked exclusive option
          ) {
            disabled = true;
          } else if (
            disableCheck &&
            typeof config.exclusiveOption === "string" &&
            value.includes(config.exclusiveOption) &&
            option.value !== config.exclusiveOption
          ) {
            disabled = true;
          }

          return (
            <div key={option.value}>
              <StyledCheckbox
                name={name}
                id={`${name}-${option.value}`}
                value={option.value}
                checked={Array.isArray(value) && value.includes(option.value)}
                disabled={disabled}
                onChange={handleChange}
              />
              <label htmlFor={`${name}-${option.value}`}>{option.text}</label>
            </div>
          );
        })}
      </>
    );
  }

  if (!editMode) {
    return <PlainValue data-testid={name}>{value || ""}</PlainValue>;
  }

  return (
    <StyledInput
      type="text"
      name={name}
      value={value || ""}
      disabled={!editMode}
      onChange={handleChange}
      maxLength={config.max_length || undefined}
    />
  );
};

export default QuestionInput;

const disabledStyle = css`
  display: block;
  width: 15.625rem;
  border: none;
  background-color: transparent;
  color: ${colors.blue};
  height: ${rem(38)};
  opacity: 1;
  appearance: none;
`;

export const enabledStyle = css<{ hasError?: boolean }>`
  display: block;
  width: 15.625rem;
  border: 1px solid ${({ hasError }) => (hasError ? colors.alertRed : colors.gray)};
  border-radius: 3px;
  height: ${rem(38)};
  background-color: transparent;
  color: ${colors.black};
  opacity: 1;
  padding-left: 0.5em;
`;

const StyledInput = styled.input<{ disabled?: boolean }>`
  ${(props) => (props.disabled ? disabledStyle : enabledStyle)}
`;

const StyledSelect = styled.select<{ disabled?: boolean }>`
  ${(props) => (props.disabled ? disabledStyle : enabledStyle)}
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })<{
  disabled?: boolean;
  checked: boolean;
}>`
  & + label {
    margin-left: 0.5em;
    color: ${colors.blue};
  }
`;

const PlainValue = styled.div`
  ${disabledStyle}
  margin: 0;
  height: auto;
  ul {
    margin: 0;
    padding-left: 0;
  }
`;
