import { em, rem } from "polished";
import styled from "styled-components/macro";
import { colors } from "../styles/variables";

const Select = styled.select`
  font-size: ${rem(16)};
  height: ${em(38, 16)};
  width: 100%;
  border: 1px solid ${colors.gray};
  border-radius: 3px;
  padding-left: ${em(10)};
  padding-right: 0.5em;
  color: ${colors.black};
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 11 8" enable-background="new 0 0 11 8" xml:space="preserve" width="11px" height="8px"><g><path d="M5.54865 6.01236L9.84433 0.78273L10.6171 1.41746L5.54865 7.58783L0.480227 1.41746L1.25296 0.78273L5.54865 6.01236Z" /></g></svg>');
  background-size: ${em(11)};
  background-position: calc(100% - ${em(11)}) 50%;
  background-repeat: no-repeat;

  & + & {
    margin-left: 1rem;
  }

  &::last-of-type {
    margin-right: 1rem;
  }
`;

export default Select;
