import { rem } from "polished";
import React from "react";
import styled from "styled-components/macro";
import ReactLoading from "react-loading";
import _ from "lodash";

import { ApplicationContext } from "../context/application";

import { ReactComponent as UndoIcon } from "../assets/icons/icon-undo.svg";
import { ReactComponent as RedoIcon } from "../assets/icons/icon-redo.svg";

import { colors } from "../styles/variables";
import applicationCanBeEdited from "../helpers/applicationCanBeEdited";
import { API, Auth } from "aws-amplify";
import ButtonInline from "./ButtonInline";
import { useParams } from "react-router";
import { TParams } from "../pages/Application";

const narrativeMaxCharacters = 4510;

type Props = {};

const useDebounce = (value: string, delay: number): string => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

const Narrative: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [viewLastDeclared, setViewLastDeclared] = React.useState<boolean>(
    false
  );
  const [saving, setSaving] = React.useState<boolean>(false);
  const { applicationID } = useParams<TParams>();

  // Autosave
  const deBouncedNarrative = useDebounce(
    state.localApplicationState?.Narrative || "",
    3000
  );
  const narrativeNeedsSaving = React.useMemo(() => {
    return !_.isEqual(
      state.localApplicationState?.Narrative,
      state.workingApplicationState?.Narrative
    );
  }, [
    state.localApplicationState?.Narrative,
    state.workingApplicationState?.Narrative,
  ]);

  React.useEffect(() => {
    const save = async () => {
      setSaving(true);
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          ApplicationID: applicationID,
          Narrative: deBouncedNarrative,
        },
      };

      const result = await API.post(
        "csvApi",
        "/private/registrar/updateApplicationWorkingNarrative",
        myInit
      );

      dispatch({
        type: "updateWorkingApplicationState",
        field: "Narrative",
        value: result.WorkingNarrative,
      });
      setSaving(false);
    };

    if (
      deBouncedNarrative.length > 0 &&
      deBouncedNarrative !== "" &&
      narrativeNeedsSaving
    ) {
      save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deBouncedNarrative]);

  const handleNarrativeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const { value } = e.target;
    dispatch({
      type: "updateLocalApplicationState",
      field: "Narrative",
      value,
    });
  };

  if (state.localApplicationState === null) {
    return null;
  }

  const showButtons =
    state.applicationState?.Narrative !==
    state.workingApplicationState?.Narrative;

  return (
    <Container>
      <h4>Narrative</h4>
      <TextareaWrapper>
        <textarea
          name="Narrative"
          id="Narrative"
          value={
            viewLastDeclared
              ? state.applicationState?.Narrative || ""
              : state.localApplicationState?.Narrative || ""
          }
          onChange={handleNarrativeChange}
          disabled={!applicationCanBeEdited(state) || viewLastDeclared}
        />
      </TextareaWrapper>
      <NarrativeFooter>
        <div>
          {showButtons && !saving && (
            <ButtonInline
              onClick={() => setViewLastDeclared((v) => !v)}
              icon={viewLastDeclared ? <RedoIcon /> : <UndoIcon />}
            >
              {viewLastDeclared ? `View latest` : `View last declared`}
            </ButtonInline>
          )}
          {saving && (
            <Saving>
              <span>Saving</span>{" "}
              <ReactLoading type="bubbles" height="1.15em" width="2em" />
            </Saving>
          )}
        </div>

        <Counter

          error={
            narrativeMaxCharacters -
              state.localApplicationState!.Narrative?.length <
            0
          }
        >
          {narrativeMaxCharacters -
            state.localApplicationState!.Narrative?.length}{" "}
          characters remaining
        </Counter>
      </NarrativeFooter>
    </Container>
  );
};

export default Narrative;

const Container = styled.div`
  width: 100%;

  h4 {
    font-size: 1rem;
    font-weight: normal;
    color: ${colors.gray};
    font-weight: normal;
    margin-top: 0;
  }
`;

const NarrativeFooter = styled.footer`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 48px;
`;

const Counter = styled.p<{ error?: boolean }>`
  font-size: ${rem(15)};
  color: ${({ error }) => (error ? colors.alertRed : colors.gray)};
  text-align: right;
  line-height: 1;
  margin: 0;
`;

const TextareaWrapper = styled.div`
  position: relative;
  textarea {
    width: 100%;
    border: 1px solid ${colors.gray};
    border-radius: 3px;
    font-size: 1.125rem;
    padding: 1em 1em 2em;
    height: calc(100vh - 74px - 216px - 50px);
    color: ${colors.black};
    font-family: inherit;
  }
`;

const Saving = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.blue};
  align-items: center;
  svg {
    margin-left: 0.3em;
    transform: translateY(-0.2em);
  }
`;
