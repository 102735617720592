import { courts, CourtType } from "csv-package";

const getCourt = (courtID: string): CourtType => {
  const court = courts.find((court) => {
    return court.court_id === courtID;
  });
  if (!court) {
    throw new Error("getCourt errror - Court not found");
  }
  const { court_id, ...rest } = court;

  return {
    ...rest,
    court_id: court_id.toString(),
  };
};

export default getCourt;
