import { schemaFVIO as schema, fvioValidation } from "csv-package";
import { ApplicationConfigType, QuestionConfigType } from ".";

const validation = {
  ...fvioValidation.applicant,
  ...fvioValidation.children,
  ...fvioValidation.conditions,
  ...fvioValidation.incidentDetails,
  ...fvioValidation.previousOrders,
  ...fvioValidation.respondent,
  ...fvioValidation.review,
};

const fvioNewConfig: ApplicationConfigType = {
  schema,
  validation,
  sideMenu: [
    { name: "summary", label: "Summary" },
    { name: "applicant", label: "Applicant" },
    { name: "children", label: "Children" },
    { name: "respondent", label: "Respondent" },
    { name: "relationship", label: "Relationship" },
    { name: "incident", label: "Incident details" },
    { name: "other-orders", label: "Other orders" },
    { name: "conditions", label: "Conditions" },
    { name: "other", label: "Other" },
    { name: "information-sharing", label: "Information sharing" },
  ],
  flags: ["high_risk", "interim_sought"],
  summaryQuestions: [
    {
      id: "summary",
      frames: [
        {
          subHeading: "Applicant safe contact details",
          questions: [
            "appl_safe_cont",
            {
              name: "appl_cont_phone_3",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone_2",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone_vm",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_safe_times",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_email",
              conditions: {
                appl_safe_cont: "email",
              },
            },
            {
              name: "appl_cont_email_2",
              conditions: {
                appl_safe_cont: "email",
              },
            },
            {
              name: "appl_cont_trst_per",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_rel",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_rel_other",
              conditions: {
                appl_safe_cont: "trust",
                appl_cont_trst_per_rel: "other",
              },
            },
            {
              name: "appl_cont_trst_per_phone",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_email",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
          ],
        },
      ],
    },
  ],
  mainQuestions: [
    {
      id: "applicant",
      frames: [
        {
          heading: "Applicant",
          subHeading: "Applicant details",
          questions: [
            "appl_det_giv_nam",
            "appl_det_giv_nam2",
            "appl_det_giv_nam3",
            "appl_det_fam_nam",
            "appl_other_names",
            "appl_det_dob",
            "appl_det_sex",
            {
              name: "appl_det_sex_other",
              conditions: {
                appl_det_sex: "U",
              },
            },
          ],
        },
        {
          private: true,
          questions: ["appl_pregnant_baby"],
        },
        {
          questions: [
            "appl_abor_code",
            "appl_lgbt",
            "appl_disabl",
            { name: "appl_disabl_req", conditions: { appl_disabl: "yes" } },
            {
              name: "appl_disabl_req_other",
              conditions: {
                appl_disabl: "yes",
                appl_disabl_req: "Other",
              },
            },
            "appl_det_inter",
            {
              name: "appl_det_lang",
              conditions: { appl_det_inter: "yes" },
            },
            {
              name: "appl_det_lang_other",
              conditions: { appl_det_inter: "yes", appl_det_lang: "Other" },
            },
          ],
        },
        {
          private: true,
          questions: [
            {
              namePrefix: "appl_det_addr",
              label: "Current Address ",
            },
            "appl_det_addr_secret",
          ],
        },
      ],
    },
    {
      id: "children",
      frames: [
        {
          heading: "Children",
          questions: ["prp_witness", "prp_add_order"],
        },
        {
          children: true,
          conditions: {
            prp_add_order: "yes",
          },
          questions: [
            "prp_giv_nam",
            "prp_giv_nam2",
            "prp_giv_nam3",
            "prp_fam_nam",
            "prp_dob",
            "prp_sex1",
            {
              name: "prp_sex_other",
              conditions: { "children[x].prp_sex1": "U" },
            },
            "prp_abor_code",
            "prp_disabl",
            "prp_rel_to_resp",
            "prp_rel_to_appl",
            "prp_live",
            {
              namePrefix: "prp_addr",
              label: "Current Address",
              conditions: {
                "children[x].prp_live": "no",
              },
            },
            "prp_ord_exist",
            {
              name: "prp_ord_exist_det",
              conditions: { "children[x].prp_ord_exist": "yes" },
            },
            {
              text: "Child Protection order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              name: "prp_ord_cpo_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              name: "prp_ord_cpo_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              text: "Intervention Order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              name: "prp_ord_io_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              name: "prp_ord_io_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              text: "Other Order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            {
              name: "prp_ord_oo_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            {
              name: "prp_ord_oo_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            "prp_ord_fc",
            {
              name: "prp_flc_hear",
              conditions: {
                "children[x].prp_ord_fc": "yes",
              },
            },
            {
              name: "prp_flc_other_po",
              conditions: {
                "children[x].prp_ord_fc": "yes",
                "children[x].prp_flc_hear": "po",
              },
            },
            {
              name: "prp_flc_other",
              conditions: {
                "children[x].prp_ord_fc": "yes",
                "children[x].prp_flc_hear": "other",
              },
            },
          ],
        },
      ],
    },
    {
      id: "respondent",
      frames: [
        {
          heading: "Respondent",
          questions: [
            "resp_giv_nam",
            "resp_giv_nam2",
            "resp_giv_nam3",
            "resp_fam_nam",
            "resp_other_nam",
            "resp_dob_known",
            { name: "resp_dob", conditions: { resp_dob_known: "yes" } },
            { name: "resp_age", conditions: { resp_dob_known: "no" } },
            "resp_sex",
            { name: "resp_sex_other", conditions: { resp_sex: "U" } },
            "resp_abor_code",
            "resp_det_inter",
            { name: "resp_det_lang", conditions: { resp_det_inter: "yes" } },
            {
              name: "resp_det_inter",
              conditions: { resp_det_inter: "yes", resp_det_lang: "Other" },
            },
          ],
        },
        {
          id: "relationship",
          heading: "Relationship with respondent",
          questions: [
            "appl_rel_to_resp",
            "resp_known_lbl",
            "resp_sep",
            { name: "resp_sep_inf", conditions: { resp_sep: "no" } },
            { name: "resp_sep_long_lbl", conditions: { resp_sep: "yes" } },
          ],
        },
        {
          heading: "What is the best way to contact the respondent?",
          questions: [
            "resp_addr_known",
            "resp_contact_type",
            {
              name: "resp_contact_other",
              conditions: { resp_contact_type: "other" },
            },
            {
              name: "resp_contact_ph",
              conditions: { resp_contact_type: "phone" },
            },
            {
              name: "resp_contact_ph_2",
              conditions: { resp_contact_type: "phone" },
            },
            {
              name: "resp_contact_ph_3",
              conditions: { resp_contact_type: "phone" },
            },
            {
              name: "resp_contact_email",
              conditions: { resp_contact_type: "email" },
            },
            {
              name: "resp_contact_email_2",
              conditions: { resp_contact_type: "email" },
            },
            {
              namePrefix: "resp_det_addr",
              label: "Current Address",
              conditions: {
                resp_addr_known: "other",
              },
            },
            {
              name: "resp_addr_type",
              conditions: { resp_addr_known: "other" },
            },
            {
              name: "resp_addr_type_other",
              conditions: { resp_addr_type: "other" },
            },
          ],
        },
        {
          heading: "Other information",
          questions: [
            "resp_imm_pro",
            "resp_armed",
            { name: "resp_armed_detail", conditions: { resp_armed: "yes" } },
            { name: "resp_armed_loc", conditions: { resp_armed: "yes" } },
            {
              namePrefix: "resp_armed_loc_add",
              label: "Address",
              conditions: {
                resp_armed: "yes",
                resp_armed_loc: "other",
              },
            },
            "resp_addiction",
            "resp_suicidal",
          ],
        },
      ],
    },
    {
      id: "incident",
      frames: [
        {
          heading: "Incident details",
          subHeading: "Most recent incident",
          questions: [
            ...[
              "phy",
              "pys",
              "aep",
              "cym",
              "thy",
              "cbp",
              "fsw",
              "dyp",
              "ryf",
              "htc",
              "kha",
              "sty",
              "fmc",
              "cdw",
            ].reduce(
              (
                accumulator: QuestionConfigType[],
                key: string
              ): QuestionConfigType[] => {
                return [
                  ...accumulator,
                  {
                    name: `incident_${key}_recent`,
                    immutable: true,
                  },
                  {
                    name: `incident_${key}_detail`,
                    conditions: {
                      [`incident_${key}_recent`]: "yes",
                    },
                    immutable: true,
                  },
                ];
              },
              []
            ),
            { name: "incident_rec_date", immutable: true },
            { name: "incident_rec_desc", immutable: true },
            { name: "incident_rec_pc", immutable: true },
            {
              name: "incident_rec_pc_stn",
              conditions: { incident_rec_pc: "yes" },
              immutable: true,
            },
            {
              name: "incident_rec_pc_ofc",
              conditions: { incident_rec_pc: "yes" },
              immutable: true,
            },
          ],
        },
      ],
    },
    {
      id: "previous",
      frames: [
        {
          subHeading: "Previous incident details",
          questions: [
            {
              name: "incident_prev_fv_time",
              conditions: { incident_prev_fv: "yes" },
              immutable: true,
            },
            {
              name: "incident_prev_fv_det",
              conditions: { incident_prev_fv: "yes" },
              immutable: true,
            },
            {
              name: "incident_prev_freq",
              conditions: { incident_prev_fv: "yes" },
              immutable: true,
            },
            ...[
              "phy",
              "pys",
              "aep",
              "cym",
              "thy",
              "cbp",
              "fsw",
              "dyp",
              "ryf",
              "htc",
              "kha",
              "sty",
              "fmc",
              "cdw",
            ].reduce(
              (
                accumulator: QuestionConfigType[],
                key: string
              ): QuestionConfigType[] => {
                return [
                  ...accumulator,
                  {
                    name: `incident_${key}_prev`,
                    immutable: true,
                  },
                  {
                    name: `incident_${key}_detail`,
                    conditions: {
                      [`incident_${key}_prev`]: "yes",
                    },
                    immutable: true,
                  },
                ];
              },
              []
            ),
            { name: "incident_prev_pc", immutable: true },
            {
              name: "incident_prev_pc_stn",
              conditions: { incident_prev_pc: "yes" },
              immutable: true,
            },
            {
              name: "incident_prev_pc_ofc",
              conditions: { incident_prev_pc: "yes" },
              immutable: true,
            },
          ],
        },
      ],
    },
    {
      id: "other-orders",
      frames: [
        {
          heading: "Other orders",
          questions: [
            "pio_fio",
            { name: "pio_gio", conditions: { pio_fio: "no" } },
            { name: "pio_bio", conditions: { pio_fio: "no", pio_gio: "yes" } },
            { name: "pio_crt", conditions: { pio_fio: "no" } },
            {
              name: "pio_crt_oth",
              conditions: { pio_fio: "no", pio_crt: "other" },
            },
            { name: "pio_rec_time", conditions: { pio_fio: "no" } },
            { name: "pio_ref_nr", conditions: { pio_fio: "no" } },
            "pio_sub_io",
            {
              name: "pio_sub_io_given",
              conditions: { pio_sub_io: "yes" },
            },
            {
              name: "pio_crt_matters",
            },
            {
              name: "pio_crt_matters_given",
              conditions: { pio_crt_matters: "yes" },
            },
          ],
        },
      ],
    },
    {
      id: "conditions",
      frames: [
        {
          heading: "Conditions on the order",
          subHeading: "I want the respondent to be prevented from:",
          questions: [
            "coo_con_old_a",
            "coo_con_old_b",
            "coo_con_old_c",
            "coo_con_old_d",
            "coo_con_old_e",
            "coo_con_old_f",
            "coo_con_old_g",
            "coo_con_old_h",
          ],
        },
        {
          subHeading: "I would like exceptions included in this order:",
          questions: [
            "coo_exc_old_a",
            "coo_exc_old_b",
            "coo_exc_old_c",
            "coo_exc_old_d",
            "coo_exc_old_e",
          ],
        },
        {
          subHeading: "I want the Court to order:",
          questions: [
            "coo_con_old_i",
            "coo_con_old_j",
            "coo_con_old_k",
            "coo_con_old_l",
          ],
        },
        {
          subHeading: "I want the Court to encourage:",
          questions: ["coo_con_old_m"],
        },
        {
          subHeading: "Children’s arrangements:",
          questions: ["coo_con_old_n", "coo_con_old_o"],
        },
        {
          subHeading:
            "I believe that it may jeopardise my safety and/or the safety of my child/ren for my child/ren:",
          questions: [
            "coo_con_old_p",
            "coo_con_old_q",
            "coo_con_old_r",
            "coo_con_other",
          ],
        },
      ],
    },
    {
      id: "other",
      frames: [
        {
          heading: "Other information",
          questions: ["sub_info_oth"],
        },
      ],
    },
    {
      id: "information-sharing",
      frames: [
        {
          heading: "Information Sharing & Reminder",
          questions: [
            "appl_info_share",
            {
              name: "appl_info_share_limited",
              conditions: {
                appl_info_share: "limited",
              },
            },
            "appl_cont_sms_consent",
          ],
        },
      ],
    },
  ],
  riskSummary: [
    {
      text: "AFM is pregnant or has a new baby.",
      conditions: {
        appl_pregnant_baby: "yes",
      },
    },
    {
      text: "AFM wishes to keep current address confidential.",
      conditions: {
        appl_det_addr_secret: "yes",
      },
    },
    {
      text: "A child has seen, heard, been exposed to or been present at incidents of family violence.",
      conditions: {
        prp_witness: "yes",
      },
    },
    {
      text: "AFM has told the respondent about planning to separate.",
      conditions: {
        resp_sep_inf: "yes",
      },
    },
    {
      text: "AFM has been separated for less than 2 months.",
      conditions: {
        resp_sep: "yes",
        resp_sep_long_lbl: (values) => {
          if (
            typeof values.resp_sep_long_lbl === "string" &&
            values.resp_sep_long_lbl.length > 4
          ) {
            const [number, unit] = values.resp_sep_long_lbl.split(" ");

            if (["days", "weeks"].includes(unit.toLowerCase())) {
              return false;
            }

            if (unit.toLowerCase() === "years") {
              return false;
            }

            // must be months
            const numberParsed = parseInt(number, 10);
            return numberParsed < 2;
          }
          return false;
        },
      },
    },
    {
      text: "Respondent has access to guns/firearms/weapons.",
      conditions: {
        resp_armed: "yes",
      },
    },
    {
      text: "Respondent has AOD problem.",
      conditions: {
        resp_addiction: "yes",
      },
    },
    {
      text: "Respondent has attempted suicide, hurt or tried to hurt themselves on purpose.",
      conditions: {
        resp_suicidal: "yes",
      },
    },
    {
      text: "AFM has been pressured sexually.",
      conditions: {
        incident_pys_detail: (values) =>
          typeof values.incident_pys_detail === "string" &&
          values.incident_pys_detail.length > 5,
      },
    },
    {
      text: "AFM has feared for their safety or wellbeing or someone else's.",
      conditions: {
        incident_cdw_detail: (values) =>
          typeof values.incident_cdw_detail === "string" &&
          values.incident_cdw_detail.length > 5,
      },
    },
    {
      text: "A child has been harmed or threatened to be harmed.",
      conditions: {
        incident_htc_detail: (values) =>
          typeof values.incident_htc_detail === "string" &&
          values.incident_htc_detail.length > 5,
      },
    },
    {
      text: "AFM has been stalked.",
      conditions: {
        incident_sty_detail: (values) =>
          typeof values.incident_sty_detail === "string" &&
          values.incident_sty_detail.length > 5,
      },
    },
    {
      text: "Respondent committed other acts of family violence against the AFM.",
      conditions: {
        incident_prev_fv: "yes",
      },
    },
    {
      text: "Family violence has increased in frequency or intensity.",
      conditions: {
        incident_prev_freq: "yes",
      },
    },
    {
      text: "Alleged breach of Court Orders.",
      conditions: {
        pio_bio: "yes",
      },
    },
  ],
  declarationOfTruth: {
    applicantFirstNameFields: ["appl_det_giv_nam"],
    applicantFamilyNameFields: ["appl_det_fam_nam"],
    applicantDOBFields: ["appl_det_dob"],
    checkedField: "declaration_of_truth",
  },
};

export default fvioNewConfig;
