import { em, rem } from "polished";
import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { colors } from "../styles/variables";

const InjectStyle = createGlobalStyle`
  body, html {
    overflow: hidden;
  }
`;

type Props = {
  type: "confirm" | "alert";
  size?: "small" | "large" | "xl";
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
};

const Modal: React.FunctionComponent<Props> = ({
  size,
  type,
  children,
  handleClose,
}) => (
  <ModalContainer>
    <InjectStyle />
    <ModalOverlay />
    <ModalBodyContainer size={size || "large"} type={type || "confirm"}>
      <ModalClose onClick={handleClose} />
      <ModalBodyChildren>{children}</ModalBodyChildren>
    </ModalBodyContainer>
  </ModalContainer>
);

export default Modal;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1001;
  background-color: rgba(255, 255, 255, 0.7);
`;

type ModalBodyContainerProps = {
  size: "small" | "large" | "xl";
  type: "confirm" | "alert";
};

const ModalBodyContainer = styled.div<ModalBodyContainerProps>`
  background: ${({ type }) =>
    type === "alert" ? "rgb(248, 217, 221)" : "#D4E1ED"};
  width: ${({ size }) => (size === "small" ? "550" : size === "xl" ? "900" : "700")}px;
  border-radius: 6px;
  padding: 46px 56px 51px;
  position: relative;
  z-index: 1002;
`;

const ModalBodyChildren = styled.div`
  line-height: 1.22;
  text-align: center;
  footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
  }

  select {
    background-color: white;
    border: none;
  }

  h2 {
    font-size: ${rem(26)};
    margin: 0 0 ${em(50, 26)};
    line-height: ${em(36, 26)};
  }
`;

const ModalClose = styled.button`
  color: ${colors.black};
  height: 12px;
  width: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  transform: rotate(45deg);
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    color: ${colors.blue};
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -1px;
  }

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 2px;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
  }
`;
