import React from "react";
import styled, { css } from "styled-components/macro";

import { ReactComponent as IconCallBack } from "../assets/icons/icon-flag-call-back-sized.svg";
import { ReactComponent as IconHighRisk } from "../assets/icons/icon-flag-high-risk-sized.svg";
import { ReactComponent as IconInterim } from "../assets/icons/icon-flag-interim-sized.svg";
import { ReactComponent as IconReview } from "../assets/icons/icon-flag-review-sized.svg";
import { colors } from "../styles/variables";

type Props = {
  type: string;
};

export type FlagMeta = {
  background: string;
  color: string;
  text: String;
};

export type FlagTypeMap = {
  [key: string]: FlagMeta;
};

export const flagTypeMap: FlagTypeMap = {
  high_risk: {
    background: "#F8D9DD",
    color: colors.alertRed,
    text: "High risk",
  },
  interim_sought: {
    background: "#F8D9DD",
    color: colors.alertRed,
    text: "Interim",
  },
  call_back: {
    background: "#D4E1ED",
    color: colors.blue,
    text: "Call back",
  },
  review: {
    background: "#FFDAC4",
    color: "#CE4E00",
    text: "Review",
  },
};

type IconPropsType = {
  type: String;
};

export const Icon: React.FunctionComponent<IconPropsType> = ({ type }) => {
  switch (type) {
    case "high_risk":
      return <IconHighRisk />;
    case "call_back":
      return <IconCallBack />;
    case "review":
      return <IconReview />;
    case "interim_sought":
      return <IconInterim />;
    default:
      return null;
  }
};

const Flag: React.FunctionComponent<Props> = ({ type }) => {
  const flagMeta = flagTypeMap[type];

  if (flagMeta === undefined) {
    return null;
  }

  return (
    <FlagWrapper meta={flagMeta}>
      <Icon type={type} />
      {flagMeta.text}
    </FlagWrapper>
  );
};

export default Flag;

const FlagWrapper = styled.div<{ meta: FlagMeta }>`
  display: inline-block;
  padding: 0.21em 0.36em;
  font-size: 0.875rem;
  border-radius: 0.36em;
  margin-bottom: 0.36em;
  white-space: nowrap;
  ${({ meta }) => css`
    color: ${meta.color};
    background: ${meta.background};
  `}
  svg {
    display: inline-block;
    height: 1em;
    width: 1em;
    margin-right: 0.36em;
    fill: currentColor;
  }
`;
