import { ApplicationStateType } from "../context/application";

// applications submitted before 22nd apr 2021 4:30AM
const useIsLegacyApplication = (state: ApplicationStateType): boolean => {
  const editingDatetimeCutoff = new Date(Date.UTC(2020, 3, 21, 18, 30, 0, 0));

  const applicationSubmitted = new Date(state.applicationState!.Submitted);

  return applicationSubmitted < editingDatetimeCutoff;
};

export default useIsLegacyApplication;
