import { AnySchema } from "yup";
import {
  SchemaType,
  ApplicationJurisdictionType,
  ApplicationTypeType,
  ConditionsType,
} from "csv-package";

import fvioNewConfig from "./fvio";
import fvioEvrConfig from "./fvioevr";
import psioNewConfig from "./psio";
import supportNewConfig from "./support";

export type SideMenuType = {
  name: string;
  label: string;
  conditions?: ConditionsType;
};

export type OptionType = {
  value: string;
  text: string;
};

export type FlagsType = "high_risk" | "interim_sought";

export type QuestionConfigType =
  | string
  | {
    name: string;
    label?: string;
    conditions?: ConditionsType;
    immutable?: boolean;
    options?: OptionType[];
  }
  | {
    namePrefix: string;
    label: string;
    conditions?: ConditionsType;
    immutable?: boolean;
  }
  | {
    text: string;
    conditions?: ConditionsType;
    immutable?: boolean;
  };

export type QuestionFrameType = {
  children?: boolean;
  conditions?: ConditionsType;
  contentHeading?: string;
  heading?: string;
  id?: string;
  private?: boolean;
  questions: QuestionConfigType[];
  subHeading?: string;
  privateHeading?: string;
};

export type QuestionSectionType = {
  id?: string;
  frames: QuestionFrameType[];
};

export type RiskSummaryConfigType = {
  text: string;
  conditions: ConditionsType;
};

// name and dob fields are arrays. Will use the first value in the array that isn't "" or null
export type DeclarationOfTruthType = {
  applicantFirstNameFields: string[];
  applicantFamilyNameFields: string[];
  applicantDOBFields: string[];
  checkedField: string;
};

export type ApplicationConfigType = {
  validation: {
    [key: string]: AnySchema;
  };
  schema: SchemaType;
  sideMenu: SideMenuType[];
  flags: FlagsType[];
  summaryQuestions: QuestionSectionType[];
  mainQuestions: QuestionSectionType[];
  riskSummary?: RiskSummaryConfigType[];
  declarationOfTruth: DeclarationOfTruthType;
  applicationType?: string;
};

type ConfigType = {
  [K in ApplicationJurisdictionType]: {
    [K in ApplicationTypeType]: ApplicationConfigType;
  };
};

const config: ConfigType = {
  FVIO: {
    N: fvioNewConfig,
    E: fvioEvrConfig,
    V: fvioEvrConfig,
    R: fvioEvrConfig,
    EV: fvioEvrConfig,
    EVR: fvioEvrConfig,
  },
  PSIO: {
    N: psioNewConfig,
    E: fvioNewConfig,
    V: fvioNewConfig,
    R: fvioNewConfig,
    EV: fvioNewConfig,
    EVR: fvioNewConfig,
  },
  SUPPORT: {
    N: supportNewConfig
  }
};

export default config;
