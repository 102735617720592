import React from "react";
import styled, { css } from "styled-components/macro";

import { ReactComponent as PrivacyIcon } from "../assets/icons/Privacy.svg";

type Props = {
  privateFrame?: boolean;
  id?: string;
  privateHeading?: string;
};

const QuestionsFrame: React.FunctionComponent<Props> = ({
  children,
  privateFrame,
  id,
  privateHeading
}) => (
  <Wrapper privateFrame={privateFrame} id={id}>
    {privateFrame && (
      <PrivateHeader>
        <PrivacyIcon />
        <Text>{privateHeading ? privateHeading : "Private question - the Respondent will not see this answer."}</Text>
      </PrivateHeader>
    )}
    {children}
  </Wrapper>
);

export default QuestionsFrame;

const Wrapper = styled.fieldset<{ privateFrame?: boolean }>`
  width: 100%;
  max-width: 680px;
  padding: 0 15px 0;
  margin-bottom: 10px;
  border: none;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  ${({ privateFrame }) =>
    privateFrame &&
    css`
      border: 1px dashed rgba(27, 164, 178, 0.3);
      padding-top: 58px;
    `}
`;

const PrivateHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 48px;
  background-color: rgba(199, 239, 243, 0.35);
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  line-height: 1;
  > svg {
    height: 1.07em;
    width: 1.07em;
    margin-right: 1em;
  }
`;

const Text = styled.div`
  width: calc(100% - 2.07em);
`;
