import React from "react";
import styled from "styled-components/macro";
import localDatetimeString from "../helpers/localDatetimeString";
import { RevisionType } from "csv-package";

import { colors } from "../styles/variables";

type ApplicationRevisionLogProps = {
  logs: RevisionType[];
};

const ApplicationRevisionLog: React.FunctionComponent<ApplicationRevisionLogProps> = ({
  logs,
}) => {
  return (
    <ApplicationRevisionLogWrapper>
      <h3>Application history</h3>
      <table>
        <tbody>
          {logs.map((log) => (
            <tr key={log.Datetime}>
              <td>{localDatetimeString(log.Datetime)}</td>
              <td>{log.Text}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ApplicationRevisionLogWrapper>
  );
};

export default ApplicationRevisionLog;

const ApplicationRevisionLogWrapper = styled.section`
  table {
    width: 100%;
  }
  td {
    height: 3.125em;
    vertical-align: middle;
    border-bottom: 1px solid #d4e1ed;
  }

  td:first-child {
    padding-right: 3em;
    color: ${colors.grey};
  }

  td:last-child {
    color: ${colors.black};
  }
`;
