import { ApplicationStatusType } from "csv-package";

export type ApplicationStatusesType = {
  [key in ApplicationStatusType]: {
    name: string;
    disabled?: boolean;
  };
};

export const applicationStatues: ApplicationStatusType[] = [
  "archived",
  "submitted",
  "verified",
  "dnp",
  "re-declare-requested",
  "re-declare-accepted",
];

export const applicationStatusObjects: ApplicationStatusesType = {
  initiated: {
    name: "Initiated",
  },
  archived: {
    name: "Archived",
    disabled: true,
  },
  submitted: {
    name: "Submitted",
  },
  verified: {
    name: "Verified",
  },
  "verified-awaiting-leave": {
    name: "Verified awaiting leave",
  },
  dnp: {
    name: "Do not proceed",
  },
  "re-declare-accepted": {
    name: "Re-declaration submitted",
  },
  "re-declare-requested": {
    name: "Awaiting re-declaration",
  },
};
