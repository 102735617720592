export type TimeOption = {
  key: string;
  value: string;
};

const getTimeOptions = (): TimeOption[] => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date();
  let oldestRecordTimestamp = 1584144000000;
  const options: TimeOption[] = [];

  while (date.getTime() > oldestRecordTimestamp) {
    // adjust date var - 1 month
    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    options.push({
      key: `${year}-${month}`,
      value: `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
    });
  }

  return options;
};

export default getTimeOptions;
