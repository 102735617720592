import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { GlobalContext } from "../context/global";

type Props = RouteProps;

const ProtectedRoute: React.FunctionComponent<Props> = (props) => {
  const { globalState } = React.useContext(GlobalContext);

  // Auth check hasn't happen yet wait
  if (globalState.user === undefined) {
    return null;
  }

  // Auth check done and no assess
  if (globalState.user === null) {
    return <Redirect to="/login" />;
  }

  // Auth check done and user isn't a registrar
  if (
    !globalState.user.signInUserSession.idToken.payload[
      "cognito:groups"
    ].includes("Registrars")
  ) {
    return <h1>Your user is not authorised to access the registrar portal</h1>;
  }

  // 🎉
  return <Route {...props} />;
};

export default ProtectedRoute;
