import styled from "styled-components/macro";
import media from "styled-media-query";

const ContentContainer = styled.div`
  max-width: 1920px;
  min-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
  padding: 0 65px;

  ${media.lessThan("huge")`
    padding: 0 30px;
  `}

  @media print {
    width: 90%;
    min-width: auto;
  }
`;

export default ContentContainer;
