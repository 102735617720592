import React from "react";
import styled from "styled-components";
import { ApplicationContext } from "../context/application";
import Button from "./Button";

import Modal from "./Modal";

type Props = {};

const ModalUnsavedFields: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);

  return (
    <Modal
      type="alert"
      size="small"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      <h4>
        There are changes that have not been saved.
        <br />
        <br />
        Check that all changes are saved before sending a re-declaration or
        submitting to Courtlink.
      </h4>

      <p>
        <strong>Unsaved fields:</strong>
      </p>
      <Ul>
        {state.fieldsCurrentlyOpen.map((f) => {
          const machineName = f.split(".").length > 1 ? f.split(".")[1] : f;
          return (
            <li key={machineName}>
              {state.config?.schema.fields.find((sf) => sf.name === machineName)
                ?.label || machineName}
            </li>
          );
        })}
      </Ul>
      <footer>
        <Button
          alert
          onClick={() => {
            dispatch({
              type: "setActiveModal",
              modal: null,
            });
          }}
        >
          Dismiss
        </Button>
      </footer>
    </Modal>
  );
};

export default ModalUnsavedFields;

const Ul = styled.ul`
  padding-left: 0;
  li {
    list-style-type: none;
  }
`;
