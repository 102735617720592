import React from "react";

type Props = {
  height?: number;
};

const Spacer: React.FC<Props> = ({ height = 12 }) => (
  <div style={{ height: `${height}px` }} />
);

export default Spacer;
