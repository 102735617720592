import { ApplicationStateType } from "../context/application";

const applicationCanBeEdited = (state: ApplicationStateType): boolean => {
  // make sure there is a registrar assigned
  if (
    !state.applicationState ||
    typeof state.applicationState!.Registrar !== "string" ||
    state.applicationState!.Registrar.length === 0
  ) {
    return false;
  }
  // can not edit if user is viewing original
  if (state.applicationVersionInView === "original") {
    return false;
  }

  if (
    [
      "archived",
      "verified",
      "dnp",
      "re-declare-requested",
      "initiated",
    ].includes(state.applicationState!.Status)
  ) {
    return false;
  }
  return true;
};

export default applicationCanBeEdited;
