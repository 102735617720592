import React from "react";
import styled from "styled-components/macro";
import { colors } from "../styles/variables";
import Checkbox from "./Checkbox";

import { Icon, flagTypeMap, FlagMeta } from "./Flag";

type Props = {
  checked: boolean;
  type: string;
  handleOnChange: React.ChangeEventHandler<HTMLInputElement>;
};

const FlagEditBlock: React.FunctionComponent<Props> = ({
  checked,
  type,
  handleOnChange,
}) => {
  const flagMeta = flagTypeMap[type];

  if (flagMeta === undefined) {
    return null;
  }

  return (
    <FlagEditBlockWrapper meta={flagMeta}>
      <Checkbox
        checked={checked}
        name={`Flags.${type}`}
        value="true"
        handleOnChange={handleOnChange}
      >
        Mark as <Icon type={type} />
        <Underline>{flagTypeMap[type].text}</Underline>
      </Checkbox>
    </FlagEditBlockWrapper>
  );
};

export default FlagEditBlock;

const FlagEditBlockWrapper = styled.div<{ meta: FlagMeta }>`
  background: #f4f7fb;
  height: 3em;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  border-radius: 4px;
  width: calc(50% - 2px);
  margin-right: 4px;

  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  svg {
    height: 0.8em;
    width: 0.8em;
    fill: ${({ meta }) => meta && meta.color};
    margin: 0 0.2em;
  }
`;

const Underline = styled.p`
  display: inline-block;
  position: relative;
  white-space: nowrap;

  &:after {
    content: "";
    display: block;
    width: 100%;
    left: 0;
    top: calc(100% - 2px);
    border-bottom: 1px dashed ${colors.blue};
  }
`;
