import { ApplicationConfigType, QuestionConfigType } from ".";
import { hasAddAndRemoveChildren, hasOnlyAddChildren, hasOnlyRemoveChildren } from "../helpers/childHelpers";
import { schemaEVR as schema, fvioEvrValidation, courts, ApplicationDataType, ChildDataType } from "csv-package";

const protectedPersonRoles = ["appl_pp", "prp_18", "prp_14"];
export const unProtectedPersonRoles = ["appl", "pg", "pwc", "gpgo_o18", "resp"];

const validation = {
  ...fvioEvrValidation.exisitingOrder,
  ...fvioEvrValidation.yourRole,
  ...fvioEvrValidation.contactDetails,
  ...fvioEvrValidation.applicationType,
  ...fvioEvrValidation.relatedOrders,
  ...fvioEvrValidation.review
};

const fvioEvrConfig: ApplicationConfigType = {
  applicationType: "EVR",
  schema: schema,
  validation: validation,
  sideMenu: [
    { name: "summary", label: "Summary" },
    { name: "existing-order", label: "Existing order" },
    { name: "applicant-details", label: "Applicant" },
    { name: "protected-person", label: "Protected person" },
    { name: "respondent", label: "Respondent", conditions: { seeking: "appl" } },
    { name: "changes", label: "Changes" },
    { name: "other-orders", label: "Other orders" },
  ],
  flags: ["high_risk"],
  summaryQuestions: [
    {
      frames: [
        {
          id: "existing-order",
          heading: "Existing order",
          questions: [
            "eo_aprox_date",
            "eo_case_no",
            {
              name: "granted_court_id",
              options: courts.filter((court) => court.enabled)
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(court => ({ value: court.court_id, text: court.name }))
            },
            "eo_pc"
          ],
        },
        {
          heading: "Applicant contact details",
          conditions: { seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || unProtectedPersonRoles.includes(String(values.seeking_appl_role)) },
          questions: [
            { name: "appl_cont", conditions: { seeking: "appl" } },
            {
              name: "appl_cont", conditions: { seeking: "resp" }, options: [{
                "value": "phone",
                "text": "Phone number"
              },
              {
                "value": "email",
                "text": "Email"
              },
              {
                "value": "trust",
                "text": "Through a trusted person"
              },
              {
                "value": "unsafe",
                "text": "It is not safe to contact me, I will contact the court"
              }]
            },
            {
              name: "appl_phone",
              conditions: { appl_cont: "phone" },
            },
            {
              name: "appl_phone_2",
              conditions: { appl_cont: "phone" },
            },
            {
              name: "appl_phone_3",
              conditions: { appl_cont: "phone" },
            },
            {
              name: "appl_email",
              conditions: { appl_cont: "email" },
            },
            {
              name: "appl_email_2",
              conditions: { appl_cont: "email" },
            },
            {
              name: "appl_cont_trst_per",
              conditions: { appl_cont: "trust" },
            },
            {
              name: "appl_cont_trst_per_rel",
              conditions: { appl_cont: "trust"  },
            },
            {
              name: "appl_cont_trst_per_rel_other",
              conditions: {
                appl_safe_cont: "trust",
                appl_cont_trst_per_rel: "other",
              },
            },
            {
              name: "appl_cont_trst_per_phone",
              conditions: { appl_cont: "trust" },
            }, 
            {
              name: "appl_cont_trst_per_email",
              conditions: { appl_cont: "trust" },
            },
          ]
        },
        {
          heading: "Protected Person's safe contact details",
          conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles },
          questions: []
        },
        {
          heading: "Applicant safe contact details",
          conditions: { seeking: "appl", seeking_appl_role: protectedPersonRoles },
          questions: []
        },
        {
          questions: [
            {
              name: "appl_safe_cont", label: "What is the best way to contact the protected person?",
              conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles }, options: [
                {
                  "value": "phone",
                  "text": "Phone number"
                },
                {
                  "value": "email",
                  "text": "Email"
                },
                {
                  "value": "trust",
                  "text": "Through a trusted person"
                },
                {
                  "value": "unsafe",
                  "text": "It is not safe to contact the protected person, she/he will contact the court"
                }
              ]
            },
            { name: "appl_safe_cont", conditions: { seeking: "appl", seeking_appl_role: protectedPersonRoles } },
            {
              name: "appl_cont_phone",
              conditions: { appl_safe_cont: "phone" },
            },
            {
              name: "appl_cont_phone_2",
              conditions: { appl_safe_cont: "phone" },
            },
            {
              name: "appl_cont_phone_3",
              conditions: { appl_safe_cont: "phone" },
            },
            {
              name: "appl_cont_phone_vm",
              conditions: { appl_safe_cont: "phone", seeking: 'appl', seeking_appl_role: protectedPersonRoles },
            },
            {
              name: "appl_cont_phone_vm",
              label: "Is it safe to leave the protected person a voicemail message?",
              conditions: { appl_safe_cont: "phone", seeking: 'appl', seeking_appl_role: unProtectedPersonRoles },
            },
            {
              name: "appl_cont_safe_times",
              conditions: { appl_safe_cont: "phone", seeking: 'appl', seeking_appl_role: protectedPersonRoles },
            },
            {
              name: "appl_cont_safe_times",
              label: "When is it safest for us to ring the protected person?",
              conditions: { appl_safe_cont: "phone", seeking: 'appl', seeking_appl_role: unProtectedPersonRoles },
            },
            {
              name: "appl_cont_email",
              conditions: { appl_safe_cont: "email" },
            },
            {
              name: "appl_cont_email_2",
              conditions: { appl_safe_cont: "email" },
            },
            {
              name: "appl_cont_trst_per",
              conditions: { appl_safe_cont: "trust" },
            },
            {
              name: "appl_cont_trst_per_rel",
              conditions: { appl_safe_cont: "trust", seeking_appl_role: protectedPersonRoles },
            },
            {
              name: "appl_cont_trst_per_rel",
              label: "What is the protected person’s relationship with the trusted person?",
              conditions: { appl_safe_cont: "trust", seeking_appl_role: unProtectedPersonRoles },
            },
            {
              name: "appl_cont_trst_per_rel_other",
              conditions: {
                appl_safe_cont: "trust",
                appl_cont_trst_per_rel: "other",
              },
            },
            {
              name: "appl_cont_trst_per_phone",
              conditions: { appl_safe_cont: "trust" },
            },
            {
              name: "appl_cont_trst_per_email",
              conditions: { appl_safe_cont: "trust" },
            },
          ],
        },
      ],
    },
  ],
  mainQuestions: [
    {
      frames: [
        {
          id: "applicant-details",
          heading: "Applicant details",
          subHeading: "Who is seeking changes?",
          questions: [
            { name: "seeking", immutable: true },
            { name: "seeking_appl_role", conditions: { seeking: "appl" } },
            { name: "seeking_appl_sub_role", conditions: { seeking: "appl", seeking_appl_role: "pg" } },
          ],
        },
        {
          id: "protected-person",
          subHeading: "Protected Person",
          conditions: { seeking: "appl", seeking_appl_role: protectedPersonRoles },
          questions: []
        },
        {
          questions: [
            { name: "appl_det_giv_nam" },
            { name: "appl_det_giv_nam2" },
            { name: "appl_det_giv_nam3" },
            { name: "appl_det_fam_nam" },
            { name: "appl_det_other_nam" },
            { name: "appl_det_dob" },
            { name: "appl_sex" },
            {
              name: "appl_det_sex_other",
              conditions: {
                appl_sex: "U",
              },
            },
            { name: "appl_abor_code1", conditions: { seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || protectedPersonRoles.includes(String(values.seeking_appl_role)) } },
            { name: "appl_lgbt", conditions: { seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || protectedPersonRoles.includes(String(values.seeking_appl_role)) } },
            { name: "appl_disabl1",  conditions: { seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || protectedPersonRoles.includes(String(values.seeking_appl_role)) } },
            { name: "appl_disabl_req", conditions: { appl_disabl1: "yes" } },
            {
              name: "appl_disabl_req_other",
              conditions: {
                appl_disabl1: "yes",
                appl_disabl_req: "Other",
              },
            },
            "appl_inter",
            {
              name: "appl_lang",
              conditions: { appl_inter: "yes" },
            },
            {
              name: "appl_lang_other",
              conditions: { appl_inter: "yes", appl_lang: "Other" },
            },
          ]
        },
        {
          private: true,
          conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles },
          privateHeading: "Private question - respondent will not be advised of your address if you choose to keep your current address secret.",
          questions: [
            { namePrefix: "appl_det_addr", label: "Current Address" },
            "appl_det_addr_secret"
          ]
        },
        {
          private: true,
          conditions: { seeking: "resp" },
          privateHeading: "Private question - as the respondent, a court registrar will advise if your address is able to be kept secret if you have chosen this option.",
          questions: [
            { namePrefix: "appl_det_addr", label: "Current Address" },
            "appl_det_addr_secret"
          ]
        },
        {
          private: true,
          privateHeading: "Private question - the other parties on the intervention order will not see these answers.",
          conditions: { seeking: "resp" },
          questions: [
            { name: "appl_pregnant_baby" },
          ],
        },
        {
          id: "protected-person",
          subHeading: "Protected Person",
          conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles },
          questions: []
        },
        {
          id: "protected-person",
          subHeading: "Protected Person",
          conditions: { seeking: "resp" },
          questions: []
        },
        {
          questions: [
            { name: "prp_det_giv_nam", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_det_giv_nam", conditions: { seeking: "resp" } },
            { name: "prp_det_giv_nam2", conditions: { seeking: "resp" } },
            { name: "prp_det_giv_nam3", conditions: { seeking: "resp" } },
            { name: "prp_det_fam_nam", conditions: { seeking: "resp" } },
            { name: "prp_det_dob", conditions: { seeking: "resp" } },
            { name: "prp_det_giv_nam2", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_det_giv_nam3", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_det_fam_nam", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_other_names", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_det_dob", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_sex", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            {
              name: "prp_sex_other",
              conditions: {
                prp_sex: "U",
              },
            },
            { name: "prp_abor_code1", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_lgbt", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_disabl1", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "prp_disabl_req", conditions: { prp_disabl1: "yes" } },
            {
              name: "prp_disabl_req_other",
              conditions: {
                prp_disabl1: "yes",
                prp_disabl_req: "Other",
              },
            },
            { name: "prp_inter", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            {
              name: "prp_lang",
              conditions: { prp_inter: "yes" },
            },
            {
              name: "prp_lang_other",
              conditions: { prp_inter: "yes", prp_lang: "Other" },
            },
          ],
        },
        {
          private: true,
          conditions: { seeking: "appl" },
          privateHeading: "Private question - respondent will not be advised of your address if you choose to keep your current address secret.",
          questions: [
            {
              namePrefix: "prp_addr",
              label: "What is your current address?",
            },
            "prp_addr_secret",
          ],
        },
        {
          private: true,
          conditions: { seeking:"appl" },
          questions: [
            { name: "prp_pregnant_baby", conditions: { seeking: "appl", seeking_appl_role: unProtectedPersonRoles } },
            { name: "appl_pregnant_baby", conditions: { seeking: ["appl", "resp"], seeking_appl_role: (values: ApplicationDataType | ChildDataType) => values.seeking === "resp" || protectedPersonRoles.includes(String(values.seeking_appl_role)) } },
          ],
        },
      ],
    },
    {
      frames: [
        {
          id: "respondent",
          subHeading: "Respondent",
          conditions: { seeking: "appl" },
          questions: [
            "resp_det_giv_nam",
            "resp_det_giv_nam2",
            "resp_det_giv_nam3",
            "resp_det_fam_nam",
            "resp_det_dob",
            "resp_addr_known",
            {
              name: "resp_addr_known_pc",
              conditions: { resp_addr_known: "unknown" },
            },
            {
              name: "resp_addr_known_pc_stn",
              conditions: {
                resp_addr_known: "unknown",
                resp_addr_known_pc: "yes",
              },
            },
            {
              name: "resp_addr_known_pc_ofc",
              conditions: {
                resp_addr_known: "unknown",
                resp_addr_known_pc: "yes",
              },
            },
            {
              namePrefix: "resp_det_addr",
              conditions: {
                resp_addr_known: "other",
              },
              label: "What is the respondent’s current address?",
            },
          ],
        },
      ],
    },
    {
      id: "changes",
      frames: [
        {
          heading: "Changes",
          questions: ["app_types"],
        },
        {
          questions: [
            { text: "Extend", conditions: { app_types: "AE" } },
            { name: "app_type_extend_why", conditions: { app_types: "AE" } },
            { name: "app_type_extend_why_still_fearful", conditions: { app_types: "AE", app_type_extend_why: "still_fearful" } },
            { text: "During the existing intervention order, has the respondent...", conditions: { app_types: "AE", app_type_extend_why: "further_violence" } },

            ...[
              "phy",
              "pys",
              "aep",
              "cym",
              "thy",
              "cbp",
              "fsw",
              "dyp",
              "ryf",
              "htc",
              "kha",
              "sty",
              "fmc",
              "cdw",
            ].reduce(
              (
                accumulator: QuestionConfigType[],
                key: string
              ): QuestionConfigType[] => {
                return [
                  ...accumulator,
                  {
                    name: `incident_${key}_detail`,
                    conditions: {
                      app_types: "AE",
                      app_type_extend_why: "further_violence",
                    },
                  },
                  {
                    name: `incident_${key}_date`,
                    label: "Date or approximate date of when it happened",
                    conditions: {
                      app_types: "AE",
                      app_type_extend_why: "further_violence",
                    },
                  },
                ];
              },
              []
            ),
            { name: "app_type_extend_why_breached", conditions: { app_types: "AE", app_type_extend_why: "breached" } },
            { name: "app_type_extend_why_violence_stopped", conditions: { app_types: "AE", app_type_extend_why: "violence_stopped" } },
            { name: "app_type_extend_add", conditions: { app_types: "AE" } },
          ],
        },
        {
          heading: "Vary",
          subHeading: "I want the respondent to be prevented from:",
          conditions: { app_types: ["AV"] },
          questions: []
        },
        {
          heading: "Vary",
          subHeading: "As the respondent, I am seeking changes to be prevented from:",
          conditions: { app_types: ["RV"] },
          questions: []
        },
        {
          conditions: { app_types: ["AV", "RV"] },
          questions: [
            "coo_con_old_a",
            { name: "coo_con_old_a_detail", conditions: { coo_con_old_a: 'yes' } },
            "coo_con_old_b",
            { name: "coo_con_old_b_detail", conditions: { coo_con_old_b: 'yes' } },
            "coo_con_old_c",
            { name: "coo_con_old_c_detail", conditions: { coo_con_old_c: 'yes' } },
            "coo_con_old_d",
            { name: "coo_con_old_d_detail", conditions: { coo_con_old_d: 'yes' } },
            "coo_con_old_e",
            { name: "coo_con_old_e_detail", conditions: { coo_con_old_e: 'yes' } },
            "coo_con_old_f",
            { name: "coo_con_old_f_detail", conditions: { coo_con_old_f: 'yes' } },
            "coo_con_old_g",
            { name: "coo_con_old_g_detail", conditions: { coo_con_old_g: 'yes' } },
            "coo_con_old_h",
            { name: "coo_con_old_h_detail", conditions: { coo_con_old_h: 'yes' } },
          ],
        },
        {
          conditions: { app_types: ["AV"] },
          subHeading: "I would like exceptions included in this order:",
          questions: []
        },
        {
          conditions: { app_types: ["RV"] },
          subHeading: "I would like exceptions included in this order. As the respondent I may:",
          questions: []
        },
        {
          conditions: { app_types: ["AV", "RV"] },
          questions: [
            { name: "coo_exc_old_a" },
            { name: "coo_exc_old_a_detail", conditions: { coo_exc_old_a: 'yes' } },
            { name: "coo_exc_old_b" },
            { name: "coo_exc_old_b_detail", conditions: { coo_exc_old_b: 'yes' } },
            { name: "coo_exc_old_c" },
            { name: "coo_exc_old_c_detail", conditions: { coo_exc_old_c: 'yes' } },
            { name: "coo_exc_old_d" },
            { name: "coo_exc_old_d_detail", conditions: { coo_exc_old_d: 'yes' } },
            { name: "coo_exc_old_e" },
            { name: "coo_exc_old_e_detail", conditions: { coo_exc_old_e: 'yes' } },
          ],
        },
        {
          conditions: { app_types: ["AV"] },
          subHeading: "I want the Court to order:",
          questions: []
        },
        {
          conditions: { app_types: ["RV"] },
          subHeading: "As the respondent, I am seeking changes to:",
          questions: []
        },
        {
          conditions: { app_types: ["AV", "RV"] },
          questions: [
            { name: "coo_con_old_i", conditions: { app_types: "AV" } },
            { name: "coo_con_old_i", conditions: { app_types: "RV" }, label: "The respondent must arrange to return personal property belonging to the protected person(s) within two days of the intervention order being served." },
            { name: "coo_con_old_i_detail", conditions: { coo_con_old_i: 'yes' } },
            { name: "coo_con_old_j", conditions: { app_types: "AV" } },
            { name: "coo_con_old_j", conditions: { app_types: "RV" }, label: "The respondent must arrange to return jointly owned property within two days of the intervention order being served." },
            { name: "coo_con_old_j_detail", conditions: { coo_con_old_j: 'yes' } },
            "coo_con_old_k",
            { name: "coo_con_old_k_detail", conditions: { coo_con_old_k: 'yes' } },
            "coo_con_old_l",
            { name: "coo_con_old_l_detail", conditions: { coo_con_old_l: 'yes' } },
          ],
        },
        {
          conditions: { app_types: ["AV"] },
          subHeading: "I want the Court to encourage:",
          questions: []
        },
        {
          conditions: { app_types: ["AV", "RV"] },
          questions: [
            "coo_con_old_m",
            { name: "coo_con_old_m_detail", conditions: { coo_con_old_m: 'yes' } },
          ],
        },
        {
          conditions: { app_types: ["AV", "RV"] },
          subHeading: "Children’s arrangements:",
          questions: []
        },
        {
          conditions: { app_types: ["AV"] },
          questions: [
            "coo_con_old_n",
            { name: "coo_con_old_n_detail", conditions: { coo_con_old_n: 'yes' } },
            "coo_con_old_o",
            { name: "coo_con_old_o_detail", conditions: { coo_con_old_o: 'yes' } },
          ],
        },
        {
          conditions: { app_types: ["AV"] },
          subHeading:
            "I believe that it may jeopardise my safety and/or the safety of my child/ren for my child/ren:",
          questions: [
            "coo_con_old_p",
            { name: "coo_con_old_p_detail", conditions: { coo_con_old_p: 'yes' } },
            "coo_con_old_q",
            { name: "coo_con_old_q_detail", conditions: { coo_con_old_q: 'yes' } },
            "coo_con_old_r",
            { name: "coo_con_old_r_detail", conditions: { coo_con_old_r: 'yes' } },
            "coo_con_other",
          ],
        },
        {
          conditions: { app_types: ["RV"] },
          questions: [
            { name: "vary_resp_children_arr", },
            { name: "vary_resp_other" }
          ],
        },
        {
          conditions: { app_types: "AV" },
          questions: ["vary_appl_why"]
        },
        {
          conditions: { app_types: "RV" },
          questions: [
            "vary_resp_why",
            "vary_resp_why_2"
          ]
        },
        {
          questions: [
            {
              text: "Add or remove a child/children",
              conditions: { app_types: "AC" },
            },
            { name: "child_action_add_why", conditions: { app_types: "AC", children: hasOnlyAddChildren } },
            { name: "child_action_remove_why", conditions: { app_types: "AC", children: hasOnlyRemoveChildren } },
            { name: "child_action_remove_why", conditions: { app_types: "RC" } },
            { name: "child_action_add_remove_why", conditions: { app_types: "AC", children: hasAddAndRemoveChildren } },
            {
              text: "Remove a child/children from the order",
              conditions: { app_types: "RC" },
            },
          ],
        },
        {
          id: "children",
          subHeading: "",
          children: true,
          questions: [
            {
              name: "child_action",
              conditions: {
                "seeking": "appl",
              },
            },
            "prp_giv_nam",
            "prp_giv_nam2",
            "prp_giv_nam3",
            "prp_fam_nam",
            "prp_dob",
            "prp_sex1",
            "prp_sex_other",
            "prp_abor_code",
            "prp_disabl",
            "prp_rel_to_appl",
            {
              name: "prp_rel_to_resp",
              label: "Relationship of child to respondent?",
              conditions: { "seeking": "appl" },
            },
            {
              name: "prp_rel_to_resp",
              label: "Relationship of child to the adult protected person?",
              conditions: { "seeking": "resp" },
            },
            "prp_live",
            {
              namePrefix: "prp_addr",
              label: "Current Address",
              conditions: {
                "children[x].prp_live": "no",
              },
            },
            "prp_ord_exist",
            {
              name: "prp_ord_exist_det",
              conditions: { "children[x].prp_ord_exist": "yes" },
            },
            {
              text: "Child Protection order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              name: "prp_ord_cpo_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              name: "prp_ord_cpo_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "cpo",
              },
            },
            {
              text: "Intervention Order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              name: "prp_ord_io_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              name: "prp_ord_io_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "io",
              },
            },
            {
              text: "Other Order",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            {
              name: "prp_ord_oo_dlo",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            {
              name: "prp_ord_oo_ncd",
              conditions: {
                "children[x].prp_ord_exist": "yes",
                "children[x].prp_ord_exist_det": "oo",
              },
            },
            "prp_ord_fc",
            {
              name: "prp_flc_hear",
              conditions: {
                "children[x].prp_ord_fc": "yes",
              },
            },
            {
              name: "prp_flc_other_po",
              conditions: {
                "children[x].prp_ord_fc": "yes",
                "children[x].prp_flc_hear": "po",
              },
            },
            {
              name: "prp_flc_other",
              conditions: {
                "children[x].prp_ord_fc": "yes",
                "children[x].prp_flc_hear": "other",
              },
            },
          ],
        },
        {
          conditions: { app_types: "AR" },
          questions: ["revoke_appl_why"]
        },
        {
          conditions: { app_types: "RR" },
          questions: ["revoke_resp_why", "revoke_resp_why_2"]
        },
      ],
    },
    {
      id: "other-orders",
      frames: [
        {
          heading: "Other orders",
          questions: [
            "order_exist_cm",
            {
              name: "order_exist_cm_yes",
              conditions: {
                order_exist_cm: "yes",
              },
            },
            "order_exist_changed_other_terr",
            "order_exist_made_other_terr",
            "order_exist_curr_app_chng_other_terr",
          ],
        },
      ],
    },
    {
      id: "other",
      frames: [
        {
          heading: "Other information",
          questions: ["sub_info_oth"],
        },
      ],
    },
    {
      id: "information-sharing",
      frames: [
        {
          heading: "Information Sharing",
          conditions: { seeking: "appl", seeking_appl_role: protectedPersonRoles },
          questions: [
            "appl_info_share",
            {
              name: "appl_info_share_limited",
              conditions: {
                seeking: "appl",
                seeking_appl_role: protectedPersonRoles,
                appl_info_share: "limited",
              },
            },
          ],
        },
      ],
    },
    {
      id: "other",
      frames: [
        {
          heading: "SMS reminder consent",
          conditions: { seeking: "appl", app_types: ["AE", "AV", "AC"] },
          questions: ["appl_cont_sms_consent",
            {
              name: "appl_reminder_cont_phone",
              conditions: {
                appl_cont_sms_consent: "yes",
              },
            }],
        },
      ],
    },
  ],
  riskSummary: [
    {
      text: "AFM is pregnant or has a new baby.",
      conditions: {
        appl_pregnant_baby: "yes",
      },
    },
    {
      text: "AFM is pregnant or has a new baby.",
      conditions: {
        prp_pregnant_baby: "yes",
      },
    },
    {
      text: "AFM wishes to keep current address confidential.",
      conditions: {
        prp_addr_secret: "yes",
      },
    },
    {
      text: "Applicant wishes to keep current address confidential.",
      conditions: {
        appl_det_addr_secret: "yes",
      },
    },
    {
      text: "AFM has been pressured sexually.",
      conditions: {
        incident_pys_detail: (values) =>
          typeof values.incident_pys_detail === "string" &&
          values.incident_pys_detail.length > 5,
      },
    },
    {
      text: "AFM fears for the safety or well-being of AFM or someone else.",
      conditions: {
        incident_fsw_detail: (values) =>
          typeof values.incident_fsw_detail === "string" &&
          values.incident_fsw_detail.length > 5,
      },
    },
    {
      text: "A child has been harmed or threatened to be harmed.",
      conditions: {
        incident_htc_detail: (values) =>
          typeof values.incident_htc_detail === "string" &&
          values.incident_htc_detail.length > 5,
      },
    },
    {
      text: "AFM has been stalked.",
      conditions: {
        incident_sty_detail: (values) =>
          typeof values.incident_sty_detail === "string" &&
          values.incident_sty_detail.length > 5,
      },
    },
  ],
  declarationOfTruth: {
    applicantFirstNameFields: ["appl_det_giv_nam"],
    applicantFamilyNameFields: ["appl_det_fam_nam"],
    applicantDOBFields: ["appl_det_dob"],
    checkedField: "declaration_of_truth",
  }
};

export default fvioEvrConfig;