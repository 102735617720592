import API from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import React from "react";
import { useParams } from "react-router";
import ReactLoading from "react-loading";

import { ApplicationContext } from "../context/application";
import { TParams } from "../pages/Application";
import Button from "./Button";
import Modal from "./Modal";

type Props = {};

const ModalUndoEdit: React.FunctionComponent<Props> = () => {
  const { applicationID } = useParams<TParams>();
  const [saving, setSaving] = React.useState<boolean>(false);
  const applicationContext = React.useContext(ApplicationContext);
  const dispatch = applicationContext[1];

  const handleConfirm = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setSaving(true);

    // do api call here

    // set API
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        ApplicationID: applicationID,
      },
    };

    // execute API call
    await API.post(
      "csvApi",
      "/private/registrar/resetApplicationWorkingState",
      myInit
    );

    dispatch({
      type: "undoWorkingEdits",
    });
    dispatch({ type: "setActiveModal", modal: null });
    setSaving(false);
  };

  return (
    <Modal
      type="confirm"
      size="small"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      <p>Are you sure you want to undo all current edits?</p>

      <footer>
        {saving ? (
          <ReactLoading type="bubbles" />
        ) : (
          <>
            <Button
              outline
              onClick={() => dispatch({ type: "setActiveModal", modal: null })}
            >
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm undo</Button>
          </>
        )}
      </footer>
    </Modal>
  );
};

export default ModalUndoEdit;
