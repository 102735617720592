import { ApplicationConfigType } from ".";
import psioSchema from "../data/schema-psio.json";

const fvioNewConfig: ApplicationConfigType = {
  schema: psioSchema,
  validation: {},
  sideMenu: [
    { name: "summary", label: "Summary" },
    { name: "personalDetails", label: "Person details" },
    { name: "incidentHistory", label: "Incident histroy" },
    { name: "children", label: "Children" },
    { name: "respondent", label: "Respondent" },
    { name: "conditions", label: "Conditions" },
    { name: "other", label: "Other" },
  ],
  flags: [],
  summaryQuestions: [
    {
      id: "summary",
      frames: [
        {
          subHeading: "Applicant role",
          questions: [
            "appl_type",
            {
              name: "abap_type",
              conditions: {
                appl_type: "abap",
              },
            },
          ],
        },
        {
          conditions: {
            appl_type: "abap",
          },
          subHeading: "Applicant safe contact details",
          questions: [
            "appl_safe_cont",
            {
              name: "appl_cont_phone_3",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone_2",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_phone_vm",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_safe_times",
              conditions: {
                appl_safe_cont: "phone",
              },
            },
            {
              name: "appl_cont_email",
              conditions: {
                appl_safe_cont: "email",
              },
            },
            {
              name: "appl_cont_email_2",
              conditions: {
                appl_safe_cont: "email",
              },
            },
            {
              name: "appl_cont_trst_per",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_rel",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_rel_other",
              conditions: {
                appl_safe_cont: "trust",
                appl_cont_trst_per_rel: "other",
              },
            },
            {
              name: "appl_cont_trst_per_phone",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
            {
              name: "appl_cont_trst_per_email",
              conditions: {
                appl_safe_cont: "trust",
              },
            },
          ],
        },
        {
          conditions: {
            appl_type: "ap",
          },
          subHeading: "Affected person safe contact details",
          questions: [
            "ap_safe_cont",
            {
              name: "ap_cont_phone_3",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone_2",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone_vm",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_safe_times",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_email",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              name: "ap_cont_email_2",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              name: "ap_cont_trst_per",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_rel",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_rel_other",
              conditions: {
                ap_safe_cont: "trust",
                ap_cont_trst_per_rel: "other",
              },
            },
            {
              name: "ap_cont_trst_per_phone",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_email",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
          ],
        },
      ],
    },
  ],
  mainQuestions: [
    {
      id: "personalDetails",
      frames: [
        {
          conditions: {
            appl_type: "abap",
          },
          heading: "Applicant",
          subHeading: "Applicant details",
          questions: [
            "appl_det_giv_nam",
            "appl_det_giv_nam2",
            "appl_det_giv_nam3",
            "appl_det_fam_nam",
            "appl_other_names",
            "appl_rel_ap",
            "appl_dob",
            "appl_age",
            "appl_sex",
            {
              name: "appl_det_sex_other",
              conditions: {
                appl_det_sex: "U",
              },
            },
            "appl_abor_code",
          ],
        },
        {
          conditions: {
            appl_type: "abap",
          },
          private: true,
          questions: [
            {
              namePrefix: "appl_det_addr",
              label: "What is your current address?",
            },
          ],
        },
      ],
    },
    {
      frames: [
        {
          heading: "Affected person",
          questions: [
            "ap_giv_nam",
            "ap_giv_nam2",
            "ap_giv_nam3",
            "ap_fam_nam",
            "ap_other_names",
            "ap_rel_resp",
            "ap_dob",
            "ap_age",
            "ap_sex",
            {
              name: "ap_sex_other",
              conditions: {
                ap_sex: "U",
              },
            },
            "ap_lgbt",
            "ap_abor_code",
          ],
        },
        {
          private: true,
          questions: ["ap_pregnant_baby"],
        },
        {
          questions: [
            "ap_inter",
            {
              name: "ap_lang",
              conditions: {
                ap_inter: "yes",
              },
            },
            {
              name: "ap_lang_other",
              conditions: {
                ap_lang: "Other",
              },
            },
          ],
        },
        {
          subHeading: "Contact details",
          questions: [
            {
              conditions: {
                appl_type: "abap",
              },
              namePrefix: "ap_addr",
              label: "What is your current address?",
            },
            "ap_safe_cont",
            {
              name: "ap_cont_phone_3",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone_2",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone_sms",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_phone_vm",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_safe_times",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "ap_cont_email",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              name: "ap_cont_email_2",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              name: "ap_cont_trst_per",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_rel",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_rel_other",
              conditions: {
                ap_safe_cont: "trust",
                ap_cont_trst_per_rel: "other",
              },
            },
            {
              name: "ap_cont_trst_per_email",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
            {
              name: "ap_cont_trst_per_phone",
              conditions: {
                ap_safe_cont: "trust",
              },
            },
          ],
        },
      ],
    },
    {
      id: "incidentHistory",
      frames: [
        {
          heading: "Incident History",
          questions: ["incident_date", "violence_duration"],
        },
        {
          subHeading: "Details of incidents",
          questions: [
            "incident_types",
            ...["assult", "threat", "damage", "stalked", "other"].reduce(
              (a: string[], c) => {
                return [
                  ...a,
                  `incident_${c}`,
                  `incident_${c}_recent`,
                  `incident_${c}_previous`,
                ];
              },
              []
            ),
            "incident_other",
          ],
        },
        {
          subHeading: "Additional information",
          questions: [
            "child_affected",
            {
              name: "child_affected_detail",
              conditions: {
                child_affected: "yes",
              },
            },
            "resp_charged",
          ],
        },
        {
          private: true,
          questions: [
            "police_contacted",
            {
              name: "police_station",
              conditions: {
                police_contacted: "yes",
              },
            },
            {
              name: "police_officer",
              conditions: {
                police_contacted: "yes",
              },
            },
          ],
        },
      ],
    },
    {
      id: "children",
      frames: [
        {
          heading: "Children ",
          questions: ["ap_children", "ap_children_exposed"],
        },
        {
          children: true,
          conditions: {
            ap_children_exposed: "yes",
          },
          questions: [
            "prp_giv_nam",
            "prp_giv_nam2",
            "prp_giv_nam3",
            "prp_fam_nam",
            "prp_other_nam",
            "prp_rel_to_resp",
            "prp_dob",
            "prp_age",
            "prp_sex",
            {
              name: "prp_sex_other",
              conditions: {
                [`children[x].prp_sex`]: "U",
              },
            },
            "prp_lgbt",
            "prp_abor_code",
            "prp_disabl",
            "prp_live",
            {
              namePrefix: "prp_addr",
              label: "What address is the child currently living at?",
              conditions: {
                [`children[x].prp_live`]: "no",
              },
            },
          ],
        },
      ],
    },
    {
      id: "respondent",
      frames: [
        {
          heading: "Respondent",
          questions: [
            "resp_giv_nam",
            "resp_giv_nam2",
            "resp_giv_nam3",
            "resp_other_nam",
            {
              namePrefix: "resp_addr",
              label: "What is the respondent’s last known address?",
            },
            "resp_known_lbl",
            "resp_sex",
            {
              name: "resp_sex_other",
              conditions: {
                resp_sex: "U",
              },
            },
            "resp_inter",
            {
              name: "resp_lang",
              conditions: {
                resp_inter: "yes",
              },
            },
            {
              name: "resp_lang_other",
              conditions: {
                resp_inter: "yes",
                resp_lang: "Other",
              },
            },
          ],
        },
        {
          subHeading: "Respondent contact details",
          questions: [
            "resp_contact_type",
            {
              name: "resp_contact_phone_3",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_phone",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_phone_2",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_phone_sms",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_phone_vm",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_safe_times",
              conditions: {
                ap_safe_cont: "phone",
              },
            },
            {
              name: "resp_contact_email",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              name: "resp_contact_email_2",
              conditions: {
                ap_safe_cont: "email",
              },
            },
            {
              namePrefix: "resp_contact_post",
              label: "Postal address",
              conditions: {
                ap_safe_cont: "post",
              },
            },
            {
              name: "resp_contact_other",
              conditions: {
                ap_safe_cont: "other",
              },
            },
          ],
        },
        {
          subHeading: "Other information",
          questions: [
            "resp_addiction",
            "resp_armed",
            {
              name: "resp_armed_detail",
              conditions: {
                resp_armed: "yes",
              },
            },
            {
              name: "resp_armed_detail",
              conditions: {
                resp_armed: "yes",
              },
            },
            {
              name: "resp_armed_loc",
              conditions: {
                resp_armed: "yes",
              },
            },
            {
              namePrefix: "resp_armed_loc_add",
              label: "Please specify",
              conditions: {
                resp_armed_loc: "other",
              },
            },
            "resp_weapon",
            {
              name: "resp_weapon_detail",
              conditions: {
                resp_weapon: "yes",
              },
            },
          ],
        },
      ],
    },
    {
      id: "conditions",
      frames: [
        {
          heading: "Conditions",
          questions: [
            "cond_prevent",
            "cond_exempt",
            "cond_firearm_weapon",
            "cond_interim_sought",
            "cond_other",
          ],
        },
      ],
    },
    {
      id: "other",
      frames: [
        {
          heading: "Other",
          questions: ["narrative_notes", "appl_cont_sms_consent"],
        },
      ],
    },
  ],
  declarationOfTruth: {
    applicantFirstNameFields: ["appl_det_giv_nam", "ap_giv_name"],
    applicantFamilyNameFields: ["appl_det_fam_nam", "ap_fam_name"],
    applicantDOBFields: ["appl_det_dob", "ap_dob"],
    checkedField: "declaration_of_truth",
  },
};

export default fvioNewConfig;
