import React from "react";
import { useParams } from "react-router";
import styled from "styled-components/macro";
import _ from "lodash";

import { ApplicationContext } from "../context/application";
import Button from "./Button";
import useHasCallbackFlag from "../hooks/useHasCallbackFlag";
import useCanBeSubmitted from "../hooks/useCanBeSubmitted";
import useReDeclareRequired from "../hooks/useReDeclareRequired";
import useReDeclareCanBeCanceled from "../hooks/useReDeclareCanBeCanceled";
import optomisticUpdateFlag from "../helpers/optomisticUpdateFlag";
import { TParams } from "../pages/Application";
import useApplicationIsDirty from "../hooks/useApplicationIsDirty";
import { colors } from "../styles/variables";
import useValidateWorkingState from "../hooks/useValidateWorkingState";
import useIsLegacyApplication from "../hooks/useIsLegacyApplication";
import useCanEvrBeSubmitted from "../hooks/useCanEvrBeSubmitted";

type Props = {};

const ApplicationActions: React.FunctionComponent<Props> = () => {
  useValidateWorkingState();
  const [state, dispatch] = React.useContext(ApplicationContext);
  const { applicationID } = useParams<TParams>();

  const hasCallbackFlag = useHasCallbackFlag(state);
  const canBeSubmitted = useCanBeSubmitted(state);
  const canEvrBeSubmitted = useCanEvrBeSubmitted(state);
  const applicationIsDirty = useApplicationIsDirty(state);
  const reDeclareRequired = useReDeclareRequired(state);
  const reDeclareCanBeCanceled = useReDeclareCanBeCanceled(state);
  const isLegacyApplication = useIsLegacyApplication(state);
  const isEvr = state?.config?.applicationType === "EVR";

  // don't render is viewing original
  if (state.applicationVersionInView === "original") {
    return null;
  }

  // show small buttons if all 4 buttons in EVR is visible
  const showSmallButtons = isEvr && canEvrBeSubmitted && !applicationIsDirty && reDeclareRequired && !isLegacyApplication && canBeSubmitted;

  return (
    <>
      {!_.isEmpty(state.errors) && (
        <Errors>
          <h3>Errors:</h3>
          <ul>
            {Object.keys(state.errors).map((key) => (
              <li key={key} id={`error-name-${key}`}>
                {state.errors[key]}
              </li>
            ))}
          </ul>
        </Errors>
      )}
      <Container>
        {isEvr && canEvrBeSubmitted && <Button
          outline
          onClick={() =>
            dispatch({ type: "setActiveModal", modal: "confirm-status-update", targetStatus: "verified" })
          }
          style={showSmallButtons ? smallButtonStyle : undefined}
        >
          Courtlink update done
        </Button>
        }
        {!applicationIsDirty && reDeclareRequired && (
          <Button
            outline
            onClick={() =>
              dispatch({ type: "setActiveModal", modal: "confirm-undo-edit" })
            }
            style={showSmallButtons ? smallButtonStyle : undefined}
          >
            Undo all edits
          </Button>
        )}
        {!isLegacyApplication && canBeSubmitted && reDeclareRequired && (
          <Button
            onClick={() => {
              if (state.fieldsCurrentlyOpen.length > 0) {
                dispatch({
                  type: "setActiveModal",
                  modal: "unsaved-fields",
                });
              } else {
                dispatch({
                  type: "setActiveModal",
                  modal: "confirm-re-declare",
                });
              }
            }}
            style={showSmallButtons ? smallButtonStyle : undefined}
          >
            Re-declare
          </Button>
        )}
        {isEvr && canEvrBeSubmitted && <Button
          outline
          onClick={() =>
            dispatch({ type: "setActiveModal", modal: "generate-coutlink-file" })
          }
          style={showSmallButtons ? smallButtonStyle : undefined}
        >
          Format for Courtlink
        </Button>
        }
        {hasCallbackFlag && (
          <Button
            alert
            onClick={async () =>
              await optomisticUpdateFlag({
                flagName: "call_back",
                ApplicationID: applicationID,
                currentValue: true,
                nextValue: false,
                dispatch,
              })
            }
          >
            Clear callback
          </Button>
        )}
        {reDeclareCanBeCanceled && (
          <Button
            alert
            onClick={() =>
              dispatch({
                type: "setActiveModal",
                modal: "confirm-cancel-re-decalre",
              })
            }
          >
            Cancel re-declare
          </Button>
        )}
        {canBeSubmitted && !isEvr && (
          <Button
            onClick={() => {
              if (state.fieldsCurrentlyOpen.length > 0) {
                dispatch({
                  type: "setActiveModal",
                  modal: "unsaved-fields",
                });
              } else {
                dispatch({
                  type: "setActiveModal",
                  modal: "confirm-courtlink-send",
                });
              }
            }}
          >
            Submit to courtlink
          </Button>
        )}
      </Container>
    </>
  );
};

export default ApplicationActions;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Errors = styled.div`
  width: 100%;
  text-align: left;
  color: ${colors.alertRed};
  border-bottom: 1px solid ${colors.alertRed};
  margin-bottom: 1em;

  ul {
    padding-left: 1em;
  }
`;

const smallButtonStyle = {
  minWidth: 'unset',
  padding: '0 0.5em'
}