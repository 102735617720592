import { API, Auth } from "aws-amplify";
import React from "react";
import {
  ApplicationDataType,
  ApplicationType,
  ChildDataType,
} from "csv-package";

import { ActionType } from "../context/application";

type OptomisitcUpdateParams = {
  dispatch: React.Dispatch<ActionType>;
  field: keyof ApplicationType | keyof ApplicationDataType | string;
  currentValue: string | string[] | null | ChildDataType[];
  nextValue: string | string[] | null | ChildDataType[];
  apiPath: string;
  apiBody: {
    [key: string]: string | string[] | null | ChildDataType[];
  };
  ApplicationID: string;
};

const optomisitcUpdate = async (
  params: OptomisitcUpdateParams
): Promise<void> => {
  const {
    dispatch,
    field,
    currentValue,
    nextValue,
    ApplicationID,
    apiBody,
    apiPath,
  } = params;

  if (typeof field === "number") {
    return;
  }

  // Update UI straigh away
  dispatch({
    type: "updateApplicationState",
    field,
    value: nextValue,
  });

  // Do API update
  const apiName = "csvApi";
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      ApplicationID,
      ...apiBody,
    },
  };
  try {
    const result = await API.post(apiName, apiPath, myInit);
    // API returns updated values
    // if RevisionLog is in result payload Update revision log UI
    if ("RevisionLog" in result) {
      dispatch({
        type: "updateApplicationState",
        field: "RevisionLog",
        value: result.RevisionLog,
      });
    }
  } catch (error) {
    // If there was an error alert the user and reset UI
    alert("Error: update failed. Please try again.");
    dispatch({
      type: "updateApplicationState",
      field,
      value: currentValue,
    });
    // setApplicationState((a) =>
    //   a === null ? null : _.set(a, field, currentValue)
    // );
  }
};

export default optomisitcUpdate;
