import React from "react"
import { API, Auth } from "aws-amplify"
import TimeAgo from "timeago-react"
import styled from "styled-components/macro"
import { RouteComponentProps } from "react-router"
import ReactLoading from "react-loading"
import { rem } from "polished"
import { ApplicationType } from "csv-package"

import { calculateApplicationType, filterLogic, tableColumns } from "../helpers/dashboardHelpers"
import getCourt from "../helpers/getCourt"

import TableStatus from "../components/TableStatus"
import Flag from "../components/Flag"
import ContentContainer from "../components/ContentContainer"
import DashboardHeader from "../components/DashboardHeader"
import { colors } from "../styles/variables"
import { GlobalContext } from "../context/global"

type SortStateType = {
  activeSortColumn: string
  direction: "ASC" | "DESC"
}

export type FilterTypeType = "all" | "unallocated" | "myApplications"
export type FilterJurisdictionType = "all" | "fvio" | "psio" | "fvioevr" | "support"

export type FilterStateType = {
  searchTerm: string
  type: FilterTypeType
  jurisdiction: FilterJurisdictionType
  currentRegistrar: string
}

export type ArchiveFiltersType = {
  timePeriod: string
  courtID: string
}

export type ViewType = "applications" | "archive"

type Props = RouteComponentProps

const Dashboard: React.FunctionComponent<Props> = (props) => {
  // Context and State
  const { globalState } = React.useContext(GlobalContext)
  const [view, setView] = React.useState<ViewType>("applications")
  const [applications, setApplications] = React.useState<ApplicationType[]>([])
  const [archive, setArcihve] = React.useState<ApplicationType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sortState, setSortState] = React.useState<SortStateType>({
    activeSortColumn: "Flagged",
    direction: "DESC"
  })
  const [filterState, setFilterState] = React.useState<FilterStateType>({
    searchTerm: "",
    type: "all",
    jurisdiction: "all",
    currentRegistrar: globalState.user?.attributes?.email || ""
  })
  const [archiveFilters, setArchiveFilters] = React.useState<ArchiveFiltersType>({
    courtID: "",
    timePeriod: ""
  })

  // On Mount
  React.useEffect(() => {
    const getApplications = async (showLoading?: boolean) => {
      const apiName = "csvApi"
      const path =
        "/private/registrar/getApplications/?status=submitted,verified,re-declare-requested,re-declare-accepted,verified-awaiting-leave"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }

      const result = await API.post(apiName, path, myInit)
      setApplications(result)
      setLoading(false)
    }

    getApplications()
    // eslint-disable-next-line
  }, [])

  // detect if Global content changes
  React.useEffect(() => {
    console.log("fire")
    setFilterState((state) => ({
      ...state,
      currentRegistrar: globalState.user?.attributes?.email || ""
    }))
  }, [globalState.user])

  const fetchArchivedApplications = async () => {
    if (archive.length === 0) {
      setLoading(true)
      const apiName = "csvApi"
      const path = "/private/registrar/getApplications/?status=archived,dnp"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }

      const result = await API.post(apiName, path, myInit)
      setArcihve(result)
      setLoading(false)
    }
  }

  // Filter and sort funcs
  const sortFunction = (a: ApplicationType, b: ApplicationType): number => {
    const columnObject = tableColumns.find((tableColumn) => tableColumn.label === sortState.activeSortColumn)

    if (!columnObject) {
      return 0
    }

    if ("sort" in columnObject) {
      return columnObject.sort[sortState.direction](a, b)
    }

    const aValue = a[columnObject.key] || ""
    const bValue = b[columnObject.key] || ""
    if (sortState.direction === "DESC") {
      return aValue < bValue ? -1 : 1
    }
    return aValue > bValue ? -1 : 1
  }

  const filterFunction = (application: ApplicationType): boolean => {
    const filterKeys = Object.keys(filterState) as Array<keyof FilterStateType>

    const results: boolean[] = filterKeys.map((key) => filterLogic[key](application, filterState))
    if (results.length === 0) {
      return true
    }
    return results.every((r) => r)
  }

  const list = view === "applications" ? applications : archive

  return (
    <ContentContainer>
      <DashboardHeader
        setFilterState={setFilterState}
        filterState={filterState}
        fetchArchivedApplications={fetchArchivedApplications}
        setView={setView}
        archiveFilters={archiveFilters}
        setArchiveFilters={setArchiveFilters}
        setLoading={setLoading}
        view={view}
        setArcihve={setArcihve}
      />
      <Table>
        <thead>
          <tr>
            {tableColumns.map((columnObject) => (
              <th
                key={columnObject.label}
                onClick={() =>
                  setSortState((currentSortState) => {
                    if (currentSortState.activeSortColumn === columnObject.label) {
                      return {
                        ...currentSortState,
                        direction: currentSortState.direction === "ASC" ? "DESC" : "ASC"
                      }
                    }
                    return {
                      activeSortColumn: columnObject.label,
                      direction: "DESC"
                    }
                  })
                }
              >
                {columnObject.label}{" "}
                {sortState.activeSortColumn === columnObject.label ? (
                  <SortIndicator direction={sortState.direction} />
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && (
            <>
              {list
                .filter(filterFunction)
                .sort(sortFunction)
                .map(
                  ({
                    ApplicationID,
                    Type,
                    Status,
                    Submitted,
                    Data,
                    Registrar,
                    CourtID,
                    Flags,
                    Jurisdiction
                  }) => (
                    <tr
                      key={ApplicationID}
                      onClick={() => props.history.push(`/application/${ApplicationID}`)}
                    >
                      <td>{ApplicationID}</td>
                      <td>
                        <TableStatus status={Status} />
                      </td>
                      <td>{calculateApplicationType(Type)}</td>
                      <td>
                        {new Date(Submitted).toLocaleDateString("en-AU")}
                        <br />
                        <span>
                          <TimeAgo datetime={Submitted} />
                        </span>
                      </td>
                      <td>
                        {Jurisdiction.toLowerCase() === "support" ? ( // Check if Application is Support Type
                          <>{Data.call_name}</>
                        ) : (
                          <>
                            <strong>{Data.appl_det_fam_nam || Data.ap_fam_nam},</strong>
                            <br />
                            {Data.appl_det_giv_nam || Data.ap_giv_nam}
                          </>
                        )}
                      </td>
                      <td>
                        <strong>{Data.resp_fam_nam},</strong>
                        <br />
                        {Data.resp_giv_nam}
                      </td>
                      <td>{Registrar}</td>
                      <td>{getCourt(CourtID).name}</td>
                      <td>
                        <FlagsContainer>
                          {Object.keys(Flags)
                            .filter((key) => key !== "appt_status" && Flags[key] === true)
                            .map((key) => (
                              <Flag key={key} type={key} />
                            ))}
                        </FlagsContainer>
                      </td>
                    </tr>
                  )
                )}
            </>
          )}
        </tbody>
      </Table>
      <Messages>
        {loading && <ReactLoading type="spin" />}
        {list.filter(filterFunction).sort(sortFunction).length === 0 && !loading && (
          <h3>No applications were found</h3>
        )}
      </Messages>
    </ContentContainer>
  )
}
export default Dashboard

const Table = styled.table`
  table-layout: auto;
  border-spacing: 0 0.36em;
  width: 100%;
  font-size: 0.875em;

  th {
    text-align: left;
    font-weight: normal;
    padding: 0 0.6em 1em;
    cursor: pointer;
    white-space: nowrap;

    &:first-child {
      padding-left: 0;
    }
  }

  tr {
    margin-bottom: 3.6em;
  }

  td {
    background: #f4f7fb;
    padding: 1em 0.6em;
    vertical-align: top;
    text-align: left;

    span {
      color: ${colors.gray};
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left: 0.57em solid #c7eff3;
      padding-left: 0.7em;
      white-space: nowrap;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      max-width: ${rem(160)};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const SortIndicator = styled.div<{ direction: "ASC" | "DESC" }>`
  display: inline-block;
  height: 0.42em;
  width: 0.42em;
  transform: rotate(${({ direction }) => (direction === "ASC" ? "45deg" : "225deg")});
  border-top: 1px solid ${colors.blue};
  border-left: 1px solid ${colors.blue};
  vertical-align: middle;
  margin: 0.25em;
`

const Messages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
`

const FlagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
