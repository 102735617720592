import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as TickIcon } from "../assets/icons/Tick.svg";

type Props = {
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  handleOnChange: React.ChangeEventHandler<HTMLInputElement>;
};

const Checkbox: React.FunctionComponent<Props> = ({
  name,
  value,
  checked,
  disabled,
  children,
  handleOnChange,
}) => (
  <CheckboxWrapper>
    <input
      type="checkbox"
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={handleOnChange}
    />
    <Check htmlFor={name}>
      <TickIcon />
    </Check>
    <label className="label" htmlFor={name}>
      {children}
    </label>
  </CheckboxWrapper>
);

export default Checkbox;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
  }
`;

export const Check = styled.label`
  height: 18px;
  width: 18px;
  border: 1px solid #868686;
  border-radius: 2px;
  background: white;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  > svg {
    width: 10px;
    height: 9px;
    display: none;
  }

  input:checked ~ & {
    color: white;
    border-color: rgba(14, 93, 171, 0.9701);
  }

  input:checked ~ & > svg {
    display: inline;
    fill: rgba(14, 93, 171, 0.9701);
  }

  input:checked ~ &:before {
    @media print {
      content: "✔";
      display: block;
      font-size: 1.3em;
    }
  }

  input:focus ~ & {
    box-shadow: 0px 0px 5px 0px rgba(14, 93, 171, 0.75);
  }
`;
