const localDatetimeString = (datetime: string): string => {
  const localDateString = new Date(datetime).toLocaleString("en-AU");

  return localDateString.replace(
    /([0-9]{2}\/[0-9]{2}\/[0-9]{4}, [0-9]{1,2}:[0-9]{2})(:[0-9]{2})/g,
    "$1"
  );
};

export default localDatetimeString;
