import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import { ReDeclareType } from "csv-package";

import { ApplicationContext } from "../context/application";
import { colors } from "../styles/variables";
import ContentContainer from "./ContentContainer";

const ReDeclareStatusBar: React.FunctionComponent<{}> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);

  // Don't show if there are no re-declares
  if (
    !state.applicationState ||
    state.applicationState.ReDeclarations.length === 0
  ) {
    return null;
  }

  const orderedReDecalres = state.applicationState!.ReDeclarations.sort(
    (a: ReDeclareType, b: ReDeclareType): number => {
      const aValueOf = new Date(a.Created).valueOf();
      const bValueOf = new Date(b.Created).valueOf();
      return bValueOf - aValueOf;
    }
  );

  const latestReDeclare = orderedReDecalres.find(
    (r: ReDeclareType) => r.Status === "accepted"
  );

  if (latestReDeclare === undefined) {
    return null;
  }

  if (latestReDeclare.Status !== "accepted") {
    return null;
  }

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    dispatch({ type: "toggleApplicationInView" });
  };

  return (
    <Container>
      <ContentContainer>
        <p>
          A new version of this application was declared on{" "}
          {new Date(latestReDeclare.Accepted).toLocaleDateString("en-AU")}.{" "}
          <ToggleApplication onClick={handleOnClick}>
            {state.applicationVersionInView === "latest"
              ? "View original application"
              : "View re-declared application"}
          </ToggleApplication>
        </p>
      </ContentContainer>
    </Container>
  );
};

export default ReDeclareStatusBar;

const Container = styled.div`
  background: #f8d9dd;
  height: ${rem(48)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 1280px;

  @media print {
    background: white;
  }
`;

const ToggleApplication = styled.button`
  display: inline;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: ${colors.blue};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  @media print {
    display: none;
  }
`;
