import React from "react";
import { ApplicationContext } from "../context/application";

import Button from "./Button";
import ReactLoading from "react-loading";
import Modal from "./Modal";
import { API, Auth } from "aws-amplify";
import { useParams } from "react-router";
import { TParams } from "../pages/Application";
import Radio from "./Radio";

type Props = {};

const ModalCancelReDeclare: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [retainData, setRetainData] = React.useState<boolean>(true);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const { applicationID } = useParams<TParams>();

  const handleProceedClick = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (processing) {
      return;
    }
    setProcessing(true);

    // Do API call
    const apiName = "csvApi";
    const path = "/private/registrar/cancelReDeclare";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        ApplicationID: applicationID,
        ReDeclareKey: state.applicationState!.ReDeclareKey,
        RetainData: retainData,
      },
    };
    try {
      const result = await API.post(apiName, path, myInit);
      dispatch({ type: "initialiseApplicationState", application: result });

      setProcessing(false);
    } catch (error) {
      alert("There has been an error.");
      setProcessing(false);
    }
    dispatch({ type: "setActiveModal", modal: null });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setRetainData(value === "yes");
  };

  return (
    <Modal
      type="alert"
      size="small"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      <p>Do you wish to keep the updates made for this redeclare?</p>
      <div>
        <Radio
          name="retainData"
          value="yes"
          checked={retainData}
          handleOnChange={handleChange}
          disabled={processing}
        >
          Keep updates
        </Radio>
        <span style={{ display: "block", height: "8px" }} />
        <Radio
          name="retainData"
          value="no"
          checked={!retainData}
          handleOnChange={handleChange}
          disabled={processing}
        >
          Discard updates permanently, This cannot be undone.
        </Radio>
      </div>

      <footer>
        {processing ? (
          <ReactLoading type="bubbles" />
        ) : (
          <>
            <Button
              alert
              outline
              onClick={() => dispatch({ type: "setActiveModal", modal: null })}
            >
              Cancel
            </Button>
            <Button alert onClick={handleProceedClick}>
              Proceed
            </Button>
          </>
        )}
      </footer>
    </Modal>
  );
};

export default ModalCancelReDeclare;
