import React from "react";
import styled from "styled-components/macro";
import _ from "lodash";
import { ConditionsType, conditionsChecker } from "csv-package";

import PortalQuestionLabel from "./PortalQuestionLabel";
import { ApplicationContext } from "../context/application";
import { colors } from "../styles/variables";
import generateAddressString from "../helpers/generateAddressString";
import { PortalQuestionWrapper } from "./PortalQuestion";

import { enabledStyle } from "./QuestionInput";
import applicationCanBeEdited from "../helpers/applicationCanBeEdited";
import useIsLegacyApplication from "../hooks/useIsLegacyApplication";
import PortalQuestionButtons from "./PortalQuestionButtons";
import useUpdateDataField from "../hooks/useUpdateDataField";
import axios from "axios";

export type AddressType = {
  _flat: string;
  _strnr: string;
  _strname: string;
  _strtype: string;
  _sub: string;
  _state: string;
  _postcode: string;
};

export type AddressConfigType = {
  suffix: string;
  placeholder: string;
  saving: boolean;
  actionSave: (value: string | string[]) => Promise<void>;
};

type PortalQuestionProps = {
  namePrefix: string;
  label: string;
  conditions?: ConditionsType;
  immutable: boolean;
};

const PortalQuestionAddress: React.FunctionComponent<PortalQuestionProps> = ({
  namePrefix,
  label,
  conditions = null,
  immutable,
}) => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [fieldOptions, setFieldOptions] =
    React.useState<{ [key: string]: string[] }>();
  const isLegacyApplication = useIsLegacyApplication(state);

  // setup actions
  const flatHook = useUpdateDataField(`${namePrefix}_flat`);
  const strnrHook = useUpdateDataField(`${namePrefix}_strnr`);
  const strnameHook = useUpdateDataField(`${namePrefix}_strname`);
  const strtypeHook = useUpdateDataField(`${namePrefix}_strtype`);
  const subHook = useUpdateDataField(`${namePrefix}_sub`);
  const stateHook = useUpdateDataField(`${namePrefix}_state`);
  const postcodeHook = useUpdateDataField(`${namePrefix}_postcode`);

  // detect postcode updates. Update state and suburb list accordingly
  const currentLocalPostcode = React.useMemo(
    () => _.get(state, `localApplicationState.Data.${namePrefix}_postcode`, ""),
    [namePrefix, state]
  );

  React.useEffect(() => {
    const fetchSuburbsForPostcode = async () => {
      const result = await axios.get(
        `https://api.addressify.com.au/address/suburbsForPostcode?api_key=f93ad8a9-ea0b-4614-a52c-0a46a262a158&term=${currentLocalPostcode}`
      );

      let suburbs: string[] = [];
      let states: string[] = [];

      result.data.forEach((r: string) => {
        const [suburb, statePostcode] = r.split(", ");
        const [state] = statePostcode.split(" ");

        suburbs.push(suburb);
        states.push(state);
      });

      // set Suburbs
      setFieldOptions((state) => ({
        _sub: _.uniq(suburbs),
        _state: _.uniq(states),
      }));

      // clear values for sub if not in current list
      if (
        !_.uniq(suburbs).includes(
          _.get(state, `localApplicationState.Data.${namePrefix}_sub`, "")
        )
      ) {
        dispatch({
          type: "updateLocalApplicationState",
          field: `Data.${namePrefix}_sub`,
          value: "",
        });
      }

      // clear values for state if not in current list
      if (
        !_.uniq(states).includes(
          _.get(state, `localApplicationState.Data.${namePrefix}_state`, "")
        )
      ) {
        dispatch({
          type: "updateLocalApplicationState",
          field: `Data.${namePrefix}_state`,
          value: "",
        });
      }
    };

    let re = /^[0-9]{4}$/;
    if (re.test(currentLocalPostcode) && edit) {
      fetchSuburbsForPostcode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocalPostcode, edit]);

  // setup some config we can use to map and reduce
  const addressFieldsConfig = [
    {
      ...postcodeHook,
      suffix: "_postcode",
      placeholder: "Postcode",
    },
    {
      ...subHook,
      suffix: "_sub",
      placeholder: "Suburb",
    },
    {
      ...stateHook,
      suffix: "_state",
      placeholder: "State",
    },
    {
      ...flatHook,
      suffix: "_flat",
      placeholder: "Flat",
    },
    {
      ...strnrHook,
      suffix: "_strnr",
      placeholder: "Street number",
    },
    {
      ...strnameHook,
      suffix: "_strname",
      placeholder: "Street name",
    },
    {
      ...strtypeHook,
      suffix: "_strtype",
      placeholder: "Street type",
    },
  ];

  // Handle functions
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    const field = `Data.${name}`;

    dispatch({
      type: "updateLocalApplicationState",
      field,
      value,
    });
  };

  const addressFieldsNames = addressFieldsConfig.map(
    (config) => `${namePrefix}${config.suffix}`
  );

  // make sure we have state
  if (state.localApplicationState === null) {
    return null;
  }

  // check for conditions
  if (
    conditions !== null &&
    !conditionsChecker(conditions, state.localApplicationState.Data)
  ) {
    return null;
  }

  const addressString = generateAddressString(
    state.localApplicationState.Data,
    namePrefix
  );

  const pickedApplicationFields = _(state.applicationState!.Data)
    .pick(addressFieldsNames)
    .pickBy(_.identity)
    .value();

  const pickedWorkingFields = _(state.workingApplicationState!.Data)
    .pick(addressFieldsNames)
    .pickBy(_.identity)
    .value();

  const pickedLocalFields = _(state.localApplicationState!.Data)
    .pick(addressFieldsNames)
    .pickBy(_.identity)
    .value();

  const reDeclareIndicator = !_.isEqual(
    pickedApplicationFields,
    pickedWorkingFields
  );

  const saveRequired = !_.isEqual(pickedWorkingFields, pickedLocalFields);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEdit(false);
    addressFieldsConfig.forEach((afc) => {
      dispatch({
        type: "updateLocalApplicationState",
        field: `Data.${namePrefix}${afc.suffix}`,
        value: _.get(
          state,
          `workingApplicationState.Data.${namePrefix}${afc.suffix}`
        ),
      });
    });
  };

  const handleSave = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setEdit(false);

    // get fields that need updating
    const fieldsToBeUpdated = addressFieldsConfig.filter(
      (afc) =>
        !_.isEqual(
          _.get(
            state,
            `workingApplicationState.Data.${namePrefix}${afc.suffix}`
          ),
          _.get(state, `localApplicationState.Data.${namePrefix}${afc.suffix}`)
        )
    );

    // do them one at a time
    for (let i = 0; i < fieldsToBeUpdated.length; i++) {
      const field = fieldsToBeUpdated[i];
      await field.actionSave(
        _.get(
          state,
          `localApplicationState.Data.${namePrefix}${field.suffix}`,
          ""
        )
      );
    }
  };

  const handleRevert = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    // revert on local
    addressFieldsConfig.forEach((afc) =>
      dispatch({
        type: "updateLocalApplicationState",
        field: `Data.${namePrefix}${afc.suffix}`,
        value: _.get(
          state,
          `applicationState.Data.${namePrefix}${afc.suffix}`,
          ""
        ),
      })
    );

    // revert on db
    // get fields that need updating
    const fieldsToBeReverted = addressFieldsConfig.filter(
      (afc) =>
        !_.isEqual(
          _.get(
            state,
            `workingApplicationState.Data.${namePrefix}${afc.suffix}`
          ),
          _.get(state, `applicationState.Data.${namePrefix}${afc.suffix}`)
        )
    );

    // do them one at a time
    for (let i = 0; i < fieldsToBeReverted.length; i++) {
      const field = fieldsToBeReverted[i];
      await field.actionSave(
        _.get(state, `applicationState.Data.${namePrefix}${field.suffix}`, "")
      );
    }
  };

  const hasError = addressFieldsConfig.some((afc) =>
    state.errors.hasOwnProperty(`Data.${namePrefix}${afc.suffix}`)
  );

  return (
    <>
      <PortalQuestionWrapper reDeclare={reDeclareIndicator} hasError={hasError}>
        <PortalQuestionLabel>{label}</PortalQuestionLabel>
        {edit ? (
          <div>
            {addressFieldsConfig.map((i) => {
              if (fieldOptions && fieldOptions[i.suffix]) {
                return (
                  <Select
                    key={i.suffix}
                    name={`${namePrefix}${i.suffix}`}
                    value={_.get(
                      state,
                      `localApplicationState.Data.${namePrefix}${i.suffix}`,
                      ""
                    )}
                    onChange={handleChange}
                    hasError={state.errors.hasOwnProperty(
                      `Data.${namePrefix}${i.suffix}`
                    )}
                  >
                    <option value="">
                      {i.suffix === "_sub"
                        ? "Select a suburb"
                        : "Select a state"}
                    </option>
                    {fieldOptions[i.suffix].map((o) => (
                      <option value={o} key={o}>
                        {o}
                      </option>
                    ))}
                  </Select>
                );
              }
              return (
                <Input
                  key={i.suffix}
                  type="text"
                  name={`${namePrefix}${i.suffix}`}
                  value={_.get(
                    state,
                    `localApplicationState.Data.${namePrefix}${i.suffix}`,
                    ""
                  )}
                  placeholder={i.placeholder}
                  onChange={handleChange}
                  hasError={state.errors.hasOwnProperty(
                    `Data.${namePrefix}${i.suffix}`
                  )}
                />
              );
            })}
          </div>
        ) : (
          <QuestionValue>{addressString}</QuestionValue>
        )}

        <PortalQuestionButtons
          hide={!applicationCanBeEdited(state)}
          showSavingIndicator={addressFieldsConfig.some((afc) => afc.saving)}
          showCommitButton={edit && saveRequired}
          showCancelButton={edit}
          showRevertButton={!edit && reDeclareIndicator}
          showEditButton={!edit && !isLegacyApplication}
          handleRevert={handleRevert}
          handleSave={handleSave}
          handleCancel={handleCancel}
          setEdit={setEdit}
          immutable={immutable}
        />
      </PortalQuestionWrapper>
    </>
  );
};

const QuestionValue = styled.p`
  color: ${colors.blue};
`;

export default PortalQuestionAddress;

const Input = styled.input`
  ${enabledStyle}
`;

const Select = styled.select`
  ${enabledStyle}
`;
