import { ApplicationStateType } from "../context/application";

const useReDeclareCanBeCanceled = (state: ApplicationStateType): boolean => {
  if (state.applicationState!.Status === "re-declare-requested") {
    return true;
  }
  return false;
};

export default useReDeclareCanBeCanceled;
