import React from "react";

import { ApplicationContext } from "../context/application";
import ModalUpdateStatus from "./ModalUpdateStatus";
import ModalCancelReDeclare from "./ModalCancelReDeclare";
import ModalCourtlink from "./ModalCourtlink";
import ModalReDeclare from "./ModalReDeclare";
import ModalUndoEdit from "./ModalUndoEdits";
import ModalUpdateCourt from "./ModalUpdateCourt";
import ModalUnsavedFields from "./ModalUnsavedFields";
import ModalCourtLinkFile from "./ModalCourtLinkFile";

type Props = {};

const ApplicationModals: React.FunctionComponent<Props> = () => {
  const [state] = React.useContext(ApplicationContext);

  switch (state.activeModal) {
    case "confirm-change-court":
      return <ModalUpdateCourt />;
    case "confirm-courtlink-send":
      return <ModalCourtlink />;
    case "confirm-status-update":
      return <ModalUpdateStatus />;
    case "confirm-re-declare":
      return <ModalReDeclare />;
    case "confirm-cancel-re-decalre":
      return <ModalCancelReDeclare />;
    case "confirm-undo-edit":
      return <ModalUndoEdit />;
    case "unsaved-fields":
      return <ModalUnsavedFields />;
    case "generate-coutlink-file":
      return <ModalCourtLinkFile />;
    default:
      return null;
  }
};

export default ApplicationModals;
