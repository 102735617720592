import React from "react";
import { ConditionsType, conditionsChecker } from "csv-package";

import { ApplicationContext } from "../context/application";

const ConditionalHeading: React.FunctionComponent<{
  conditions?: ConditionsType;
}> = ({ children, conditions }) => {
  const [state] = React.useContext(ApplicationContext);

  const reDeclareData = state.workingApplicationState!.Data;

  // check for conditions
  if (conditions && !conditionsChecker(conditions, reDeclareData)) {
    return null;
  }

  return <h4>{children}</h4>;
};

export default ConditionalHeading;
