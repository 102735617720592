import React from "react";
import { useParams } from "react-router";
import { courts } from "csv-package";

import { TParams } from "../pages/Application";

import Select from "./Select";
import Button from "./Button";
import Modal from "./Modal";
import { ApplicationContext } from "../context/application";
import optomisitcUpdate from "../helpers/optomisitcUpdate";

type Props = {};

const ModalUpdateCourt: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [selectedCourtID, setSelectedCourtID] = React.useState<string>("");
  const { applicationID } = useParams<TParams>();

  const handleUpdateCourt = async (nextCourtID: string): Promise<void> => {
    const currentCourtID = state.applicationState?.CourtID || "";

    await optomisitcUpdate({
      apiBody: {
        courtID: nextCourtID,
      },
      apiPath: "/private/registrar/updateApplicationCourt",
      ApplicationID: applicationID,
      currentValue: currentCourtID,
      nextValue: nextCourtID,
      field: "CourtID",
      dispatch,
    });
  };

  React.useEffect(() => {
    setSelectedCourtID(state.applicationState!.CourtID);
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      type="confirm"
      size="small"
      handleClose={(e) => dispatch({ type: "setActiveModal", modal: null })}
    >
      <p>Choose a court for application {applicationID}</p>
      <Select
        name="CourtID"
        value={selectedCourtID}
        onChange={(e) => setSelectedCourtID(e.target.value)}
      >
        {courts
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map(({ court_id, name }) => (
            <option key={court_id} value={court_id}>
              {name}
            </option>
          ))}
      </Select>
      <footer>
        <Button
          outline
          onClick={(e) => dispatch({ type: "setActiveModal", modal: null })}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleUpdateCourt(selectedCourtID);
            dispatch({ type: "setActiveModal", modal: null });
          }}
        >
          Confirm
        </Button>
      </footer>
    </Modal>
  );
};

export default ModalUpdateCourt;
