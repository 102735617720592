import React from "react";
import { Auth } from "aws-amplify";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { GlobalContext } from "../context/global";
import styled from "styled-components/macro";
import Button from "../components/Button";

type Props = RouteComponentProps;

const Login: React.FunctionComponent<Props> = (props) => {
  const { globalState } = React.useContext(GlobalContext);

  if (globalState.user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <LoginWrapper>
      <Button onClick={() => Auth.federatedSignIn()}>Login</Button>
    </LoginWrapper>
  );
};

export default Login;

const LoginWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 6.12rem);
  display: flex;
  justify-content: center;
  align-items: center;
`;
