import React from "react";
import _ from "lodash";
import {
  ApplicationStatusType,
  ApplicationDataType,
  ApplicationType,
  ChildDataType,
  NoteType,
} from "csv-package";

import { ErrorsType, UserType } from "../types";
import { ApplicationConfigType } from "../config";

export type ApplicaitonModal =
  | "confirm-status-update"
  | "confirm-change-court"
  | "confirm-courtlink-send"
  | "confirm-re-declare"
  | "confirm-cancel-re-decalre"
  | "confirm-undo-edit"
  | "unsaved-fields"
  | "generate-coutlink-file"
  | null;

export type ApplicationStateLayerType = {
  Data: ApplicationDataType;
  Narrative: string;
};

export type ApplicationStateType = {
  applicationState: ApplicationType | null;
  workingApplicationState: ApplicationStateLayerType | null;
  localApplicationState: ApplicationStateLayerType | null;
  courtRegistrars: UserType[];
  activeModal: ApplicaitonModal;
  targetStatus: ApplicationStatusType | null;
  applicationVersionInView: "original" | "latest";
  config: ApplicationConfigType | null;
  errors: ErrorsType;
  fieldsCurrentlyOpen: string[];
};

export const initialState: ApplicationStateType = {
  applicationState: null,
  workingApplicationState: null,
  localApplicationState: null,
  courtRegistrars: [],
  activeModal: null,
  targetStatus: null,
  applicationVersionInView: "latest",
  config: null,
  errors: {},
  fieldsCurrentlyOpen: [],
};

export type ActionType =
  | {
      type: "setActiveModal";
      modal: ApplicaitonModal;
      targetStatus?: ApplicationStatusType;
    }
  | {
      type: "updateApplicationFlag";
      value: boolean;
      flag: string;
    }
  | {
      type: "updateApplicationState";
      value: string | string[] | null | ChildDataType[];
      field: string;
    }
  | {
      type: "updateWorkingApplicationState";
      field: string;
      value: string | string[];
    }
  | {
      type: "updateLocalApplicationState";
      field: string;
      value: string | string[];
    }
  | {
      type: "addApplicationNote";
      note: NoteType;
    }
  | {
      type: "initialiseApplicationState";
      application: ApplicationType;
    }
  | {
      type: "setCourtRegistrars";
      registrars: UserType[];
    }
  | {
      type: "toggleApplicationInView";
    }
  | {
      type: "setConfig";
      config: ApplicationConfigType;
    }
  | {
      type: "cancelReDecalre";
    }
  | {
      type: "setErrors";
      errors: ErrorsType;
    }
  | {
      type: "undoWorkingEdits";
    }
  | {
      type: "copyLocalStateToWorkingState";
    }
  | {
      type: "fieldEditorOpened";
      field: string;
    }
  | {
      type: "fieldEditorClosed";
      field: string;
    };

export const reducer = (
  state: ApplicationStateType,
  action: ActionType
): ApplicationStateType => {
  switch (action.type) {
    case "updateApplicationFlag":
      if (state.applicationState === null) {
        return state;
      }
      return {
        ...state,
        applicationState: {
          ...state.applicationState,
          Flags: {
            ...state.applicationState.Flags,
            [action.flag]: action.value,
          },
        },
      };
    case "updateApplicationState":
      const applicationState =
        state.applicationState === null
          ? null
          : _.setWith(
              _.clone(state.applicationState),
              action.field,
              action.value,
              _.clone
            );
      return {
        ...state,
        applicationState,
      };
    case "updateWorkingApplicationState":
      const workingApplicationState =
        state.workingApplicationState === null
          ? null
          : _.setWith(
              _.clone(state.workingApplicationState),
              action.field,
              action.value,
              _.clone
            );
      return {
        ...state,
        workingApplicationState,
      };
    case "updateLocalApplicationState":
      const localApplicationState =
        state.localApplicationState === null
          ? null
          : _.setWith(
              _.clone(state.localApplicationState),
              action.field,
              action.value,
              _.clone
            );

      return {
        ...state,
        localApplicationState,
      };
    case "undoWorkingEdits":
      return {
        ...state,
        localApplicationState: state.applicationState,
        workingApplicationState: state.applicationState,
        errors: {},
      };
    case "initialiseApplicationState":
      const { application } = action;
      // if awiting re declare add refill data in re
      if (application.Status === "re-declare-requested") {
        const reDeclareObject =
          application.ReDeclarations![application.ReDeclarations!.length - 1];

        const localWorkingApplicationState = {
          Narrative: reDeclareObject.Narrative,
          Data: {
            ...application.Data,
            ...reDeclareObject.Data,
          },
        };

        return {
          ...state,
          applicationState: application,
          workingApplicationState: localWorkingApplicationState,
          localApplicationState: localWorkingApplicationState,
        };
      }
      return {
        ...state,
        applicationState: application,
        workingApplicationState: {
          Narrative: application.WorkingNarrative
            ? application.WorkingNarrative
            : application.Narrative,
          Data: {
            ...application.Data,
            ...application.WorkingData,
          },
        },
        localApplicationState: {
          Narrative: application.WorkingNarrative
            ? application.WorkingNarrative
            : application.Narrative,
          Data: {
            ...application.Data,
            ...application.WorkingData,
          },
        },
      };
    case "setCourtRegistrars":
      const { registrars } = action;
      return {
        ...state,
        courtRegistrars: registrars,
      };
    case "setActiveModal":
      const nextState = {
        ...state,
        activeModal: action.modal,
      };
      if (action.modal === "confirm-status-update") {
        nextState.targetStatus =
          action.targetStatus === undefined ? null : action.targetStatus;
      }

      return nextState;
    case "addApplicationNote":
      if (state.applicationState === null) return state;
      return {
        ...state,
        applicationState: {
          ...state.applicationState,
          Notes: [...(state.applicationState?.Notes || []), action.note],
        },
      };
    case "toggleApplicationInView":
      // if the current view is the latest
      // reverse loop through re-decalres and make adjustments
      // and set view to original
      if (state.applicationVersionInView === "latest") {
        const workingApplicationState = {
          Data: state.applicationState!.Data,
          Narrative: state.applicationState!.Narrative,
        };
        // loop through all re-decare object in reverse and reverse updates
        state.applicationState?.ReDeclarations.reverse().forEach((r) => {
          if (r.Status === "accepted" && workingApplicationState !== null) {
            workingApplicationState.Data = {
              ...workingApplicationState.Data,
              ...r.PreviousData,
            };
            workingApplicationState.Narrative = r.PreviousNarrative;
          }
        });

        if (workingApplicationState === undefined) return state;

        return {
          ...state,
          applicationVersionInView: "original",
          workingApplicationState,
        };
      } else {
        // switching to latest
        return {
          ...state,
          applicationVersionInView: "latest",
          workingApplicationState: state.applicationState,
        };
      }
    case "setConfig":
      return {
        ...state,
        config: action.config,
      };
    case "setErrors":
      return {
        ...state,
        errors: action.errors,
      };
    case "fieldEditorOpened":
      return {
        ...state,
        fieldsCurrentlyOpen: [...state.fieldsCurrentlyOpen, action.field],
      };
    case "fieldEditorClosed":
      const fieldsCurrentlyOpen = state.fieldsCurrentlyOpen.filter(
        (f) => f !== action.field
      );
      return {
        ...state,
        fieldsCurrentlyOpen,
      };
    case "copyLocalStateToWorkingState":
      return {
        ...state,
        workingApplicationState: state.localApplicationState,
      };
    default:
      return state;
  }
};

export const ApplicationContext =
  // @ts-ignore
  React.createContext<[ApplicationStateType, React.Dispatch<ActionType>]>();
