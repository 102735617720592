import React from "react";
import styled, { css } from "styled-components/macro";

import { colors } from "../styles/variables";

type Props = {
  alert?: boolean;
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const ButtonInline: React.FunctionComponent<Props> = ({
  children,
  alert,
  icon,
  onClick,
  disabled,
}) => (
  <Container onClick={onClick} alert={alert} disabled={disabled}>
    {icon && icon}
    <span>{children}</span>
  </Container>
);

export default ButtonInline;

const Container = styled.button<{ alert?: boolean; disabled?: boolean }>`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: ${({ alert }) => (alert ? colors.alertRed : colors.blue)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  svg {
    height: 1em;
    width: 1em;
    margin-right: 0.2em;
    fill: currentColor;
  }
  span {
    text-decoration: underline;
    white-space: nowrap;
  }

  &:hover span {
    text-decoration: none;
  }

  & + & {
    margin-left: 1em;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: #b5b5b5;
    `}
`;
