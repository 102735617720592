import React from "react";
import styled from "styled-components/macro";
import TimeAgo from "timeago-react";
import { NoteType } from "csv-package";

import localDatetimeString from "../helpers/localDatetimeString";

type NoteProps = {
  note: NoteType;
};

const Note: React.FunctionComponent<NoteProps> = ({ note }) => (
  <Container>
    <p>{note.Text}</p>
    <footer>
      <div>
        <Light>
          <TimeAgo datetime={note.Datetime} />{" "}
        </Light>
        {localDatetimeString(note.Datetime)}
      </div>
      <Light>{note.Name}</Light>
    </footer>
  </Container>
);

export default Note;

const Container = styled.div`
  background-color: rgb(242, 242, 243);
  margin-bottom: 0.45rem;
  padding: 1rem;
  border-radius: 0.38rem;

  p {
    margin: 0 0 0.5rem;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.875rem;
  }
`;

const Light = styled.span`
  font-weight: 300;
  color: #4a4a4a;
`;
