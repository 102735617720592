import { API, Auth } from "aws-amplify";
import React from "react";
import { useParams } from "react-router";
import styled from "styled-components/macro";
import { NoteType } from "csv-package";

import { ApplicationContext } from "../context/application";

import { GlobalContext } from "../context/global";
import { TParams } from "../pages/Application";
import { colors } from "../styles/variables";

import Button from "./Button";
import Note from "./Note";

type Props = {};

const ApplicationNotes: React.FunctionComponent<Props> = () => {
  const { globalState } = React.useContext(GlobalContext);
  const [state, dispatch] = React.useContext(ApplicationContext);
  const { applicationID } = useParams<TParams>();

  const [addNotePanelOpen, setAddNotePanelOpen] = React.useState<boolean>(
    false
  );
  const [noteText, setNoteText] = React.useState<string>("");
  const [noteSaving, setNoteSaving] = React.useState<boolean>(false);

  const handlePublish = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setNoteSaving(true);

    const currentNoteText = noteText;
    const Note = {
      Datetime: new Date().toISOString(),
      Text: currentNoteText,
      Name: `${globalState.user?.attributes.given_name || ""} ${
        globalState.user?.attributes.family_name || ""
      }`,
    };

    // update state UI
    dispatch({
      type: "addApplicationNote",
      note: Note,
    });

    // update api
    const apiName = "csvApi";
    const path = "/private/registrar/addApplicationNote";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        ApplicationID: applicationID,
        Note,
      },
    };

    try {
      await API.post(apiName, path, myInit);
      setNoteText("");
      setAddNotePanelOpen(false);
      setNoteSaving(false);
    } catch (error) {
      alert("Failed to save note please try again!");
      setNoteSaving(false);
    }
  };

  if (state.applicationState === null) {
    return null;
  }

  return (
    <section>
      <h3>Notes</h3>
      {state.applicationState.Notes.map((note: NoteType, index: number) => (
        <Note key={index} note={note} />
      ))}

      {addNotePanelOpen ? (
        <>
          <Textarea
            name="Text"
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
          <Split>
            <Button inline onClick={() => setAddNotePanelOpen(false)}>
              Cancel
            </Button>
            <Button slim onClick={handlePublish} disabled={noteSaving}>
              {noteSaving ? "Saving" : "Publish"}
            </Button>
          </Split>
        </>
      ) : (
        <>
          <Button inline onClick={() => setAddNotePanelOpen(!addNotePanelOpen)}>
            Add note
          </Button>
        </>
      )}
    </section>
  );
};

export default ApplicationNotes;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 5.63rem;
  border: 1px solid ${colors.gray};
  border-radius: 3px;
  padding: 1em;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
`;
