import { API, Auth } from "aws-amplify";
import _ from "lodash";
import { rem } from "polished";
import React from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router";
import styled from "styled-components/macro";
import { format } from "timeago.js";
import { SendConfigMethodType, SendConfigType } from "csv-package";

import { ApplicationContext } from "../context/application";
import { GlobalContext } from "../context/global";

import { getDeliverTimes } from "../helpers/deliveryTimes";

import { colors } from "../styles/variables";

import Button from "./Button";
import Modal from "./Modal";
import Select from "./Select";
import Input from "./Input";
import { unProtectedPersonRoles } from "../config/fvioevr";

type Props = {};

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ModalReDeclare: React.FunctionComponent<Props> = () => {
  const { applicationID } = useParams<{ applicationID: string }>();
  const { globalState } = React.useContext(GlobalContext);
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [sendConfigState, setSendConfigState] = React.useState<SendConfigType>({
    Method: "sms",
    Destination: "",
    SenderAlias: "MCSERVICES",
    DeliveryTime: "now",
  });
  const [reDeclareUrl, setReDeclareUrl] = React.useState<string>("");
  const [otherField, setOtherField] = React.useState<string>("");

  const senderAliases = ["MCSERVICES", "BUNNINGS", "COLES"];
  const deliverTimes = React.useMemo(() => getDeliverTimes(), []);
  const applicantHasAccount: boolean = React.useMemo(
    () => Boolean(state.applicationState?.User),
    [state.applicationState]
  );
  const isEvr = state?.config?.applicationType === "EVR";

  // change senderAlias when method changes
  // on mount
  React.useEffect(() => {
    // calculate Method
    const appl_safe_cont = isEvr && (state.workingApplicationState!.Data?.seeking === "resp" || unProtectedPersonRoles.includes(state?.workingApplicationState!.Data?.seeking_appl_role)) ? state.workingApplicationState!.Data.appl_cont as string[] :
        state.workingApplicationState!.Data.appl_safe_cont as string[];

    let Method: SendConfigMethodType = "sms";

    if (appl_safe_cont.includes("email") && !appl_safe_cont.includes("phone")) {
      Method = "email";
    }

    if (appl_safe_cont.includes("unsafe")) {
      Method = "none";
    }

    setSendConfigState((scs) => ({
      ...scs,
      Method,
    }));
    // eslint-disable-next-line
  }, []);

  // run when method updates
  React.useEffect(() => {
    const SenderAlias =
      sendConfigState.Method === "email"
        ? "noreply@courts.vic.gov.au"
        : senderAliases[0];
    setSendConfigState((scs) => ({
      ...scs,
      SenderAlias,
    }));
    // eslint-disable-next-line
  }, [sendConfigState.Method]);

  const reDeclareCanBeProcessed: boolean = React.useMemo(() => {
    if (sendConfigState.Method === "none" && !applicantHasAccount) {
      return false;
    }

    if (sendConfigState.Method === "none") {
      return true;
    }
    const patterns: { [K in SendConfigMethodType]: RegExp } = {
      email:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      sms: /^04[0-9]{8}$/,
      none: /./,
    };

    const pattern = RegExp(patterns[sendConfigState.Method]);

    const destinationString =
      sendConfigState.Destination === "other"
        ? otherField
        : sendConfigState.Destination;

    return pattern.test(destinationString);
  }, [
    otherField,
    sendConfigState.Destination,
    sendConfigState.Method,
    applicantHasAccount,
  ]);

  if (state.workingApplicationState === null) {
    return null;
  }

  const applicantName = `${state.workingApplicationState.Data.appl_det_giv_nam} ${state.workingApplicationState.Data.appl_det_fam_nam}`;
  const handleReDeclareSend = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      state.applicationState === null ||
      state.workingApplicationState === null
    ) {
      return;
    }
    setProcessing(true);

    const Data = _.omitBy(
      state.workingApplicationState.Data,
      (value, key) => _.get(state, `applicationState.Data.${key}`) === value
    );

    const SendConfig = sendConfigState;
    // swap value if other is selected
    // we should only be here is it's a valid mobile or email address
    if (SendConfig.Destination === "other") {
      SendConfig.Destination = otherField;
    }

    // build payload
    const payload = {
      ApplicationType: state.config?.applicationType,
      ApplicationID: applicationID,
      SendConfig,
      Data,
      Narrative: state.workingApplicationState!.Narrative,
    };

    // do api call
    const apiName = "csvApi";
    const path = "/private/registrar/addApplicationReDeclare";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: payload,
    };
    try {
      const result = await API.post(apiName, path, myInit);
      setProcessing(false);
      dispatch({
        type: "updateApplicationState",
        field: "Status",
        value: "re-declare-requested",
      });

      dispatch({
        type: "updateApplicationState",
        field: "ReDeclareKey",
        value: result.ReDeclareKey,
      });

      dispatch({
        type: "copyLocalStateToWorkingState",
      });

      if ("RevisionLog" in result) {
        dispatch({
          type: "updateApplicationState",
          field: "RevisionLog",
          value: result.RevisionLog,
        });
      }

      setReDeclareUrl(result.urlToSend);
    } catch (error) {
      alert("There has been an error.");
      setProcessing(false);
    }
  };

  if (reDeclareUrl) {
    return (
      <Modal
        type="confirm"
        size="large"
        handleClose={(e) => dispatch({ type: "setActiveModal", modal: null })}
      >
        <p>
          {sendConfigState.Method === "none"
            ? `Re-declaration has been successfully sent to the applicant without a link.`
            : sendConfigState.DeliveryTime === "now"
              ? `Re-declaration has been successfully sent to the applicant with the
          following link ${reDeclareUrl}`
              : `Re-declaration has been scheduled to be sent to the applicant with the following link ${reDeclareUrl}`}
        </p>
        <footer>
          <Button
            onClick={() => dispatch({ type: "setActiveModal", modal: null })}
          >
            Dismiss
          </Button>
        </footer>
      </Modal>
    );
  }

  return (
    <Modal
      type="confirm"
      size="large"
      handleClose={(e) => dispatch({ type: "setActiveModal", modal: null })}
    >
      <h2>
        {
          {
            email: `Send ${applicantName} a secure link by email to retrieve their application and re-declare`,
            sms: `Send ${applicantName} a secure link by SMS to retrieve their application and re-declare`,
            none: `Send ${applicantName} their application for re-declaration to their online account only.`,
          }[sendConfigState.Method]
        }
      </h2>
      <Table>
        <tbody>
          <tr>
            <td>
              <Label htmlFor="Method">Send link via:</Label>
            </td>
            <td>
              <Select
                value={sendConfigState.Method}
                name="Method"
                onChange={(e) => {
                  e.persist();
                  const Method = e.target.value as SendConfigMethodType;
                  setSendConfigState((state) => ({
                    ...state,
                    Method,
                  }));
                }}
              >
                {!state.applicationState?.User && (
                  <option value="">Please select a send option</option>
                )}
                <option value="email">
                  Send email with link for re-declare
                </option>
                <option value="sms">Send SMS with link for re-declare</option>
                {state.applicationState?.User && (
                  <option value="none">
                    Re-declare via online account only
                  </option>
                )}
              </Select>
            </td>
          </tr>
          {sendConfigState.Method !== "none" && (
            <>
              <tr>
                <td>
                  <Label htmlFor="Destination">Send to:</Label>
                </td>
                <td>
                  <Select
                    value={sendConfigState.Destination}
                    name="Destination"
                    onChange={(e) => {
                      e.persist();
                      setSendConfigState((state) => ({
                        ...state,
                        Destination: e.target.value,
                      }));
                    }}
                  >
                    <option value="">
                      Select a{" "}
                      {sendConfigState.Method === "sms"
                        ? "phone number"
                        : "email address"}
                    </option>
                    {{
                      sms: isEvr && (state.workingApplicationState!.Data?.seeking === "resp" || unProtectedPersonRoles.includes(state?.workingApplicationState!.Data?.seeking_appl_role)) ? [
                        "appl_phone_3",
                        "appl_phone",
                        "appl_phone_2"
                      ] :[
                        "appl_cont_phone",
                        "appl_cont_phone_2",
                        "appl_cont_phone_3",
                        "appl_cont_trst_per_phone",
                      ],
                      email: isEvr && (state.workingApplicationState!.Data?.seeking === "resp" || unProtectedPersonRoles.includes(state?.workingApplicationState!.Data?.seeking_appl_role)) ? [
                        "appl_email",
                        "appl_email_2"
                      ]:[
                        "appl_cont_email",
                        "appl_cont_email_2",
                        "appl_cont_email_3",
                        "appl_cont_trst_per_email",
                      ],
                    }[sendConfigState.Method]
                      .filter(
                        (n) =>
                          _.get(
                            state,
                            `workingApplicationState.Data.${n}`,
                            null
                          ) !== null
                      )
                      .filter((n) => {
                        if (sendConfigState.Method === "email") {
                          return true;
                        }
                        const v = _.get(
                          state,
                          `workingApplicationState.Data.${n}`,
                          ""
                        );
                        return Boolean(v.match(/^04[0-9]{8}$/gm));
                      })
                      .map((n) => (
                        <option
                          value={_.get(
                            state,
                            `workingApplicationState.Data.${n}`,
                            ""
                          )}
                          key={n}
                        >
                          {_.get(
                            state,
                            `workingApplicationState.Data.${n}`,
                            ""
                          )}
                          {n.indexOf("_cont_trst_") !== -1
                            ? ` (${_.get(
                              state,
                              `workingApplicationState.Data.appl_cont_trst_per`,
                              ""
                            )})`
                            : ""}
                        </option>
                      ))}
                    <option value="other">Other</option>
                  </Select>
                </td>
              </tr>
              {sendConfigState.Destination === "other" && (
                <tr>
                  <td>
                    <Label htmlFor="destination_other">
                      {sendConfigState.Method === "email"
                        ? "Other email:"
                        : "Other phone number:"}
                    </Label>
                  </td>
                  <td>
                    <Input
                      type="text"
                      onChange={(e) => setOtherField(e.target.value)}
                      value={otherField}
                      name="destination_other"
                      error={!reDeclareCanBeProcessed}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <Label htmlFor="SenderAlias">
                    {sendConfigState.Method === "sms"
                      ? `Show sender as:`
                      : `From address:`}
                  </Label>
                </td>
                <td>
                  <Select
                    value={sendConfigState.SenderAlias}
                    name="SenderAlias"
                    onChange={(e) => {
                      e.persist();
                      setSendConfigState((state) => ({
                        ...state,
                        SenderAlias: e.target.value,
                      }));
                    }}
                  >
                    {sendConfigState.Method === "sms" ? (
                      senderAliases.map((sa) => (
                        <option key={sa} value={sa}>
                          {sa}
                        </option>
                      ))
                    ) : (
                      <>
                        <option value="noreply@courts.vic.gov.au">
                          noreply@courts.vic.gov.au
                        </option>
                        <option value={globalState.user!.attributes.email}>
                          {globalState.user!.attributes.email}
                        </option>
                      </>
                    )}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <Label htmlFor="DeliveryTime">Select delivery time:</Label>
                </td>
                <td>
                  <Select
                    value={sendConfigState.DeliveryTime}
                    name="DeliveryTime"
                    onChange={(e) => {
                      e.persist();
                      setSendConfigState((state) => ({
                        ...state,
                        DeliveryTime: e.target.value,
                      }));
                    }}
                  >
                    <option value="now">Now</option>
                    {deliverTimes.map((dt, i) => {
                      const now = new Date();
                      const dateObject = new Date(dt);
                      let day = dayNames[dateObject.getDay()];

                      if (dateObject.getDay() === now.getDay()) {
                        day = "Today";
                      } else if (dateObject.getDay() === now.getDay() + 1) {
                        day = "Tomorrow";
                      }

                      const bracketsText =
                        i < 4 ? format(dateObject).replace("in ", "") : day;
                      let mins =
                        dateObject.getMinutes() < 10
                          ? `0${dateObject.getMinutes()}`
                          : dateObject.getMinutes();
                      const text = `${dateObject.getHours() > 12
                        ? dateObject.getHours() % 12
                        : dateObject.getHours()
                        }:${mins} ${dateObject.getHours() >= 12 ? "PM" : "AM"
                        }  (${bracketsText})`;

                      return (
                        <option key={dt} value={dateObject.toISOString()}>
                          {text}
                        </option>
                      );
                    })}
                  </Select>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {state.applicationState!.User && (
        <Note>
          *This applicant is set up with an online account. They can log in
          later and re-decalre.
        </Note>
      )}

      <footer>
        {processing ? (
          <>
            <ReactLoading type="bubbles" />
          </>
        ) : (
          <>
            <Button
              outline
              onClick={(e) => dispatch({ type: "setActiveModal", modal: null })}
            >
              Cancel
            </Button>
            <Button
              disabled={!reDeclareCanBeProcessed}
              onClick={handleReDeclareSend}
            >
              Confirm send
            </Button>
          </>
        )}
      </footer>
    </Modal>
  );
};

export default ModalReDeclare;

const Table = styled.table`
  border-spacing: ${rem(6)};
  width: 100%;

  td {
    border: none;
    text-align: left;
    vertical-align: middle;
    padding: 0;
    height: ${rem(38)};
  }

  td:first-child {
    width: ${rem(260)};
  }
`;

const Note = styled.p`
  font-size: ${rem(14)};
  color: ${colors.black};
`;

const Label = styled.label`
  font-size: ${rem(18)};
`;
