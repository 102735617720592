import { fontFace, normalize } from "polished";
import { createGlobalStyle } from "styled-components/macro";
import { colors } from "./variables";

export default createGlobalStyle`

    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Regular",
      fontWeight: "300",
    })}

    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Medium",
      fontWeight: "400",
    })}

    ${fontFace({
      fontFamily: "HK Grotesk",
      fontFilePath: "/fonts/HKGrotesk-Bold",
      fontWeight: "700",
    })}

    ${normalize()}

    html {
      font-family: "HK Grotesk", Helvetica, sans-serif;
    }

    body {
        font-family: "HK Grotesk", Helvetica, sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: ${colors.black};
    }

    svg {
        fill: currentColor;
    }

    * {
        box-sizing: border-box;
    }



    a {
      color: inherit;
    }

    .hide-print {
      @media  print{
        display: none;
      }
    }

    h2 {
      color: ${colors.black};
      font-size: 1.625rem;
    }

    h2 {
      color: ${colors.black};
      font-size: 1.375rem;
    }

    @media print {
      font-size: 1.5em;
    }
`;
