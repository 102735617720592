import { API, Auth } from "aws-amplify";
import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";
import { ApplicationType } from "csv-package";

import { GlobalContext } from "../context/global";

import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import getCourt from "../helpers/getCourt";
import getTimeOptions from "../helpers/getTimeOptions";

import {
  FilterStateType,
  ViewType,
  ArchiveFiltersType,
  FilterJurisdictionType,
  FilterTypeType,
} from "../pages/Dashboard";

import Button from "./Button";
import Select from "./Select";
import { colors } from "../styles/variables";

type Props = {
  setFilterState: React.Dispatch<React.SetStateAction<FilterStateType>>;
  filterState: FilterStateType;
  fetchArchivedApplications: Function;
  view: ViewType;
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
  archiveFilters: ArchiveFiltersType;
  setArchiveFilters: React.Dispatch<React.SetStateAction<ArchiveFiltersType>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setArcihve: React.Dispatch<React.SetStateAction<ApplicationType[]>>;
};

const DashboardHeader: React.FunctionComponent<Props> = ({
  filterState,
  setFilterState,
  fetchArchivedApplications,
  view,
  setView,
  archiveFilters,
  setArchiveFilters,
  setLoading,
  setArcihve,
}) => {
  const { globalState } = React.useContext(GlobalContext);

  const filterTypes: FilterTypeType[] = [
    "all",
    "unallocated",
    "myApplications",
  ];

  const handleApplicationJurisdictionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const jurisdiction = e.target.value as FilterJurisdictionType;
    setFilterState((s) => ({
      ...s,
      jurisdiction,
    }));
  };

  const handleArchiveFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    setArchiveFilters((a) => ({
      ...a,
      [name]: value,
    }));
  };

  return (
    <DashboardHeaderWrapper>
      <Heading>Dashboard</Heading>

      <HeaderSplit>
        <SearchBar>
          <input
            type="text"
            value={filterState?.searchTerm || ""}
            name="searchTerm"
            placeholder="Search applications"
            onChange={(e) => {
              e.persist();
              setFilterState((s) => ({ ...s, searchTerm: e.target.value }));
            }}
          />
          <button>
            <SearchIcon />
          </button>
          <p>Applicant name, reference number, respondent name</p>
        </SearchBar>
        <ServicingCourt>
          <h5>Servicing court</h5>
          <ul>
            {globalState.courtIDs.map((courtID) => {
              const court = getCourt(courtID);
              return (
                <li key={courtID}>
                  {court.name}
                  <br />
                  {court.locality} {court.postal_code}
                </li>
              );
            })}
          </ul>
        </ServicingCourt>
      </HeaderSplit>

      <HeaderSplit>
        {view === "archive" && (
          <FilterControlSection direction="left">
            <Select
              name="courtID"
              id="courtID"
              onChange={handleArchiveFilterChange}
              value={archiveFilters.courtID}
            >
              <option value="">Please select a court</option>
              {globalState.courtIDs.map((courtID) => {
                const court = getCourt(courtID);
                return (
                  <option key={courtID} value={courtID}>
                    {court.name}
                  </option>
                );
              })}
            </Select>
            <Select
              name="timePeriod"
              id="timePeriod"
              onChange={handleArchiveFilterChange}
              value={archiveFilters.timePeriod}
            >
              <option value="">Please select a time period</option>
              {getTimeOptions().map(({ key, value }) => (
                <option value={key} key={key}>
                  {value}
                </option>
              ))}
            </Select>
            <Button
              onClick={async () => {
                setLoading(true);

                const apiName = "csvApi";
                const path = `/private/registrar/getApplications/?status=archived,dnp&courtID=${
                  archiveFilters.courtID
                }&year=${archiveFilters.timePeriod.split("-")[0]}&month=${
                  archiveFilters.timePeriod.split("-")[1]
                }`;
                const myInit = {
                  headers: {
                    Authorization: `Bearer ${(await Auth.currentSession())
                      .getIdToken()
                      .getJwtToken()}`,
                  },
                };

                const result = await API.post(apiName, path, myInit);
                setArcihve(result);
                setLoading(false);
              }}
              disabled={
                archiveFilters.courtID === "" ||
                archiveFilters.timePeriod === ""
              }
            >
              Go
            </Button>
            <Button clear onClick={() => setView("applications")}>
              Cancel
            </Button>
          </FilterControlSection>
        )}

        {view === "applications" && (
          <FilterControlSection direction="left">
            {filterTypes.map((type) => (
              <Button
                key={type}
                onClick={() => setFilterState((s) => ({ ...s, type }))}
                secondary={filterState.type !== type}
              >
                {
                  {
                    all: "All applications",
                    unallocated: "Unallocated",
                    myApplications: "My applications",
                  }[type]
                }
              </Button>
            ))}
          </FilterControlSection>
        )}
        <FilterControlSection direction="right">
          <h5>Showing</h5>
          <StyledSelect
            name="jurisdiction"
            id="jurisdiction"
            onChange={handleApplicationJurisdictionChange}
          >
            <option value="all">All Applications</option>
            <option value="fvio">FVIO Applications</option>
            <option value="psio">PSIO Applications</option>
            <option value="fvioevr">FV-EVR Applications</option>
            <option value="support">Callback Applications</option>
          </StyledSelect>
          {view === "applications" && (
            <Button
              onClick={() => {
                fetchArchivedApplications();
                setView("archive");
              }}
              secondary
            >
              View archive
            </Button>
          )}
        </FilterControlSection>
      </HeaderSplit>
    </DashboardHeaderWrapper>
  );
};

export default DashboardHeader;

const DashboardHeaderWrapper = styled.header`
  width: 100%;
`;

const Heading = styled.h1`
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 1.36em;
`;

const SearchBar = styled.div`
  position: relative;
  width: 48rem;

  input {
    font-size: 0.9375rem;
    height: 2.53em;
    width: 100%;
    border: 1px solid ${colors.gray};
    border-radius: 3px;
    padding-left: 0.8em;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: transparent;
    padding: 0;
    height: 2.53em;
    width: 2.53em;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 21px;
      width: 21px;
    }
  }

  p {
    font-size: 0.875em;
    color: ${colors.gray};
  }
`;

const HeaderSplit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & + & {
    margin-top: 2.625rem;
  }

  &:last-child {
    margin-bottom: 2.5rem;
  }
`;

const ServicingCourt = styled.div`
  font-size: 0.9375rem;
  padding: 1em;
  width: 16.8em;
  background: #f4f7fb;
  border-radius: 6px;

  h5 {
    font-weight: 400;
    font-size: 1em;
    margin: 0;
    color: ${colors.gray};
  }

  ul {
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid rgb(234, 239, 245);

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

const FilterControlSection = styled.div<{ direction: "left" | "right" }>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "left" ? "flex-start" : "flex-end"};

  h5 {
    font-size: 0.9375rem;
    height: 2.53em;
    line-height: 2.53;
    color: ${colors.black};
    padding: 0 1em;
    font-weight: normal;
    margin: 0;
    padding: 0 1em;
  }
`;

const StyledSelect = styled(Select)`
  margin-right: ${rem(6)};
  width: ${rem(200)};
`;
