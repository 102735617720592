import React from "react";
import { ApplicationStatusType } from "csv-package";

import { ActionType, ApplicationStateType } from "../context/application";
import optomisitcUpdate from "./optomisitcUpdate";

const updateApplicationStatus = async (
  ApplicationID: string,
  status: ApplicationStatusType,
  state: ApplicationStateType,
  dispatch: React.Dispatch<ActionType>
): Promise<void> => {
  if (state.applicationState === null) return;

  const currentValue = state.applicationState.Status;
  const nextValue = status;

  await optomisitcUpdate({
    apiBody: {
      ApplicationID,
      status,
    },
    apiPath: "/private/registrar/updateApplicationStatus",
    ApplicationID: state.applicationState.ApplicationID,
    currentValue,
    nextValue,
    field: "Status",
    dispatch,
  });
};

export default updateApplicationStatus;
