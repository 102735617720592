import { ApplicationStateType } from "../context/application";

const useHasCallbackFlag = (state: ApplicationStateType): boolean => {
  if (state.applicationState === null) {
    return false;
  }

  if ("call_back" in state.applicationState.Flags) {
    return state.applicationState.Flags.call_back;
  }

  return false;
};

export default useHasCallbackFlag;
