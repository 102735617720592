import React, { useEffect, useState } from "react";
import ASCIIFolder from "fold-to-ascii";

import { ApplicationContext } from "../context/application";
import Button from "./Button";
import Modal from "./Modal";

type Props = {};

const ModalCourtLinkFile: React.FunctionComponent<Props> = () => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [parsedNarrative1, setParsedNarrative1] = useState<string>('');
  const [parsedNarrative1Lines, setParsedNarrative1Lines] = useState<Number>(0);
  const [parsedNarrative2, setParsedNarrative2] = useState<string>('');
  const [parsedNarrative2Lines, setParsedNarrative2Lines] = useState<Number>(0);

  // split line to multiple lines with maxLen and without breaking words
  const splitter = (line: string, maxLen: number): string[] => {
    var strs = [];
    while (line.length > maxLen) {
      let pos = line.substring(0, maxLen).lastIndexOf(" ");
      pos = pos <= 0 ? maxLen : pos;
      strs.push(line.substring(0, pos));
      var i = line.indexOf(" ", pos) + 1;
      if (i < pos || i > pos + maxLen) i = pos;
      line = line.substring(i);
    }
    if (line.length) {
      strs.push(line);
    }
    return strs;
  }

  const generateParsedNarrative = (narrative: string, isPart1: boolean) => {
    if (isPart1) {
      setParsedNarrative1Lines(0);
      setParsedNarrative1('');
    } else {
      setParsedNarrative2Lines(0);
      setParsedNarrative2('');
    }
    let cleanedNarrative = narrative || '';
    cleanedNarrative = cleanedNarrative.replace(/ *[\n\r]+ */g, "\n");
    cleanedNarrative = cleanedNarrative.replace(/ +/g, " ");
    cleanedNarrative = cleanedNarrative.replace(/ *[\n\r]+ */g, "\n");
    cleanedNarrative = cleanedNarrative.split("\u0022").join('"');
    cleanedNarrative = cleanedNarrative.split("\u0022").join('"');
    cleanedNarrative = cleanedNarrative.split("\u0026").join("&");
    cleanedNarrative = cleanedNarrative.split("\u0027").join("'");
    cleanedNarrative = cleanedNarrative.split("\u2018").join("'");
    cleanedNarrative = cleanedNarrative.split("\u2019").join("'");
    cleanedNarrative = cleanedNarrative.split("\u201C").join('"');
    cleanedNarrative = cleanedNarrative.split("\u201D").join('"');
    cleanedNarrative = cleanedNarrative.split("\u201c").join('"');
    cleanedNarrative = cleanedNarrative.split("\u201d").join('"');
    cleanedNarrative = ASCIIFolder.foldReplacing(cleanedNarrative, " ");
    cleanedNarrative = cleanedNarrative.toUpperCase();
    let lines = cleanedNarrative.split("\n");
    let parsedLines = [];
    for (let line of lines) {
      parsedLines.push(...splitter(line, 69));
    }
    if (isPart1) {
      setParsedNarrative1Lines(parsedLines.length);
      setParsedNarrative1(parsedLines.join('\r'));
    } else {
      setParsedNarrative2Lines(parsedLines.length);
      setParsedNarrative2(parsedLines.join('\r'));
    }
  }

  useEffect(() => {
    const narrative: string = state.localApplicationState?.Narrative || "";
    const midIndex = narrative.indexOf('THE REASONS/GROUNDS FOR THE APPLICATION ARE:');
    generateParsedNarrative(narrative.substring(0, midIndex), true);
    generateParsedNarrative(narrative.substring(midIndex), false);
    // eslint-disable-next-line
  }, [state.localApplicationState?.Narrative]);

  return (
    <Modal
      type="confirm"
      size="xl"
      handleClose={() => dispatch({ type: "setActiveModal", modal: null })}
    >
      {parsedNarrative1Lines > 5 && <span>I seek to details exceed max line length. Edit to reduce length. </span>}
      {parsedNarrative2Lines > 60 && <span>Reasons/grounds for the application exceed max line length. Edit to reduce length.</span>}
      {parsedNarrative1Lines <= 5 && parsedNarrative2Lines <= 60 && <>
        <textarea
          style={{ width: '100%', height: 100 }}
          name="Narrative"
          id="Narrative"
          value={parsedNarrative1} />
        <textarea
          style={{ width: '100%', height: 400 }}
          name="Narrative"
          id="Narrative"
          value={parsedNarrative2} />
      </>}

      <footer>
        <Button onClick={() => dispatch({ type: "setActiveModal", modal: null })} >
          Close
        </Button>
      </footer>
    </Modal>
  );
};

export default ModalCourtLinkFile;
