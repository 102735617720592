import { ApplicationConfigType } from "."
import supportSchema from "../data/schema-support.json"

const supportNewConfig: ApplicationConfigType = {
  schema: supportSchema,
  validation: {},
  sideMenu: [
    { name: "summary", label: "Summary" },
    { name: "applicant", label: "Applicant" }
  ],
  flags: ["high_risk", "interim_sought"],
  summaryQuestions: [
    {
      id: "summary",
      frames: [
        {
          subHeading: "Applicant contact details",
          questions: [
            {
              name: "call_pref_method",
              options: [
                {
                  value: "email",
                  text: "Email"
                },
                {
                  value: "call_self",
                  text: "Phone"
                },
                {
                  value: "call_trusted",
                  text: "Through trusted person"
                }
              ]
            },
            {
              name: "call_email",
              conditions: {
                call_pref_method: "email"
              }
            },
            {
              name: "call_phone",
              conditions: {
                call_pref_method: "call_self"
              }
            },
            {
              name: "call_trusted_name",
              conditions: {
                call_pref_method: "call_trusted"
              }
            },
            {
              name: "call_trusted_phone",
              conditions: {
                call_pref_method: "call_trusted"
              }
            }
          ]
        }
      ]
    }
  ],
  mainQuestions: [
    {
      id: "applicant",
      frames: [
        {
          heading: "Applicant",
          subHeading: "Applicant details",
          questions: [
            "call_name",
            {
              namePrefix: "call_addr",
              label: "Applicant Address"
            },
            {
                name: "call_inter",
                options: [
                  {
                    value: "yes",
                    text: "Yes"
                  },
                  {
                    value: "no",
                    text: "No"
                  }
                ]
            },
            {
              name: "call_inter_lang",
              conditions: {
                call_inter: "yes"
              }
            },
            {
                name: "call_disabled",
                options: [
                  {
                    value: "yes",
                    text: "Yes"
                  },
                  {
                    value: "no",
                    text: "No"
                  }
                ]
            },
            {
              name: "call_disabled_reqs",
              conditions: {
                call_disabled: "yes"
              }
            },
            {
                name: "call_indig",
                options: [
                  {
                    value: "yes",
                    text: "Yes"
                  },
                  {
                    value: "no",
                    text: "No"
                  }
                ]
            },
            {
                name: "call_lgbt",
                options: [
                  {
                    value: "yes",
                    text: "Yes"
                  },
                  {
                    value: "no",
                    text: "No"
                  }
                ]
            },
            {
                name: 'call_safe_times',
                conditions: {
                    call_pref_method: "call_self"
                },
                options: [
                    { value: 'morning', text: 'Morning' },
                    { value: 'around midday', text: 'Around Midday' },
                    { value: 'afternoon', text: 'Afternoon' }
                ],
            },
            {
                name: 'call_safe_days',
                conditions: {
                    call_pref_method: "call_self"
                },
                options: [
                    { value: 'monday', text: 'Monday' },
                    { value: 'tuesday', text: 'Tuesday' },
                    { value: 'wednesday', text: 'Wednesday' },
                    { value: 'thursday', text: 'Thursday' },
                    { value: 'friday', text: 'Friday' }
                ],
            }
          ]
        }
      ]
    }
  ],
  declarationOfTruth: {
    applicantFirstNameFields: ["call_name"],
    applicantFamilyNameFields: ["call_name"],
    applicantDOBFields: [],
    checkedField: "declaration_of_truth"
  }
}

export default supportNewConfig
