import { API, Auth } from "aws-amplify";
import React from "react";
import { ActionType } from "../context/application";

type OptomisitcUpdateFlagParams = {
  dispatch: React.Dispatch<ActionType>;
  flagName: string;
  currentValue: boolean;
  nextValue: boolean;
  ApplicationID: string;
};

const optomisitcUpdateFlag = async (
  params: OptomisitcUpdateFlagParams
): Promise<void> => {
  const { dispatch, flagName, currentValue, nextValue, ApplicationID } = params;

  // Update UI straigh away
  dispatch({
    type: "updateApplicationFlag",
    flag: flagName,
    value: nextValue,
  });

  // Do API update
  const apiName = "csvApi";
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      ApplicationID,
      flagName,
      flagValue: nextValue,
    },
  };
  try {
    const result = await API.post(
      apiName,
      "/private/registrar/updateApplicationFlag",
      myInit
    );
    // API returns updated values
    // if RevisionLog is in result payload Update revision log UI
    if ("RevisionLog" in result) {
      dispatch({
        type: "updateApplicationState",
        field: "RevisionLog",
        value: result.RevisionLog,
      });
    }
  } catch (error) {
    // If there was an error alert the user and reset UI
    alert("Error: update failed. Please try again.");
    dispatch({
      type: "updateApplicationFlag",
      flag: flagName,
      value: currentValue,
    });
  }
};

export default optomisitcUpdateFlag;
