export const getNextTimeInterval = () => {
  const dateObject = new Date();
  dateObject.setMinutes(dateObject.getMinutes() + 15);
  const currentMinute = dateObject.getMinutes() + 4;
  const minutesRoundedDown = (Math.floor(currentMinute / 15) * 15) % 60;
  dateObject.setMinutes(minutesRoundedDown);
  dateObject.setSeconds(0);
  dateObject.setMilliseconds(0);

  return dateObject.toISOString();
};

export const getDeliverTimes = () => {
  const dateObject = new Date(getNextTimeInterval());
  const times = [];

  for (let i = 0; i < 300; i++) {
    times.push(dateObject.toISOString());
    dateObject.setMinutes(dateObject.getMinutes() + 15);
  }

  return times;
};
