import { ApplicationStatusType } from "csv-package";

type ApplicationStatusTransitionMatrixType = {
  [key in ApplicationStatusType]: ApplicationStatusType[];
};

const applicationStatusTransitionMatrix: ApplicationStatusTransitionMatrixType = {
  initiated: [],
  archived: [],
  submitted: ["dnp"],
  verified: ["archived"],
  "verified-awaiting-leave": [],
  dnp: ["archived", "submitted", "re-declare-requested", "re-declare-accepted"],
  "re-declare-requested": ["dnp"],
  "re-declare-accepted": ["dnp"],
};

export default applicationStatusTransitionMatrix;
