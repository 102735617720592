import React from "react";
import * as Yup from "yup";

import { ApplicationContext } from "../context/application";
import { ErrorsType } from "../types";
import { ValidationError } from "yup";

const useDebounce = (value: any, delay: number): any => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

const useValidateWorkingState = (): void => {
  const [state, dispatch] = React.useContext(ApplicationContext);

  const debouncedLocalApplicationState = useDebounce(
    state.localApplicationState,
    500
  );

  React.useEffect(() => {
    if (state.config === null || debouncedLocalApplicationState === null) {
      return;
    }

    const localStateSchema = {
      Data: Yup.object(state.config.validation),
      Narrative: Yup.string()
        .required("Narrative must not be blank")
        .max(4510, "Narrative must be 4510 or less"),
    };

    const schema = Yup.object().shape(localStateSchema);
    schema
      .validate(debouncedLocalApplicationState, {
        abortEarly: false,
        context: {
            Submitted: state.applicationState.Submitted
        }
      })
      .then((stuff) => {
        dispatch({ type: "setErrors", errors: {} });
      })
      .catch((e) => {
        const errors = e.inner.reduce(
          (accumulator: ErrorsType, error: ValidationError, index: number) => {
            const key = error.path;
            if (typeof key !== "string") {
              return accumulator;
            }

            // filter out nullable errors
            if (
              error.message.indexOf(
                `If "null" is intended as an empty value be sure to mark the schema as`
              ) > 0
            ) {
              return accumulator;
            }
            // get only first error from same field
            if(e.inner.findIndex((err: ValidationError) => err.path === key) !== index){
              return accumulator;
            }
            return {
              ...accumulator,
              [key]: error.message,
            };
          },
          {}
        );
        dispatch({ type: "setErrors", errors });
      });
  }, [dispatch, state.config, debouncedLocalApplicationState, state.applicationState.Submitted]);
};

export default useValidateWorkingState;
