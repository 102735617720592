import _ from "lodash";
import React from "react";
import styled, { css } from "styled-components/macro";

import {
  ConditionsType,
  getQuestionConfig,
  stripToolTip,
  conditionsChecker,
} from "csv-package";

import { ApplicationContext } from "../context/application";
import applicationCanBeEdited from "../helpers/applicationCanBeEdited";
import { colors } from "../styles/variables";

import PortalQuestionLabel from "./PortalQuestionLabel";
import QuestionInput from "./QuestionInput";
import useIsLegacyApplication from "../hooks/useIsLegacyApplication";
import isStrings from "../helpers/isStrings";
import useUpdateDataField from "../hooks/useUpdateDataField";
import PortalQuestionButtons, { Buttons } from "./PortalQuestionButtons";
import { OptionType } from "../config";
import { unProtectedPersonRoles } from "../config/fvioevr";

type PortalQuestionProps = {
  name: string;
  label?: string;
  conditions?: ConditionsType;
  immutable: boolean;
  options?: OptionType[];
};

const PortalQuestion: React.FunctionComponent<PortalQuestionProps> = ({
  name,
  label = null,
  conditions = null,
  immutable,
  options,
}) => {
  const [state, dispatch] = React.useContext(ApplicationContext);
  const [edit, setEdit] = React.useState<boolean>(false);

  const { saving, actionSave } = useUpdateDataField(name);

  const isLegacyApplication = useIsLegacyApplication(state);

  // report to application context when edit of this field is opened and closed
  React.useEffect(() => {
    if (edit) {
      dispatch({ type: "fieldEditorOpened", field: name });
    } else {
      dispatch({ type: "fieldEditorClosed", field: name });
    }
  }, [dispatch, edit, name]);

  const questionConfig = React.useMemo(() => {
    if (state.config?.schema) {
      return getQuestionConfig(state.config.schema, name);
    }
    return null;
  }, [name, state.config?.schema]);

  const reDeclareData = state.workingApplicationState!.Data;

  const hasError = state.errors.hasOwnProperty(`Data.${name}`);

  const defaultValue = questionConfig && questionConfig.multiple ? [] : "";

  if (questionConfig === null) {
    return null;
  }

  // check for conditions
  if (conditions !== null && !conditionsChecker(conditions, reDeclareData)) {
    return null;
  }

  let displayLabel = label;
  if (displayLabel === null && questionConfig !== undefined) {
    displayLabel = questionConfig.label || "";
    if (typeof displayLabel === "string") {
      displayLabel = stripToolTip(displayLabel);
    }
  }

  // is first appl child?
  if (name && name === 'children[0].prp_rel_to_appl') {
    const isSeekingApplRole = unProtectedPersonRoles.some((role: string) => reDeclareData.seeking_appl_role === role);
    if (isSeekingApplRole && reDeclareData.seeking === "appl") {
      displayLabel = 'Relationship of child to the adult protected person';
    }
  }

  const workingStateValue = _.get(
    state,
    `workingApplicationState.Data.${name}`,
    defaultValue
  );
  const localStateValue = _.get(
    state,
    `localApplicationState.Data.${name}`,
    defaultValue
  );
  const saveRequired = !_.isEqual(localStateValue, workingStateValue);

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ): void => {
    const { value } = e.target;
    let questionValue: string | string[] = value;

    // check if multiple and exclusiveOption
    if (
      questionConfig.multiple &&
      ((Array.isArray(questionConfig.exclusiveOption) && questionConfig.exclusiveOption.includes(value)) || questionConfig.exclusiveOption === value ) &&
      typeof value === "string" &&
      Array.isArray(localStateValue) &&
      !localStateValue.includes(value)
    ) {
      questionValue = [value];
    } else if (questionConfig.multiple && Array.isArray(localStateValue)) {
      // check if it needs to be array
      const rawValues = (questionValue = localStateValue.includes(value)
        ? localStateValue.filter((v) => v !== value)
        : [...localStateValue, value]);

      if (Array.isArray(questionConfig.options)) {
        const { options } = questionConfig;
        questionValue = rawValues.filter((v) =>
          options.map((o) => o.value).includes(v)
        );
      }
    } else if (questionConfig.multiple && !Array.isArray(localStateValue)) {
      // something has gone wrong here array should be array. convert it here
      questionValue = [questionValue];
    }

    dispatch({
      type: "updateLocalApplicationState",
      field: `Data.${name}`,
      value: questionValue,
    });
  };

  const handleSave = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setEdit(false);
    await actionSave(localStateValue);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setEdit(false);
    const blank = questionConfig.multiple ? [] : "";
    const value = _.get(state, `workingApplicationState.Data.${name}`, blank);
    dispatch({
      type: "updateLocalApplicationState",
      field: `Data.${name}`,
      value,
    });
  };

  const handleRevert = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (state.applicationState === null) return;
    let value = _.get(
      state,
      `applicationState.Data.${name}`,
      ""
    );

    if (value === "" && questionConfig.multiple) {
      value = [];
    }

    // type guard
    if (
      (Array.isArray(value) && isStrings(value)) ||
      typeof value === "string"
    ) {
      dispatch({
        type: "updateLocalApplicationState",
        field: `Data.${name}`,
        value,
      });
      await actionSave(value);
    }
  };

  const reDeclareIndicator = !_.isEqual(
    _.get(state, `workingApplicationState.Data.${name}`, ""),
    _.get(state, `applicationState.Data.${name}`, "")
  );

  const valueToDispaly = localStateValue;

  return (
    <PortalQuestionWrapper reDeclare={reDeclareIndicator} hasError={hasError}>
      <PortalQuestionLabel htmlFor={name}>{displayLabel}</PortalQuestionLabel>
      <QuestionValue>
        <QuestionInput
          name={name}
          value={valueToDispaly || ""}
          editMode={edit}
          handleChange={handleChange}
          config={questionConfig}
          selectOptions={options}
        />
      </QuestionValue>
      <PortalQuestionButtons
        hide={!applicationCanBeEdited(state) || isLegacyApplication}
        showSavingIndicator={saving}
        showCommitButton={edit && saveRequired}
        showCancelButton={edit}
        showEditButton={!edit}
        showRevertButton={!edit && reDeclareIndicator}
        setEdit={setEdit}
        handleSave={handleSave}
        handleRevert={handleRevert}
        handleCancel={handleCancel}
        immutable={immutable}
      />
    </PortalQuestionWrapper>
  );
};

export const PortalQuestionWrapper = styled.div<{
  reDeclare?: boolean;
  hasError?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #d4e1ed;
  padding: 0.5em 0;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &:hover ${Buttons} {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  ${({ reDeclare }) =>
    reDeclare &&
    css`
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: ${colors.blue};
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -18px;
        top: 50%;
        transform: translateY(-50%);
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: ${colors.alertRed};
      }
    `}
`;

const QuestionValue = styled.div`
  margin: 0.6rem 0;
`;

export default PortalQuestion;
