import { API, Auth } from "aws-amplify";
import React from "react";
import { useParams } from "react-router";
import _ from "lodash";

import { TParams } from "../pages/Application";
import { ApplicationContext } from "../context/application";
import useChildren from "./useChildren";

type UpdateDataFieldType = {
  saving: boolean;
  actionSave: (value: string | string[]) => Promise<void>;
};

const useUpdateDataField = (name: string): UpdateDataFieldType => {
  const { applicationID } = useParams<TParams>();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [state, dispatch] = React.useContext(ApplicationContext);
  const { addChild, removeAllChildren } = useChildren();

  const actionSave = React.useCallback(
    async (value: string | string[]): Promise<void> => {
      setSaving(true);

      let Data = {};

      if (name.substring(0, 9) === "children[") {
        Data = {
          children: _.setWith(
            _.clone(state.localApplicationState!.Data),
            name,
            value,
            _.clone
          )!.children,
        };
      } else {
        Data = {
          [name]: value,
        };
      }

      // execute API call
      const result = await updateApplicationWorkingData(applicationID, Data);

      // grab returned valued
      const returnedValue = _.get(result, `WorkingData.${name}`);
      const field = `Data.${name}`;

      //if registrar select Add or remove a child/children, add empty child to children so it will populate fields
      if (field === 'Data.app_types' && Array.isArray(returnedValue) && (returnedValue.includes('AC') || returnedValue.includes('RC'))) {
        const workingStateValue = _.get(
          state,
          `workingApplicationState.Data.${name}`
        );
        if (!workingStateValue.includes('AC') && !workingStateValue.includes('RC')) {
          addChild();
        }
      }
      //if registrar deselect Add or remove a child/children, set children to empty array so it will hide the fields
      if (field === 'Data.app_types' && Array.isArray(returnedValue) && (!returnedValue.includes('AC') && !returnedValue.includes('RC'))) {
        const workingStateValue = _.get(
          state,
          `workingApplicationState.Data.${name}`
        );
        if (workingStateValue.includes('AC') || workingStateValue.includes('RC')) {
          removeAllChildren();
        }
      }

      dispatch({
        type: "updateWorkingApplicationState",
        field,
        value: returnedValue,
      });

      setSaving(false);
    },
    // eslint-disable-next-line
    [applicationID, dispatch, name, state.localApplicationState]
  );

  return {
    saving,
    actionSave,
  };
};

export default useUpdateDataField;

export const updateApplicationWorkingData = async (applicationID: string, Data: any) => {
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      ApplicationID: applicationID,
      Data
    },
  };

  // execute API call
  const result = await API.post(
    "csvApi",
    "/private/registrar/updateApplicationWorkingData",
    myInit
  );
  return result;
}